<div class="popup-dialog">
  <div class="title"><mat-icon fontSet="material-icons-outlined">people_outline</mat-icon>Create organization</div>
  <mat-divider></mat-divider>
  <div class="text">You can share your projects with your organization members.</div>
  <div class="dialog-input-area">
    <mat-form-field class="col-12" appearance="outline">
      <mat-label>Enter organization name</mat-label>
      <input
        [(ngModel)]="newOrganizationName"
        matInput
      />
    </mat-form-field>
  </div>

  <div class="text-right">
    <button mat-button class="btn-choose button-positive" (click)="onOkClick()" cdkFocusInitial [disabled]="!newOrganizationName">
      Ok
    </button>
    <button mat-button class="btn-choose button-negative" (click)="onNoClick()">
      Cancel
    </button>
  </div>
</div>
