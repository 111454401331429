import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DataService } from "../../services/data.service";

@Component({
  selector: "./update-organization",
  templateUrl: "../popups/update-organization.html"
})
export class UpdateOrganizationDialog {
  userMail = null;
  role = null;
  constructor(
    public dialogRef: MatDialogRef<UpdateOrganizationDialog>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick() {
    this.dialogRef.close({result: true, userMail: this.userMail, role: this.role});
  }
}
