import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "./update-organization-meta",
  templateUrl: "../popups/update-organization-meta.html"
})
export class UpdateOrganizationMetaDialog {
  name = null;
  constructor(
    public dialogRef: MatDialogRef<UpdateOrganizationMetaDialog>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick() {
    this.dialogRef.close({result: true, organizationName: this.name});
  }
}
