<div class="popup-dialog">
  <div class="title" *ngIf="title">{{title}}</div>
  <mat-divider *ngIf="title"></mat-divider>
  <div class="text">{{text}}</div>

  <div class="dialog-input-area" *ngIf="confirmationText">
    <mat-form-field class="col-12" appearance="outline">
      <mat-label>Complete your action</mat-label>
      <input [(ngModel)]="userInputText" matInput type="password" />
    </mat-form-field>
  </div>
  
  <div class="row">
    <div class="col">
      <div class="mt-3 text-right">
        <button mat-button class="btn-choose button-positive" cdkFocusInitial (click)="onOkClick()" [disabled]="confirmationText && (confirmationText != userInputText)">
          {{okText}}
        </button>
        <button mat-button class="btn-choose button-negative" (click)="onNoClick()">
          {{noText}}
        </button>
      </div>
    </div>
  </div>
</div>