import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { merge, Observable, of, Subject } from "rxjs";
import { map, switchMap, withLatestFrom, tap } from "rxjs/operators";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatChipInputEvent } from "@angular/material/chips";
import { ProjectService } from "src/app/services/page-services/project.service";
import { OrganizationService } from "src/app/services/page-services/organization.service";
import { UpdateProjectDialog } from "../popups/update-project.component";
import { ConfirmationDialog } from "../popups/confirmation-popup.component";
import { PaymentService } from "./../../services/page-services/payment.service";
import { MatDialog } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { UpdateVersionDialog } from "./../popups/update-version.component";

@Component({
  selector: "app-details",
  templateUrl: "./details.component.html",
  styleUrls: ["./details.component.scss"],
})
export class DetailsComponent implements OnInit {
  userID: string;
  userOrganizations: any;
  assignedOrganizations: any = [];
  relatedProject: any;
  projectDetailsObervable: Observable<any>;
  period: number = 120; //Default period
  before: number = 1;
  refresh = new Subject();
  //serverDetails: any;
  // poolLimit = {
  //   function_pool_max_size: 1,
  //   function_pool_size: 1,
  // };
  project = {
    enableAdvanceSettings: false,
  };
  unrelatedProject;
  /*
  storageDetails = {
    usage: 0,
    limit: 0,
  };
  */
  corsConfig: any = {};
  isProcessing = {
    organization: true,
    metaInfo: false,
    observer: false,
    sub_services: false,
    main: true,
  };
  paymentMethods;
  myLicenses = [];
  attachableServices = [];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  constructor(
    private organizationService: OrganizationService,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private paymentService: PaymentService,
    public dialog: MatDialog,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.projectDetailsObervable = merge(this.route.params, this.refresh).pipe(
      withLatestFrom(this.route.params),
      switchMap(([_, params]) => {
        return this.getProjectDetails(params.projectId);
      }),
      map((project: any) => {
        this.relatedProject = project;
        this.corsConfig = project.server.configuration;
        this.userID = localStorage.getItem("user_id");
        this.getUserOrganizations();
        return project;
      }),
      tap((result) => {
        this.paymentService
          .getPaymentMethods(result.user["stripe_customer_id"])
          .toPromise()
          .then((data) => (this.paymentMethods = data['data']))
          .catch((error) => {
            console.log("Error whet get payment methods : ", error.message);
            this.paymentMethods = [];
          });
        this.unrelatedProject = JSON.stringify(result);
        this.removeRelationOfProject();
      }),
      switchMap(() => {
        return this.projectService.getProjectsByType("mine");
      }),
      tap((data) => {
        this.myLicenses = data;
        this.attachableServices = data
          .filter((item) => this.canAttachableLicense(item))
          .map((item) => {
            item["assigned"] = item.head_license == this.relatedProject._id;
            return item;
          });
        this.isProcessing.main = false;
      })
    );
  }

  canAttachableLicense(item) {
    return (
      !this.myLicenses.some((license) => license.head_license == item._id) &&
      item._id != this.relatedProject._id &&
      (!item.head_license || item.head_license == this.relatedProject._id)
    );
  }
  updateProject(project) {
    this.isProcessing.metaInfo = true;
    this.projectService
      .updateProject(project)
      .toPromise()
      .then(
        (data) => {
          this.isProcessing.metaInfo = false;
          this.unrelatedProject = data;
        },
        (error) => {
          this.isProcessing.metaInfo = false;
        }
      );
  }

  toggleAssign(organization) {
    this.isProcessing.organization = true;

    if (organization.assigned) {
      this.unAssignToOrganization(organization._id);
    } else {
      this.assignProjectToOrganization(organization._id);
    }
  }

  assignProjectToOrganization(organizationId: string) {
    this.projectService
      .assignProjectToOrganization(organizationId, this.unrelatedProject)
      .subscribe((project: any) => {
        this.unrelatedProject = project;
        this.getProjectDetails(project._id).subscribe((data) => {
          this.relatedProject = data;
          this.setOrganizationsAssignation();
        });
      });
  }

  unAssignToOrganization(organizationId: string) {
    this.projectService
      .unAssignProjectToOrganization(organizationId, this.unrelatedProject)
      .subscribe((project: any) => {
        this.unrelatedProject = project;
        this.getProjectDetails(project._id).subscribe((data) => {
          this.relatedProject = data;
          this.setOrganizationsAssignation();
        });
      });
  }

  addChipItem(event: MatChipInputEvent, corsAllowed: string): void {
    const input = event.input;
    const value = event.value;

    if ((value || "").trim()) {
      this.corsConfig["cors_allowed_" + corsAllowed + ""].push(value.trim());
      input.value = "";
    }
  }

  removeChipItem(item: any, corsAllowed: string): void {
    const index =
      this.corsConfig["cors_allowed_" + corsAllowed + ""].indexOf(item);
    if (index >= 0) {
      this.corsConfig["cors_allowed_" + corsAllowed + ""].splice(index, 1);
    }
  }
  updateProjectCors(corsConf) {
    let dialog = this.dialog.open(ConfirmationDialog, {
      width: "30%",
      maxWidth: "400px",
    });

    dialog.componentInstance.text =
      "If you confirm this, the changes you make will cause your server to be disabled for a few minutes. Do you want to continue?";
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.isProcessing.metaInfo = true;
        corsConf.configuration = this.corsConfig;

        this.projectService
          .updateProjectCors(corsConf)
          .toPromise()
          .then(
            () => {
              this.isProcessing.metaInfo = false;
              this.router.navigate(["projects"]);
            },
            (error) => {
              this.isProcessing.metaInfo = false;
              console.log(error);
            }
          );
      }
    });
  }

  openRemoveDialog() {
    let dialog = this.dialog.open(ConfirmationDialog, {
      width: "30%",
      maxWidth: "400px",
    });

    dialog.componentInstance.text =
      "Do you want to delete the project ? You CAN NOT re-open the project again. Your remaining subscriptions time will be added to your balance in a few hours.";
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        /*
        if (project.server) {
          this.projects.map((p, i) => {
            if (p._id == project._id) this.projects[i].server.state = 4;
          });
        }
        */
        //for status of denied
        this.projectService
          .removeProject(Object.assign({}, this.unrelatedProject))
          .toPromise()
          .then(
            () => {
              this.router.navigate(["projects"]);
            },
            (error) => {
              console.error(error);
            }
          );
      }
    });
  }
  openUpdatePackageDialog() {
    this.dialog
      .open(UpdateProjectDialog, {
        width: "30%",
        maxWidth: "400px",
        data: { project: this.unrelatedProject, payments: this.paymentMethods },
      })
      .afterClosed()
      .toPromise()
      .then((data) => {
        if (data) {
          this.isProcessing.metaInfo = false;
          this.router.navigate(["projects"]);
        }
      });
  }
  openUpdateVersionDialog() {
    this.dialog
      .open(UpdateVersionDialog, {
        width: "30%",
        maxWidth: "400px",
        data: {
          project: this.unrelatedProject,
          version: this.relatedProject.server.version,
        },
      })
      .afterClosed()
      .toPromise()
      .then((data) => {
        if (data) {
          this.isProcessing.metaInfo = false;
          this.router.navigate(["projects"]);
        }
      });
  }

  openRestartDialog() {
    let dialog = this.dialog.open(ConfirmationDialog, {
      width: "30%",
      maxWidth: "400px",
    });

    dialog.componentInstance.text =
      "If you confirm this, the changes you make will cause your server to be disabled for a few minutes. Do you want to continue?";
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.isProcessing.metaInfo = true;
        this.projectService
          .restartProject(this.relatedProject.server._id)
          .toPromise()
          .then(() => {
            this.router.navigate(["projects"]);
            this.toastr.success(
              undefined,
              "Your process is succesfuly completed ",
              {
                positionClass: "toast-bottom-center",
                timeOut: 2500,
              }
            );
          })
          .catch((error) => {
            this.toastr.error(undefined, "Something went wrong", {
              positionClass: "toast-bottom-center",
              timeOut: 2500,
            });
            this.isProcessing.metaInfo = false;
            console.log("error : ", error);
          });
      }
    });
  }

  removeRelationOfProject() {
    this.unrelatedProject = JSON.parse(this.unrelatedProject);
    let organizations = [];
    this.unrelatedProject.user = this.unrelatedProject.user
      ? this.unrelatedProject.user._id
      : "";

    this.unrelatedProject.server =
      this.unrelatedProject.server && this.unrelatedProject.server._id
        ? this.unrelatedProject.server._id
        : "";

    if (this.unrelatedProject.organizations.length > 0) {
      this.unrelatedProject.organizations.map((item) => {
        organizations.push(item._id);
        return item;
      });
    }

    this.unrelatedProject.organizations = organizations;
  }

  setOrganizationsAssignation() {
    if (this.userOrganizations.length > 0)
      this.userOrganizations.forEach((organization, index) => {
        if (
          this.relatedProject.organizations &&
          this.relatedProject.organizations.some(
            (o) => o._id === organization._id
          )
        )
          this.userOrganizations[index]["assigned"] = true;
        else this.userOrganizations[index]["assigned"] = false;
        this.isProcessing.organization = false;
      });
    else this.isProcessing.organization = false;
  }

  getProjectDetails(id) {
    return this.projectService.getProjectDetails(id);
  }

  async getUserOrganizations() {
    this.organizationService.getUserOrganizations().subscribe((data) => {
      this.userOrganizations = data;
      this.setOrganizationsAssignation();
    });
  }

  activateObserver() {
    if (this.relatedProject.server?.observer_activated_date) {
      window.open(
        "https://observer.spicaengine.com/" + this.relatedProject._id,
        "_blank"
      );
      return;
    }
    this.isProcessing.observer = true;
    this.projectService
      .activateObserver(this.relatedProject.server._id)
      .toPromise()
      .then(() => {
        this.getProjectDetails(this.relatedProject._id).subscribe((data) => {
          this.relatedProject = data;
          this.isProcessing.observer = false;
        });
      })
      .catch(() => (this.isProcessing.observer = false));
  }

  goToServer(element) {
    this.projectService.getStrategies(element.server).subscribe(
      (data: any) => {
        let head_strategy = data.filter(
          (item) => item.name == "headquarters"
        )[0];
        if (head_strategy)
          window.open(
            `http://${element.server["ip_address"]}/passport/identify?strategy=${head_strategy._id}`,
            "_blank"
          );
      },
      (err) => console.log("error : ", err)
    );
  }
  async attachToService(license) {
    this.isProcessing.sub_services = true;

    let index = this.attachableServices.findIndex(
      (item) => item._id == license._id
    );
    if (index != -1) {
      if (this.attachableServices[index].assigned) {
        this.attachableServices[index].assigned = false;
        delete license.head_license;
      } else {
        license.head_license = this.relatedProject._id;
        this.attachableServices[index].assigned = true;
      }
    }

    let cleanLicense = this.clearRelations(JSON.parse(JSON.stringify(license)));
    await this.projectService.updateProject(cleanLicense).toPromise();
    this.isProcessing.sub_services = false;
  }
  clearRelations(obj) {
    ["user", "server", "organizations", "head_license"].forEach((field) => {
      if (typeof obj[field] == "object") {
        obj[field] = Array.isArray(obj[field])
          ? obj[field].map((v) => (v._id ? v._id : v))
          : obj[field]._id;
      }
    });
    return obj;
  }
}
