import { Component, OnInit } from "@angular/core";
import { DataService } from "../../services";
import { User } from "src/app/interfaces/user";
import { License } from "src/app/interfaces/license";
import { Router } from "@angular/router";
import { ProjectService } from "src/app/services/page-services/project.service";
import { environment } from "src/environments/environment";
import { MatDialog } from "@angular/material/dialog";
import { ChooseAssetComponent } from "src/app/components/choose-asset/choose-asset.component";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  expanded = {
    auditing: false,
    chronos: false,
    assetstore: false,
    composer: false,
  };
  user: User;
  projects: License[];
  newProjectName;
  reg_ex = environment.project_name_regex;
  dataProccessed = false;
  selectedAsset: any = "blank";
  constructor(
    private dataService: DataService,
    private router: Router,
    private projectService: ProjectService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.dataService
      .getUserWithIdentity()
      .toPromise()
      .then(async (user) => {
        this.user = user;
        await this.projectService
          .getUserProjects()
          .toPromise()
          .then((projects: License[]) => (this.projects = projects));
        this.dataProccessed = true;
      });
  }

  createProject(createProject) {
    this.dataProccessed = false;
    this.dialog
      .open(createProject, {
        width: "100%",
        maxWidth: "800px",
      })
      .afterClosed()
      .toPromise();
  }
  checkPattern() {
    return environment.project_name_regex.test(this.newProjectName);
  }
  selectButtonOfAssets(event) {
    switch (event) {
      case "ok":
        this.projectService
          .createProject(
            "free_tier",
            this.newProjectName,
            this.selectedAsset._id || "blank"
          )
          .toPromise()
          .then(() => {
            this.dialog.closeAll();
            this.dataProccessed = true;
            this.router.navigate(["/projects"]);
          });

        break;
      case "no":
        this.dataProccessed = true;
        this.dialog.closeAll();
        break;
    }
  }
}
