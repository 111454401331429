import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { AssetStoreService } from "src/app/services/page-services/asset-store.service";

@Component({
  selector: "app-choose-asset",
  templateUrl: "./choose-asset.component.html",
  styleUrls: ["./choose-asset.component.scss"],
})
export class ChooseAssetComponent implements OnInit {
  selectedAsset: any = "blank";
  searchAsset: string;
  assets;
  searcDataPassed: boolean = true;
  @Input() withBack = false;
  @Output() selectedButton: EventEmitter<string> = new EventEmitter();
  @Output() changeSelected: EventEmitter<string> = new EventEmitter();

  constructor(private assetService: AssetStoreService) {}

  async ngOnInit() {
    this.assets = await this.assetService.getAssets().toPromise();
  }
  findAssets() {
    this.searcDataPassed = false;
    this.assetService
      .getAssets(this.searchAsset)
      .toPromise()
      .then((data) => {
        this.searcDataPassed = true;
        this.assets = data;
        if (this.selectedAsset != "blank") {
          let oldSelected = this.assets.filter(
            (item) => item._id == this.selectedAsset._id
          )[0];
          this.selectedAsset = oldSelected ? oldSelected : "blank";
          this.changeSelected.emit(this.selectedAsset);
        }
      });
  }
  selectButton(button) {
    this.selectedButton.emit(button);
  }
  selectAsset(asset) {
    this.selectedAsset = asset;
    this.changeSelected.emit(this.selectedAsset);
  }
}
