<div class="bg">
  <div class="left text-center">
    <img src="../../../assets/logo.png" class="logo" />
    <slider></slider>
  </div>

  <div class="right" *ngIf="user">
    <div class="content-wrapper">
      <div class="content">
        <div *ngIf="first_step; &quot;text-center&quot;" class="px-5 py-5">
          <div class="form-group">
            <mat-form-field appearance="outline">
              <mat-label>Name</mat-label>
              <input matInput [(ngModel)]="user.name" required />
              <mat-icon matSuffix fontSet="material-icons-outlined">person</mat-icon>
              <mat-hint>Please enter the name for hq account information.</mat-hint>
            </mat-form-field>
          </div>

          <div class="form-group">
            <mat-form-field appearance="outline">
              <mat-label>Surname</mat-label>
              <input matInput [(ngModel)]="user.surname" required />
              <mat-icon matSuffix fontSet="material-icons-outlined">person</mat-icon>
              <mat-hint>Please enter the surname for hq account information.</mat-hint>
            </mat-form-field>
          </div>

          <div class="form-group">
            <mat-form-field appearance="outline">
              <mat-label>Email address</mat-label>
              <input required matInput [(ngModel)]="user.email" />
              <mat-icon matSuffix fontSet="material-icons-outlined" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$">email</mat-icon>
              <mat-hint>Please enter hq account email for login.</mat-hint>
            </mat-form-field>
          </div>
          <div class="form-group">
            <mat-form-field appearance="outline">
              <mat-label>Phone Number</mat-label>
              <input matInput [(ngModel)]="user.number" pattern="^((\+)?(\d{1,3}))?(\d{7,}){1}?$" />
              <mat-icon matSuffix fontSet="material-icons-outlined">phone</mat-icon>
              <mat-hint>Please enter hq account phone number for information.</mat-hint>
            </mat-form-field>
          </div>

          <div class="form-group">
            <mat-form-field appearance="outline">
              <mat-label>Enter password</mat-label>
              <input matInput [(ngModel)]="user.password" (blur)="checkStrength()" required type="password" />
              <mat-icon matSuffix fontSet="material-icons-outlined">vpn_key</mat-icon>
              <mat-hint>Please enter hq account password for login.</mat-hint>
            </mat-form-field>
            <div class="inline-error pl-2" *ngIf="passwordChecker.strength < 30">
              Your password is not strong enough. Your password must be at least 10 characters. Include letters, number
              and symbols to make it more secure.
            </div>
          </div>

          <div class="form-group">
            <mat-form-field appearance="outline">
              <mat-label>Confirm password</mat-label>
              <input matInput [(ngModel)]="passwordChecker.confirmation" (blur)="checkPass()" required
                type="password" />
              <mat-icon matSuffix fontSet="material-icons-outlined">vpn_key</mat-icon>
              <mat-hint>Please enter your password again</mat-hint>
            </mat-form-field>
            <div class="inline-error pl-2" *ngIf="passwordChecker.invalid">
              Re-enter your password confirmation so it matches your password
            </div>
          </div>

          <div class="form-group">
            <mat-checkbox [(ngModel)]="privacy" color="primary">I accept <a
                href="https://dashboard.spicaengine.com/terms" target="_blank">terms</a> and <a
                href="https://dashboard.spicaengine.com/privacy-policy" target="_blank">policy</a>.</mat-checkbox>
          </div>

          <div class="form-group">

            <button mat-flat-button [routerLink]="['/login']" class="button ml-2">
              Sign In
            </button>
            <button mat-raised-button *ngIf="dataPassed" class="float-right" color="primary" [disabled]="
                !user.name || !user.surname || !user.email || !user.password || passwordChecker.confirmation != user.password || !privacy || passwordChecker.strength < 30
              " (click)="register()">
              Register
            </button>
            <loading-spinner *ngIf="!dataPassed"></loading-spinner>
          </div>
        </div>
        <div *ngIf="final_step" class="card px-5 py-5">
          <mat-form-field appearance="outline">
            <mat-label>Mail Auth Code</mat-label>
            <input matInput [(ngModel)]="mailAuthCodeClient" required />

            <mat-hint>Please enter the password sent to your e-mail address in this
              field.</mat-hint>
          </mat-form-field>
          <button *ngIf="dataPassed" [disabled]="!mailAuthCodeClient" (click)="finalRegister()" mat-flat-button
            class="mt-3">
            Register
          </button>
          <loading-spinner *ngIf="!dataPassed"></loading-spinner>
        </div>
      </div>
    </div>
  </div>
</div>
