<app-header-background onPage="help"></app-header-background>

<div class="content-area">
    <div class="container">
        <div class="row">
            <div class="col">
            <div class="page-title">
                Support
            </div>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-12 mb-5">
                <h5 class="title"><mat-icon fontSet="material-icons-outlined">info</mat-icon>Your Support Package</h5>
                <mat-divider></mat-divider>   
                <p class="option">Your support package is <u>basic package</u>. 
                    You can upgrade your support package to enable realtime chat connection with Spica support team.</p>
            </div>
            <div class="col-3 cell">
                <h5 class="title"><mat-icon>add</mat-icon>Urgent Support</h5>
                <mat-divider></mat-divider>   
                <p class="option"><a href="https://join.slack.com/t/spica-engine/shared_invite/zt-9qix07sg-BxiOW9tBi8elYRQWcMTOKg" target="_blank">Slack</a></p>
                <p class="option"><a href="https://discord.com/invite/HJTrRMH" target="_blank">Discord</a></p>
                <p class="option disabled">Phone support</p>
                <p class="option"><a href="mailto:contact@spicaengine.com">Email support</a></p>
            </div>
            <div class="col-4 cell">
                <h5 class="title"><mat-icon fontSet="material-icons-outlined">article</mat-icon>Contents</h5>
                <mat-divider></mat-divider> 
                <p class="option"><a href="https://spicaengine.com/docs/faq" target="_blank">FAQ</a></p>  
                <p class="option"><a href="https://spicaengine.com/docs/guide" target="_blank">Guides</a></p>
                <p class="option disabled"><a href="#" target="_blank">How To Videos</a></p>
                <p class="option disabled"><a href="#" target="_blank">Quickstart Videos</a></p>
                <p class="option"><a href="https://spicaengine.com/docs/concept/bucket">Documentation</a></p>
            </div>
            <div class="col-5 cell">
                <h5 class="title"><mat-icon fontSet="material-icons-outlined">article</mat-icon>Integrated Success Services</h5>
                <mat-divider></mat-divider> 
                <p class="option">Realtime chat option</p>  
                <p class="option">Private slack channel</p>  
                <p class="option">Resolution in 4 hours</p>  
                <mat-divider></mat-divider>
                <div class="action mt-2">
                    <mat-icon fontSet="material-icons-outlined">keyboard_arrow_right</mat-icon>
                    <a href="#" [routerLink]="['/service']" >Enable</a>
                </div>
            </div>
        </div>
    </div>
</div>