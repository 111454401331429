import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: "root",
})
export class AssetStoreService {
  apiFunctionsUrl = `${environment.assetstore_url}fn-execute`;
  apiBucketsUrl = `${environment.assetstore_url}bucket`;
  hqApiFunctionsUrl = `${environment.url}fn-execute`;

  assetBucketId: string = "5f158e77be1dd33ef975a257";
  full_tag_id: string = "609aa122279b71002c4a683d";
  decodeToken;
  user;
  constructor(private http: HttpClient) {}
  getAssets(searchTerm = "") {
    let header = new HttpHeaders({
      "x-authorization": `APIKEY ${environment.assetstore_api_key}`,
    });
    return this.http.get<any>(
      `${this.apiBucketsUrl}/${
        this.assetBucketId
      }/data?sort={"integrated_count":1}&filter={"approved":true,"price":0,"tags":{"$in":["${
        this.full_tag_id
      }"]},"name":{"$regex":"${searchTerm.toLowerCase()}","$options":"i"}}`,
      { headers: header }
    );
  }
  login() {
    if (localStorage.getItem("assetstore_token")) {
      return of({ token: localStorage.getItem("assetstore_token") });
    }
    return this.http
      .post<any>(`${this.apiFunctionsUrl}/login`, {
        token: `${localStorage.getItem("token")}`,
      })
      .pipe(
        tap((data) => localStorage.setItem("assetstore_token", data.token))
      );
  }
  getDecodeToken() {
    if (this.decodeToken) return this.decodeToken;
    let token = localStorage.getItem("assetstore_token");
    let decoded = jwt_decode(token);
    this.decodeToken = decoded;
    return decoded;
  }
  integrate(server_id, identity_id, asset_id) {
    return this.http.post<any>(`${this.hqApiFunctionsUrl}/integrate`, {
      server_id,
      identity_id,
      asset_id,
    });
  }
}
