import { Injectable } from "@angular/core";
import { GoogleLoginProvider, SocialAuthService } from "angularx-social-login";

@Injectable({
  providedIn: "root",
})
export class GoogleAuthService {
  constructor(private socialAuthService: SocialAuthService) {}
  loginWithGoogle(): Promise<any> {
    return this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  /*
  logOut() {
    this.socialAuthService.signOut();
  }
  */
}
