<mat-card
  *ngIf="
    project.server && project.status != 'denied' && project.server['state'] != 6
  "
  [class.deleting-project]="
    project.server['state'] == 4 || project.server['state'] == 5
  "
  [class.delete-request]="
  project.status == 'delete_request'
"
  [class.installing-project]="
    project.server['state'] == 1 || project.server['state'] == 0
  "
  [class.pointer]="project.status == 'active'"
  [routerLink]="project.status == 'active' ? ['/details', project._id] : undefined"
>
  <div class="row">
    <div class="col-10">
      <h4 class="project-name">{{ project.project_name }}</h4>
    </div>
    <div class="col-2">
      <mat-icon fontSet="material-icons-outlined"
        >keyboard_arrow_right</mat-icon
      >
    </div>
  </div>
  <div class="info">
    <p>
      {{ project.package_code.replace("_", " ") + " Package" | titlecase }}
    </p>
    <p>
      <strong>Domain: </strong>
      <a
        *ngIf="
          project.server['state'] == 2 ||
          project.server['state'] == 3 ||
          project.server['state'] == 11
        "
        target="_blank"
        (click)="$event.stopPropagation(); goToServerClick()"
        class="text-decoration-none"
        >{{ project.server["ip_address"].split(".")[0] }}</a
      >
    </p>
  </div>
  <div
    class="action-buttons"
    *ngIf="
      project.status == 'active' &&
      (project.server['state'] == 2 || project.server['state'] == 3)
    "
  >
    <div>
      <div
        class="project-state project-state-success"
        *ngIf="project.server['state'] == 2"
      >
        <span matTooltip="Safe and running"></span>
        Active
      </div>
      <div
        class="project-state project-state-danger"
        *ngIf="project.server['state'] == 3"
      >
        <span matTooltip="Server is unavailable now"></span>
        Unavailable
      </div>
    </div>
  </div>
  <p
    class="status"
    *ngIf="project.server['state'] == 4 || project.server['state'] == 5"
  >
    <mat-spinner [diameter]="20"></mat-spinner>Deleting the project...
  </p>
  <p
    class="status"
    *ngIf="project.status == 'delete_request'"
  >
    We sent a mail to you. Please confirm the mail and approve deletion process.
  </p>
  <p class="status" *ngIf="project.server['state'] == 11">
    Installing an asset, please wait to finish the process
  </p>
  <p
    class="status"
    *ngIf="project.server['state'] == 1 || project.server['state'] == 0"
  >
    {{ installingMessage }}
    <mat-progress-bar
      mode="determinate"
      [value]="installingProgress"
    ></mat-progress-bar>
  </p>
  <mat-card-footer *ngIf="project.from" class="from-organization">
    <span> From the</span><b>{{ project.from }}</b
    ><span>organization</span>
  </mat-card-footer>
</mat-card>
<mat-card
  *ngIf="
    project.status == 'expired' ||
    project.status == 'requested' ||
    project.status == 'denied'
  "
>
  <div class="row">
    <div class="col-10">
      <h4 class="project-name">{{ project.project_name }}</h4>
    </div>
  </div>
  <div class="status d-flex" *ngIf="project.status == 'requested'">
    <mat-spinner [diameter]="20"></mat-spinner>Checking payment details...
  </div>
  <p class="status" *ngIf="project.status == 'denied'">
    Your card provider denied the payment or you do not have a valid payment
    method.<a class="link" [routerLink]="['/payment-method']"
      >Check your payment method.</a
    >
  </p>
  <p class="status status-small" *ngIf="project.status == 'expired'">
    Your license has been expired but will be accessible for two weeks after
    expiry date. Not renewed projects will be deleted. Your license expiry date
    is
    <span>{{ project.expire_at | date }}.</span><br />
    <span *ngIf="paymentMethods.length == 0"
      >You do not have a valid payment method.<a
        class="link"
        [routerLink]="['/payment-method']"
        >Check your payment method.</a
      ></span
    >
  </p>
  <div>
    <button
      *ngIf="
        (project.user == user._id && project.status == 'denied') ||
        project.status == 'expired'
      "
      mat-button
      (click)="openRemoveDialogClick()"
      matTooltip="Remove"
    >
      <mat-icon fontSet="material-icons-outlined">close</mat-icon>
      Cancel project
    </button>
    <button
      *ngIf="
        project.user == user._id &&
        project.status == 'denied' &&
        paymentMethods?.length > 0
      "
      mat-button
      (click)="reTryProjectClic()"
      matTooltip="Retry"
    >
      <mat-icon fontSet="material-icons-outlined">cached</mat-icon>
      Retry project
    </button>
    <button
      *ngIf="
        project.user == user._id &&
        project.status == 'expired' &&
        paymentMethods &&
        paymentMethods.length > 0
      "
      mat-button
      (click)="openUpdateDialogClick()"
      matTooltip="Retry"
    >
      <mat-icon fontSet="material-icons-outlined">cached</mat-icon>
      Renew License
    </button>
  </div>
  <mat-card-footer *ngIf="project.from" class="from-organization">
    <span> From the</span><b>{{ project.from }}</b
    ><span>organization</span>
  </mat-card-footer>
</mat-card>
