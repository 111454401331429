import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "./../../../environments/environment";
@Injectable({
  providedIn: "root",
})
export class LicenceService {
  apiBucketUrl = `${environment.url}bucket/`;
  apiFunctionsUrl = `${environment.url}fn-execute/`;
  bucketLicenses: string = "5dd912440566406ec8f0d756";

  constructor(private http: HttpClient) {}

  getUserLicenses(userid: string) {
    return this.http.get(
      this.apiBucketUrl +
        this.bucketLicenses +
        `/data?relation=true&filter={"$and":[{"user":"${userid}"},
      {"$or":[{"status":{"$in":["active","requested","denied","expired"]}}]} ]}`
    );
  }
  removeLicense(licenseId: string) {
    return this.http.post(this.apiFunctionsUrl + "deleteLicense", {
      license_id: licenseId,
    });
  }
  createOnPremiseLicence(project_name: string) {
    return this.http.post(this.apiFunctionsUrl + "createOnPremiseLicense", {
      project_name,
      user_id: localStorage.getItem("user_id")
    });
  }
}
