import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RecoveryPasswordCode } from '../../interfaces/recovery-password-code';
import { ToastrService } from 'ngx-toastr';
import { ProfileService } from 'src/app/services/page-services/profile.service';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss']
})
export class PasswordRecoveryComponent implements OnInit {
  resetProcess: boolean = false;
  codeIsActive: boolean = false;
  recoveryCode: RecoveryPasswordCode;
  formGroup: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private routerActivatedRoute: ActivatedRoute,
    private router: Router,
    private profileService:ProfileService
    ) { }

  ngOnInit(): void {
    let code:string = this.routerActivatedRoute.snapshot.queryParams["c"];


    this.profileService.getPasswordRecoveryCode(code).
    toPromise()
    .then((data:RecoveryPasswordCode) => {
      this.recoveryCode = data[0];
      if(new Date(this.recoveryCode.expire_at) > new Date()){
        this.codeIsActive = true;
        this.createForm();
      }else{
        this.codeIsActive = false;
      }
    })
    
    .catch();
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      password: ["", Validators.required],
      password_again: ["", Validators.required]
    });
  }

  getError(el) {
    switch (el) {
      case "password":
        if (this.formGroup.get("password").hasError("required")) {
          return "Enter your password please!";
        }
        break;
      case "password_again":
        if (this.formGroup.get("password_again").hasError("required")) {
          return "Confirm your new password please!";
        }
        break;
      default:
        return "";
    }
  }

  onSubmit(post) {
    this.resetProcess = true;
    if(post.password == post.password_again){
      this.profileService.resetPassword(this.recoveryCode.recover_id, post.password)
      .toPromise()
      .then(data => {
        this.resetProcess = false;
        this.toastr.success(undefined, "You changed your password. Redirecting in 3 seconds.", {
          positionClass: "toast-bottom-center",
          timeOut: 3000
        });
        setTimeout(() => {
          this.router.navigate(["/login"]);
        }, 2500);
      }).catch((data) => {
        this.resetProcess = false;
      });
    }
  }

}
