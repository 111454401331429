import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import jwt_decode from "jwt-decode";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class DataService {
  apiBucketUrl = `${environment.url}bucket/`;
  apiFunctionsUrl = `${environment.url}fn-execute/`;
  bucketUsers: string = "5dd911410566400169f0d755";

  constructor(private http: HttpClient) {}

  getUserId() {
    let token = localStorage.getItem("token");
    let decoded = jwt_decode<any>(token);
    return decoded._id;
  }

  getUser() {
    return this.http.get(
      this.apiBucketUrl +
        this.bucketUsers +
        '/data?relation=true&filter={"_id":"' +
        this.getUserId() +
        '"}'
    );
  }
  getUserWithIdentity() {
    let token = localStorage.getItem("token");
    let decoded = jwt_decode<any>(token);
    return this.http
      .get(
        this.apiBucketUrl +
          this.bucketUsers +
          '/data?relation=true&filter={"identity_id":"' +
          decoded._id +
          '"}'
      )
      .pipe(map((data) => data[0]));
  }
  isServerAvailable(ip: string) {
    return this.http.get("https://" + ip + "/api/passport/identify");
  }
  getCountries() {
    return this.http.get(
      "https://countriesnow.space/api/v0.1/countries/codes"
    ) as Observable<[]>;
  }
  getCityByCountry(country) {
    return this.http.post(
      "https://countriesnow.space/api/v0.1/countries/states",
      {
        country: country,
      }
    );
  }
  getStatesByCity(country, city) {
    return this.http.post(
      "https://countriesnow.space/api/v0.1/countries/state/cities",
      {
        country: country,
        state: city,
      }
    );
  }
}
