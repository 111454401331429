<app-header-background onPage="licenses"></app-header-background>
<div class="content-area">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="page-title">
          Licenses
          <button mat-button (click)="openDialog()" >
            Allocate a new one
            <mat-icon fontSet="material-icons-outlined">add</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <loading-spinner *ngIf="!dataPassed"></loading-spinner>
    <div *ngIf="licenses | async; let licensesDataSource">
      <div
        *ngIf="licensesDataSource.length == 0 && dataPassed"
        class="text-center col-12"
      >
        You don't have any license yet! Let's create a new project or new
        on-premise license.
      </div>
      <table
        *ngIf="licensesDataSource.length > 0 && dataPassed"
        mat-table
        class="w-100"
        [dataSource]="licensesDataSource"
      >
        <ng-container matColumnDef="license_id">
          <th class="text-center" mat-header-cell *matHeaderCellDef>
            License ID
          </th>
          <td class="text-center" mat-cell *matCellDef="let license">
            {{ license.license_id }}
          </td>
        </ng-container>
        <ng-container matColumnDef="license_type">
          <th class="text-center" mat-header-cell *matHeaderCellDef>
            License Type
          </th>
          <td class="text-center" mat-cell *matCellDef="let license">
            {{ packages[license.package_code] }}
          </td>
        </ng-container>
        <ng-container matColumnDef="project">
          <th class="text-center" mat-header-cell *matHeaderCellDef>
            Project Name
          </th>
          <td class="text-center" mat-cell *matCellDef="let license">
            {{ license.project_name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th class="text-center" mat-header-cell *matHeaderCellDef>
            License Status
          </th>
          <td class="text-center" mat-cell *matCellDef="let license">
            {{ license.status }}
          </td>
        </ng-container>
        <ng-container matColumnDef="expiration">
          <th class="text-center" mat-header-cell *matHeaderCellDef>
            License Expiration
          </th>
          <td class="text-center" mat-cell *matCellDef="let license">
            <div *ngIf="license.package_code == 'on_premise'">
              {{ license.expire_at | date: "medium" }}
            </div>
            <div
              *ngIf="license.package_code != 'on_premise'"
              matTooltip="Only on premise licenses have expiration date"
            >
              No Expiration
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th class="text-center" mat-header-cell *matHeaderCellDef>Action</th>
          <td class="text-center" mat-cell *matCellDef="let license">
            <button
              matTooltip="You can delete just on-premise license. Please delete the project from projects page."
              mat-icon-button
              [class.disabled]="license.package_code != 'on_premise'"
              (click)="
                license.package_code == 'on_premise'
                  ? removeLicense(license._id)
                  : null
              "
            >
              <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr
          class="text-center"
          mat-header-row
          *matHeaderRowDef="[
            'license_id',
            'license_type',
            'project',
            'status',
            'expiration',
            'actions'
          ]"
        ></tr>
        <tr
          class="text-center"
          mat-row
          *matRowDef="
            let row;
            columns: [
              'license_id',
              'license_type',
              'project',
              'status',
              'expiration',
              'actions'
            ]
          "
        ></tr>
      </table>
    </div>
  </div>
</div>
