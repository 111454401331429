import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from './../../../environments/environment';
import { DataService } from './../data.service';
@Injectable({
  providedIn: "root",
})
export class ServiceService {
  apiBucketUrl = `${environment.url}bucket/`;
  apiFunctionsUrl = `${environment.url}fn-execute/`;
  bucketServices = "5f33cf55107b9e69857a155e";
  bucketConfigurations = "5e022ced760bb0603e198304";


  constructor(
    private http: HttpClient,
    private dataService: DataService
  ) { }



  getUserServices(user: string) {
    return this.http.get(
      this.apiBucketUrl +
      this.bucketServices +
      `/data?relation=true&filter={"user._id":"${user}"}`
    );
  }
  getUserServicesByService(user: string, confId: string) {
    return this.http.get(
      this.apiBucketUrl +
      this.bucketServices +
      `/data?relation=true&filter={"user._id":"${user}","service._id":"${confId}"}`
    );
  }
  getAllServices() {
    return this.http.get(
      this.apiBucketUrl +
      this.bucketConfigurations +
      `/data?filter={"key":{"$in":["training_onboarding","integrated_success_services","solution_architect"]}} `
    );
  }
  /*
  removeServices(userServiceId: string, stripe_subs_id: string) {
    return this.http.post(this.apiFunctionsUrl + "deleteServices", {
      userServiceId,
      stripe_subs_id
    });
  }
  */
  softRemoveServices(service) {
    let data = {
      "_id": service.service_id,
      "service": service._id,
      "user": service.user._id,
      "status": "canceled",
      "stripe_subs_id": service.stripe_subs_id

    };
    return this.http.put(this.apiBucketUrl + this.bucketServices + "/data/" + service.service_id, data)
  }
  addServices(confId: string) {
    return this.http.post(this.apiBucketUrl + this.bucketServices + "/data", {
      user: localStorage.getItem('user_id'),
      status: "requested",
      service: confId
    });
  }

  realRemoveService(service) {
    return this.http.delete(this.apiBucketUrl + this.bucketServices + "/data/" + service);
  }

}