import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatCardModule } from "@angular/material/card";
import { MatChipsModule } from "@angular/material/chips";
import { MatNativeDateModule, MatOptionModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { MatToolbarModule } from "@angular/material/toolbar";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
//import { PaymentMethodsComponent } from "./payment-methods/payment-methods.component";
import { NgPaymentCardModule } from "ng-payment-card";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
//import { HeaderBackgroundComponent } from "./header-background/header-background.component";
import { DetailsComponent } from "./pages/project-details/details.component";
//import { NavbarComponent } from "./navbar/navbar.component";
import { EditorganizationComponent } from "./pages/editorganization/editorganization.component";
import { OverlayModule } from "@angular/cdk/overlay";
import { HeaderBackgroundComponent } from "./components/header-background/header-background.component";
import { HomeComponent } from "./pages/home/home.component";
import { InvoicesComponent } from "./pages/invoices/invoices.component";
import { LoginComponent } from "./pages/login/login.component";
import { OrganizationsComponent } from "./pages/organizations/organizations.component";
import { ProfileComponent } from "./pages/profile/profile.component";
//import { DashboardComponent } from "./dashboard/dashboard.component";
import { ProjectsComponent } from "./pages/projects/projects.component";
import { ConfirmationDialog } from "./pages/popups/confirmation-popup.component";
import { CreateProjectDialog } from "./pages/popups/create-project.component";
import { UpdateProjectDialog } from "./pages/popups/update-project.component";
import { UpdateOrganizationDialog } from "./pages/popups/update-organization.component";
import { UpdateOrganizationMetaDialog } from "./pages/popups/update-organization-meta.component";
import { AddPaymentMethodDialog } from "./pages/popups/add-payment-method.component";
import { RegisterComponent } from "./pages/register/register.component";
import { CommonModule } from "@angular/common";
import { ToastrModule } from "ngx-toastr";
import { LicensesComponent } from "./pages/licenses/licenses.component";
import { CreateLicenseDialog } from "./pages/popups/create-license.component";
import { CreateOrganizationDialog } from "./pages/popups/create-organization.component";
import { PaymentMethodComponent } from "./pages/payment-method/payment-method.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatPaginatorModule } from "@angular/material/paginator";
import { LoadingSpinnerComponent } from "./pages/loading-spinner/loading-spinner.component";
import { PrivacyPolicyComponent } from "./pages/legal/privacy-policy/privacy-policy.component";
import { TermsConditionsComponent } from "./pages/legal/terms-conditions/terms-conditions.component";
import { LegalTitlesComponent } from "./pages/legal/legal-titles/legal-titles.component";
import { PasswordRecoveryComponent } from "./pages/password-recovery/password-recovery.component";
import { TokenInterceptor } from "./services/token.interceptor";
import { SliderComponent } from "./components/slider/slider.component";
import { MatSliderModule } from "@angular/material/slider";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { HelpComponent } from "./pages/help/help.component";
import { UpdateUserRoleDialog } from "./pages/popups/update-user-role.component";
import { GetEmailDialog } from "./pages/popups/get-email.component";
import { ServiceComponent } from "./pages/service/service.component";
import { MatTabsModule } from "@angular/material/tabs";
import { UpdateVersionDialog } from "./pages/popups/update-version.component";

import {
  SocialLoginModule,
  SocialAuthServiceConfig,
} from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";
import { ChooseAssetComponent } from './components/choose-asset/choose-asset.component';
import { ProjectCardComponent } from './components/project-card/project-card.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
  declarations: [
    AppComponent,
    //    DashboardComponent,
    ProjectsComponent,

    OrganizationsComponent,
    LoginComponent,
    RegisterComponent,
    //    NavbarComponent,
    EditorganizationComponent,
    CreateOrganizationDialog,
    CreateLicenseDialog,
    CreateProjectDialog,
    ConfirmationDialog,
    UpdateProjectDialog,
    UpdateVersionDialog,
    UpdateOrganizationDialog,
    UpdateOrganizationMetaDialog,
    UpdateUserRoleDialog,
    GetEmailDialog,
    ProfileComponent,
    ServiceComponent,
    ///   PaymentMethodsComponent,
    AddPaymentMethodDialog,
    InvoicesComponent,
    LicensesComponent,
    ///  HeaderBackgroundComponent,
    DetailsComponent,
    HeaderBackgroundComponent,
    HomeComponent,
    PaymentMethodComponent,
    LoadingSpinnerComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    LegalTitlesComponent,
    PasswordRecoveryComponent,
    SliderComponent,
    HelpComponent,
    ServiceComponent,
    ChooseAssetComponent,
    ProjectCardComponent,
  ],
  entryComponents: [
    ConfirmationDialog,
    CreateOrganizationDialog,
    CreateProjectDialog,
    UpdateProjectDialog,
    UpdateVersionDialog,
    CreateLicenseDialog,
    UpdateOrganizationDialog,
    UpdateOrganizationMetaDialog,
    UpdateUserRoleDialog,
    GetEmailDialog,
  ],
  imports: [
    MatDividerModule,
    CommonModule,
    MatProgressSpinnerModule,
    ToastrModule.forRoot(),
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    MatFormFieldModule,
    HttpClientModule,
    NgbModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgPaymentCardModule,
    MatCardModule,
    MatDatepickerModule,
    MatToolbarModule,
    MatChipsModule,
    MatSidenavModule,
    MatListModule,
    MatTableModule,
    MatNativeDateModule,
    MatMenuModule,
    MatSelectModule,
    MatStepperModule,
    MatOptionModule,
    MatButtonModule,
    MatInputModule,
    MatDialogModule,
    MatProgressBarModule,
    MatIconModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatPaginatorModule,
    MatCheckboxModule,
    SocialLoginModule,
    OverlayModule,
    MatTabsModule,
    MatExpansionModule, 
    MatRadioModule
  ],
  exports: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: "SocialAuthServiceConfig",
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              "1055057512297-6149j8nhj7m4mtes6rijn8mslj26v9l8.apps.googleusercontent.com"
            ),
          },
        ],
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
