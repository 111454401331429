import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "./create-Organizations",
  templateUrl: "../popups/create-organization.html"
})
export class CreateOrganizationDialog {
  newOrganizationName: string;

  constructor(
    public dialogRef: MatDialogRef<CreateOrganizationDialog>  ) {}

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onOkClick() {
    this.dialogRef.close({result: true, organizationName: this.newOrganizationName});
  }
}