<app-header-background onPage="invoices"></app-header-background>

<div class="content-area">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="page-title">
          Profile Edit
        </div>
      </div>
    </div>
  </div>
  <div class="container mt-5">
    <div class="row">
      <div class="col-12">
        <loading-spinner *ngIf="!dataPassed"></loading-spinner>
        <div *ngIf="dataPassed">
          <div class="row">
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>Enter your name</mat-label>
                <input matInput [(ngModel)]="name" type="text" id="name" aria-describedby="nameHelp"
                  placeholder="Enter your name" />
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>Enter your surname</mat-label>
                <input matInput [(ngModel)]="surname" type="text" id="surname" aria-describedby="surnameHelp"
                  placeholder="Enter your surname" />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>Enter your email</mat-label>
                <input matInput [(ngModel)]="email" type="email" id="email" aria-describedby="emailHelp"
                  placeholder="Enter your email" disabled />
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>Enter your phone number</mat-label>
                <input matInput [(ngModel)]="number" pattern="^((\\+91-?)|0)?[0-9]{10}$" />              
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <mat-form-field appearance="outline">
                <mat-label>Enter new password (optional)</mat-label>
                <input matInput [(ngModel)]="new_password" (blur)="checkStrength()" value="213132" type="password"
                  id="new_password" placeholder="Enter new password (optional)" />
              </mat-form-field>
              <div class="inline-error pl-2" *ngIf="passwordChecker.strength < 30">
                Your password is not strong enough. Your password must be at least 10 characters. Include letters,
                number and symbols to make it more secure.
              </div>
            </div>
          </div>

          <!-- <div class="row">

            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>Enter current password</mat-label>
                <input
                  required
                  matInput
                  [(ngModel)]="cur_password"
                  value="213132"
                  type="password"
                  id="password"
                  placeholder="Enter current password for changes"
                />
              </mat-form-field>
            </div>
          </div> -->
          <div class="row text-right">
            <div class="col-12 text-right">
              <button (click)="onUpdate()" mat-button>
                Update
              </button>
            </div>
          </div>
          <mat-divider class="mt-5"></mat-divider>
          <div class="row mt-5 mb-0">
            <h3 class="col-12">Delete Account</h3>
            <div class="col-12">Do you want to delete your account.</div>
            <div class="col-12 text-right">
              <button mat-button class="button-negative important" (click)="deleteAccount()">
                Delete Account
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>