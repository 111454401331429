<div class="bg">
    <div class="left text-center">
      <img src="../../../assets/logo.png" class="logo">
      <div class="note text-left">
        <h5>Welcome to Spica Engine HQ service</h5>
        <br>
        This is the very first version of Spica Engine Manager. You should know that this is a beta version of Spica Engine HQ
      </div>
    </div>
  
    <div class="right">
      <div class="content-wrapper">
        <div class="content">
          <mat-card class="p-5">
            <h2>Password Recovery</h2>
            <form *ngIf="codeIsActive" [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)" class="form">
              <mat-form-field color="light" appearance="outline" floatLabel="never" class="form-element d-block">
                <mat-label>Password</mat-label>
                <input type="password" matInput formControlName="password" />
                <mat-error *ngIf="formGroup.controls['password'].invalid">
                  {{ getError("password") }}
                </mat-error>
              </mat-form-field>
  
              <mat-form-field floatLabel="never" appearance="outline" class="form-element d-block">
                <mat-label>Confirm Password</mat-label>
                <input type="password" matInput formControlName="password_again" />
                <mat-error *ngIf="!formGroup.controls['password_again'].valid">
                  {{ getError("password_again") }}
                </mat-error>
              </mat-form-field>
            
              <div class="form-element text-center">
                <button *ngIf="!resetProcess" mat-raised-button type="submit" class="button"
                  [disabled]="!formGroup.valid">
                  Reset Password
                </button>
              </div>
              <loading-spinner *ngIf="resetProcess" mode="query"></loading-spinner>
            </form>
            <div *ngIf="!codeIsActive">
                This recovery code is expired. 
            </div>
          </mat-card>
        </div>
      </div>
    </div>
  </div>