import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
} from "@angular/core";
import { DataService } from "../../services/data.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { PaymentService } from "src/app/services/page-services/payment.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { environment } from "src/environments/environment";
declare var Stripe: any;
@Component({
  selector: "app-add-payment-method",
  templateUrl: "./add-payment-method.component.html",
  styleUrls: ["./add-payment-method.component.scss"],
})
export class AddPaymentMethodDialog implements OnInit {
  onProcessing: boolean = false;
  userMail: string;
  customer: string;
  elem;
  formGroup: FormGroup;
  hasCardError: boolean = false;
  countries: [] = [];
  cities: [] = [];
  states: [] = [];
  dial_code;
  stripe = Stripe(environment.stripe_in_test ? environment.stripe_test_pk : environment.stripe_live_pk);

  @ViewChild("cardelement") cardelement: ElementRef;
  constructor(
    private dataService: DataService,
    private paymentService: PaymentService,
    public dialogRef: MatDialogRef<AddPaymentMethodDialog>,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.dataService
      .getUserWithIdentity()
      .toPromise()
      .then((data) => {
        this.userMail = data.email;
        this.customer = data.stripe_customer_id;
      });
    this.createForm();
    this.getCountries();
  }
  async getCountries() {
    this.countries = await this.dataService.getCountries().toPromise();
    this.countries = this.countries["data"];
  }
  ngAfterViewInit(): void {
    var elements = this.stripe.elements();
    var style = {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    };

    this.elem = elements.create("card", { style: style });
    this.elem.mount(this.cardelement.nativeElement);

    this.elem.addEventListener("change", ({ error }) => {
      const displayError = document.getElementById("card-errors");
      if (error) {
        displayError.textContent = error.message;
        this.hasCardError = true;
      } else {
        this.hasCardError = false;
        displayError.textContent = "";
      }
    });
  }

  async stripePaymentMethodHandler(result) {
    if (result.error) {
      this.onProcessing = false;
      console.log(result.error);
    } else {
      this.paymentService
        .addPaymentMethod(this.customer, result.paymentMethod.id)
        .toPromise()
        .then((_) => {
          this.dialogRef.close(true);
        })
        .catch((response) => {
          this.dialogRef.close(false);
          this.toastr.error(response["error"]["message"], "Sorry!", {
            positionClass: "toast-bottom-center",
            timeOut: 5000,
          });
        });
    }
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }
  getError(el) {
    switch (el) {
      case "email":
        if (this.formGroup.get("email").hasError("required")) {
          return "Enter your email please!";
        }
        if (this.formGroup.get("email").hasError("pattern")) {
          return "Please provide a valid email address!";
        }
        break;
      case "address":
        if (this.formGroup.get("address").hasError("required")) {
          return "Enter your address please!";
        }
        break;
      case "country":
        if (this.formGroup.get("country").hasError("required")) {
          return "Enter your country please!";
        }
        break;
      case "city":
        if (this.formGroup.get("city").hasError("required")) {
          return "Enter your city please!";
        }
        break;
      case "name":
        if (this.formGroup.get("name").hasError("required")) {
          return "Enter your full name please!";
        }
        break;
      default:
        return "";
    }
  }
  createForm() {
    this.formGroup = this.formBuilder.group({
      address_line1: [""],
      email: [
        "",
        [
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ],
      ],
      name: ["", Validators.required],
      country: ["", Validators.required],
      city: ["", Validators.required],
      phone: [""],
      state: ["", Validators.required],
      address_line2: ["", Validators.required],
      postal_code: ["", Validators.required],
    });
  }
  async onOkClick() {
    let result = await this.stripe.createToken(this.elem);
    if (result.error) {
      const displayError = document.getElementById("card-errors");
      displayError.textContent = result.error.message;
      // Inform the user if there was an error
    } else {
      this.onProcessing = true;
      let billingObj = {};
      billingObj["address"] = {
        line1: this.formGroup.controls["address_line1"].value,
        line2: this.formGroup.controls["address_line2"].value,
        country: this.formGroup.controls["country"].value,
        city: this.cities.filter(
          (item) => item["state_code"] == this.formGroup.controls["city"].value
        )[0]['name'],
        state: this.formGroup.controls["state"].value,
        postal_code: this.formGroup.controls["postal_code"].value,
      };
      billingObj["phone"] = this.dial_code + this.formGroup.controls["phone"].value;
      billingObj["name"] = this.formGroup.controls["name"].value;
      billingObj["email"] = this.formGroup.controls["email"].value;
      const result = await this.stripe.createPaymentMethod({
        type: "card",
        card: this.elem,
        billing_details: billingObj,
      });
      this.stripePaymentMethodHandler(result);
      // Send the token to your server
    }
  }
  async countryChange() {
    let selectedCountry = this.countries.filter(
      (item) => item["code"] == this.formGroup.value["country"]
    )[0];
    this.dial_code = selectedCountry["dial_code"];
    let citiesOfcountry = await this.dataService
      .getCityByCountry(selectedCountry["name"])
      .toPromise();
    this.cities = citiesOfcountry["data"]["states"];
    this.formGroup.controls["city"].setValue("");
    this.formGroup.controls["state"].setValue("");
  }
  async cityChange() {
    let selectedCountry = this.countries.filter(
      (item) => item["code"] == this.formGroup.value["country"]
    )[0];
    let selectedCity = this.cities.filter(
      (item) => item["state_code"] == this.formGroup.value["city"]
    )[0];
    let statesOfCity = await this.dataService
      .getStatesByCity(selectedCountry["name"], selectedCity["name"])
      .toPromise();
    this.states = statesOfCity["data"];
    this.formGroup.controls["state"].setValue("");
  }
}
