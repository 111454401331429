import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "project-card",
  templateUrl: "./project-card.component.html",
  styleUrls: ["./project-card.component.scss"],
})
export class ProjectCardComponent {
  @Input() project;
  @Input() installingMessage;
  @Input() installingProgress;
  @Input() paymentMethods;
  @Input() user;
  @Output() openRemoveDialog: EventEmitter<any> = new EventEmitter();
  @Output() reTryProject: EventEmitter<any> = new EventEmitter();
  @Output() openUpdateDialog: EventEmitter<any> = new EventEmitter();
  @Output() goToServer: EventEmitter<any> = new EventEmitter();

  constructor() {}

  openRemoveDialogClick() {
    this.openRemoveDialog.emit(this.project);
  }
  reTryProjectClic() {
    this.reTryProject.emit(this.project);
  }
  openUpdateDialogClick() {
    this.openUpdateDialog.emit(this.project);
  }
  goToServerClick() {
    this.goToServer.emit(this.project);
  }
}
