import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "./../../../environments/environment";
import { DataService } from "./../data.service";
@Injectable({
  providedIn: "root",
})
export class ProfileService {
  apiBucketUrl = `${environment.url}bucket/`;
  apiFunctionsUrl = `${environment.url}fn-execute/`;
  bucketPasswordRecovery: string = "5e2abd24b0a66e9336fede7e";

  constructor(private http: HttpClient, private dataService: DataService) {}

  updateProfile(user) {
    return this.http.post(this.apiFunctionsUrl + "updateProfile", {
      id: user.id,
      email: user.email,
      name: user.name,
      surname: user.surname,
      newPassword: user.newPassword,
      identity_id: user.identity_id,
      number: user.number,
    });
  }

  resetPassword(recover_id: string, password: string) {
    return this.http.post(this.apiFunctionsUrl + "resetPassword", {
      recover_id,
      password,
    });
  }
  deleteAccount() {
    return this.http.post(this.apiFunctionsUrl + "deleteAccount", {
      user_id: localStorage.getItem("user_id"),
    });
  }
  getPasswordRecoveryCode(code: string) {
    return this.http.get(
      this.apiBucketUrl +
        this.bucketPasswordRecovery +
        `/data?relation=true&filter={"recover_id":"${code}"}`
    );
  }
}
