import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "./../../../environments/environment";
import { User } from "./../../interfaces/user";
import { Organization } from "./../../interfaces/organization";
import { map, switchMap, tap } from "rxjs/operators";
import { DataService } from "./../data.service";
import { of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class OrganizationService {
  apiBucketUrl = `${environment.url}bucket/`;
  apiFunctionsUrl = `${environment.url}fn-execute/`;
  bucketInvitations = "5de51f310566406e5df0d888";
  bucketOrganizations: string = "5dda80c50566400742f0d774";
  bucketUsers: string = "5dd911410566400169f0d755";
  bucketUserOrganizations: string = "5f2c1d64107b9e377a7a0fbc";
  bucketOrganizationInvites: string = "5de51f310566406e5df0d888";

  constructor(private http: HttpClient, private dataService: DataService) {}

  getOrganization(organizationId: string) {
    return this.http.get(
      this.apiBucketUrl +
        this.bucketOrganizations +
        `/data?relation=owner&filter={"_id":"${organizationId}"}`
    );
  }

  getOrganizationsV3(userId: string) {
    const queryParams = `relation=true&filter={"users._id":{"$in":["${userId}"]}}`;

    return this.http.get(
      this.apiBucketUrl + this.bucketOrganizations + "/data" + "?" + queryParams
    );
  }

  getOrganizationUsers(organizationId: string, relation = false) {
    let organizationIdKey = "organization";
    if (relation) {
      organizationIdKey = "organization._id";
    }
    const filter = {
      [organizationIdKey]: organizationId,
    };

    let url = new URL(
      this.apiBucketUrl + this.bucketUserOrganizations + "/data"
    );

    url.searchParams.set("relation", relation.toString());
    url.searchParams.set("filter", JSON.stringify(filter));
    url.searchParams.set("paginate", true.toString());

    return this.http.get(url.toString());
  }
  getOrganizationInvitations(organizationId: string) {
    return this.http.get(
      this.apiBucketUrl +
        this.bucketOrganizationInvites +
        '/data?filter={"organization":"' +
        organizationId +
        '"}&paginate=true'
    );
  }
  userRemoveOrganization(organizationId: string, user) {
    if (!user.invitation_id)
      return this.http.post(
        this.apiFunctionsUrl + "unlinkUserFromOrganization",
        {
          user_id: user._id,
          organization_id: organizationId,
        }
      );
    //Remove Invitation
    else return this.removeInvitation(user);
  }
  inviteUserToOrganization(
    organization_id: string,
    email: string,
    role: string
  ) {
    return this.http.post(this.apiFunctionsUrl + "inviteUserToOrganization", {
      email,
      organization_id,
      role,
    });
  }
  changeOrganizationName(organization_id, organization: Organization) {
    return this.http.put(
      this.apiBucketUrl + this.bucketOrganizations + "/data/" + organization_id,
      organization
    );
  }
  userUpdateRole(organization_id: string, user, role: string) {
    // console.log("organization_id :", organization_id, "user :", user, "role: ", role);

    return this.http.put(this.apiFunctionsUrl + "updateRole", {
      user: user,
      organization_id: organization_id,
      role: role,
    });
  }
  getUserInvitations(email: string) {
    return this.http.get(
      this.apiBucketUrl +
        this.bucketOrganizationInvites +
        `/data?relation=true&filter={"email":"${email}"}`
    );
  }
  createOrganization(organizationName: string) {
    return this.http
      .post(this.apiBucketUrl + this.bucketOrganizations + "/data", {
        name: organizationName,
        owner: localStorage.getItem("user_id"),
        users: [localStorage.getItem("user_id")],
      })
      .pipe(tap(console.log));
  }

  removeOrganization(organizationId: string) {
    return this.http.post(this.apiFunctionsUrl + "deleteOrganization", {
      organization_id: organizationId,
    });
  }
  userAddOrganization(organizationId: string, user: User, role: string) {
    if (!user.organizations) {
      user.organizations = [];
    }

    let userAddOrganization = {
      role: role,
      user: user._id,
      organization: organizationId,
    };

    return this.http
      .post(
        this.apiBucketUrl + this.bucketUserOrganizations + "/data",
        userAddOrganization
      )
      .pipe(
        switchMap((usersOrganization) => {
          user.organizations = user.organizations.map((org) => org["_id"]);
          user.organizations.push(usersOrganization["_id"]);
          return this.http.put(
            this.apiBucketUrl + this.bucketUsers + "/data/" + user._id,
            user
          );
        }),
        switchMap((user) => {
          const lastUserOrganizationId = user["organizations"].pop();
          return this.http.get(
            this.apiBucketUrl +
              this.bucketUserOrganizations +
              `/data/${lastUserOrganizationId}?relation=organization`
          );
        }),
        switchMap((data) => {
          const organization = data["organization"];

          organization["users"].push(user._id);
          return this.http.put(
            this.apiBucketUrl +
              this.bucketOrganizations +
              "/data/" +
              organizationId,
            organization
          );
        })
      );
  }

  removeInvitation(invitation) {
    return this.http.delete(
      this.apiBucketUrl + this.bucketInvitations + "/data/" + invitation._id
    );
  }
  getUserOrganizations() {
    return this.http.get(
      this.apiBucketUrl +
        this.bucketOrganizations +
        `/data?filter={"owner":"${localStorage.getItem("user_id")}"}`
    );
  }
}
