import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "./../../../environments/environment";
@Injectable({
  providedIn: "root",
})
export class PaymentService {
  apiBucketUrl = `${environment.url}bucket/`;
  apiFunctionsUrl = `${environment.url}fn-execute/`;

  constructor(private http: HttpClient) { }

  addPaymentMethod(customer: string, method_id: string) {
    return this.http.post(this.apiFunctionsUrl + "addPaymentMethod" + (environment.stripe_in_test ? 'Test' : ''), {
      customer,
      method_id,
    });
  }

  removePaymentMethod(customer: string, method_id: string) {
    return this.http.post(this.apiFunctionsUrl + "deletePaymentMethod" + (environment.stripe_in_test ? 'Test' : ''), {
      customer,
      method_id,
    });
  }
  setDefaultPaymentMethod(customer: string, method_id: string) {
    return this.http.post(this.apiFunctionsUrl + "setDefaultPaymentMethod" + (environment.stripe_in_test ? 'Test' : ''), {
      customer,
      method_id,
    });
  }

  getPaymentMethods(customer: string) {
    return this.http.post(this.apiFunctionsUrl + "getPaymentMethods" + (environment.stripe_in_test ? 'Test' : ''), {
      customer,
    });
  }
}
