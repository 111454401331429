import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ProjectService } from "src/app/services/page-services/project.service";

@Component({
  selector: "./update-version",
  templateUrl: "../popups/update-version.html",
})
export class UpdateVersionDialog {
  onProcessing: boolean = false;
  version: string;
  project;
  versions;
  constructor(
    public dialogRef: MatDialogRef<UpdateVersionDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private projectService: ProjectService
  ) {}

  ngOnInit() {
    this.version = this.data.version;
    this.project = this.data.project;
    this.setVersions();
  }
  onNoClick(): void {
    this.dialogRef.close(false);
  }
  setVersions() {
    this.projectService.getVersions().subscribe((data: any) => {
      this.versions = data.filter((item) => {
        let temp_vers = item.name.split(".").join("");
        return Number(temp_vers) >= Number(this.version.split(".").join(""));
      });
    });
  }
  updateVersion() {
    this.onProcessing = true;
    this.data.project["version"] = this.version;
    this.projectService
      .updateVersion(this.data.project)
      .toPromise()
      .then(
        () => {
          console.log("close true");
          this.dialogRef.close(true);
        },
        (error) => {
          console.log("close error", error);
          this.dialogRef.close(false);
        }
      );
  }
}
