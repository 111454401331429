import { Component, OnInit } from "@angular/core";
import { User } from "../../interfaces/user";
import { AuthService } from "../../services/auth.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit {
  privacy: boolean = false;
  dataPassed: boolean = true;
  passwordChecker = {
    invalid: false,
    confirmation: null,
    strength: 100,
  };
  user: User = {
    name: undefined,
    surname: undefined,
    email: undefined,
    password: undefined,
    number: undefined,
  };

  first_step: boolean = true;
  final_step: boolean = false;
  mailAuthCodeBackend: any = null;
  mailAuthCodeClient: any = null;
  minBirthdayDate: Date;
  maxBirthdayDate: Date;
  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private router: Router
  ) {
    const currentYear = new Date().getFullYear();
    this.minBirthdayDate = new Date(currentYear - 80, 0, 1);
    this.maxBirthdayDate = new Date(currentYear - 10, 11, 31);
  }

  ngOnInit() {}

  register() {
    if (!this.user) {
      this.toastr.error(undefined, "Form Error", {
        positionClass: "toast-bottom-center",
      });
      return;
    }
    if (!this.validateEmail(this.user.email)) {
      this.toastr.error(undefined, "Incorrect E-mail Adress", {
        positionClass: "toast-bottom-center",
      });
      return;
    }
    if (!this.validateMobileNumber(this.user.number)) {
      this.toastr.error(undefined, "Incorrect Mobile Number", {
        positionClass: "toast-bottom-center",
      });
      return;
    }
    this.dataPassed = false;
    this.authService
      .register(this.user)
      .toPromise()
      .then((data) => {
        this.first_step = false;
        this.final_step = true;
        this.mailAuthCodeBackend = data.auth_code;
        this.toastr.success(undefined, data.message, {
          positionClass: "toast-bottom-center",
        });
        this.dataPassed = true;
      })
      .catch((data) => {
        this.toastr.error(undefined, data.error.message, {
          positionClass: "toast-bottom-center",
        });
        this.dataPassed = true;
      });
  }

  finalRegister() {
    this.dataPassed = false;
    let auth_verify =
      this.mailAuthCodeClient == atob(this.mailAuthCodeBackend) ? true : false;
    this.authService
      .mailAuthRegister(this.user, auth_verify)
      .toPromise()
      .then((data) => {
        this.toastr.success(undefined, data.message, {
          positionClass: "toast-bottom-right",
          timeOut: 1000,
        });

        setTimeout(() => {
          this.router.navigate(["/login"]);
        }, 1000);
        this.dataPassed = true;
      })
      .catch((data) => {
        this.toastr.error(undefined, data.error.message, {
          positionClass: "toast-bottom-center",
        });
        this.dataPassed = true;
      });
  }
  validateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(mail)) {
      return true;
    }
    return false;
  }
  validateMobileNumber(number) {
    if (!number) return true;
    if (/^((\+)?(\d{1,3}))?(\d{7,}){1}?$/.test(number)) {
      return true;
    }
    return false;
  }

  checkStrength() {
    let p = this.user.password;
    // 1
    let force = 0;

    // 2
    const regex = /[$-/:-?{-~!"^_@`\[\]]/g;
    const lowerLetters = /[a-z]+/.test(p);
    const upperLetters = /[A-Z]+/.test(p);
    const numbers = /[0-9]+/.test(p);
    const symbols = regex.test(p);

    // 3
    const flags = [lowerLetters, upperLetters, numbers, symbols];

    // 4
    let passedMatches = 0;
    for (const flag of flags) {
      passedMatches += flag === true ? 1 : 0;
    }

    // 5
    force += 2 * p.length + (p.length >= 10 ? 1 : 0);
    force += passedMatches * 10;

    // 6
    force = p.length <= 6 ? Math.min(force, 10) : force;

    // 7
    force = passedMatches === 1 ? Math.min(force, 10) : force;
    force = passedMatches === 2 ? Math.min(force, 20) : force;
    force = passedMatches === 3 ? Math.min(force, 30) : force;
    force = passedMatches === 4 ? Math.min(force, 40) : force;

    this.passwordChecker.strength = force;
  }

  checkPass() {
    if (this.passwordChecker.confirmation != this.user.password) {
      this.passwordChecker.invalid = true;
    } else {
      this.passwordChecker.invalid = false;
    }
  }
}
