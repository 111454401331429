import { Component, OnInit } from "@angular/core";
import { DataService } from "../../services";
import { ToastrService } from "ngx-toastr";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialog } from "../popups/confirmation-popup.component";
import { AuthService } from "../../services/auth.service";
import { Router } from "@angular/router";
import { ProfileService } from "src/app/services/page-services/profile.service";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  constructor(
    private dataService: DataService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    public router: Router,
    private authService: AuthService,
    private profileService: ProfileService
  ) {}
  passwordChecker = {
    invalid: false,
    confirmation: null,
    strength: 100,
  };
  dataPassed: boolean = false;
  userId: string;
  name: string;
  surname: string;
  email: string;
  new_password: string;
  cur_password: string;
  identity_id: string;
  number: string;

  ngOnInit() {
    this.dataService.getUserWithIdentity().subscribe((res) => {
      let user = res;
      this.userId = user._id;
      this.name = user.name;
      this.surname = user.surname;
      this.number = user.number;
      this.email = user.email;
      this.identity_id = user.identity_id;
      this.dataPassed = true;
    });
  }

  onUpdate() {
    /*
    if (!this.cur_password) {
      this.toastr.error(
        "Please enter your current password to update your profile",
        "Profile Update",
        {
          positionClass: "toast-bottom-center",
          timeOut: 2500,
        }
      );
      return; 
    }*/
    if (!this.validateMobileNumber()) {
      this.toastr.error(undefined, "Incorrect Mobile Number", {
        positionClass: "toast-bottom-center",
      });
      return;
    }
    this.dataPassed = false;
    this.profileService
      .updateProfile({
        id: this.userId,
        name: this.name,
        surname: this.surname,
        number: this.number,
        newPassword: this.new_password,
        identity_id: this.identity_id,
        email: this.email,
      })
      .toPromise()
      .then(() => {
        this.dataPassed = true;
        this.toastr.success("Your profile has been updated", "Profile Update", {
          positionClass: "toast-bottom-center",
          timeOut: 1000,
        });
      })
      .catch((error) => {
        this.dataPassed = true;
        this.toastr.error(error.error.message, "Profile Update", {
          positionClass: "toast-bottom-center",
          timeOut: 2500,
        });
      });
  }

  deleteAccount() {
    let dialog = this.dialog.open(ConfirmationDialog, {
      width: "30%",
      maxWidth: "400px",
    });
    dialog.componentInstance.title = `Delete Account`;
    dialog.componentInstance.text = `Are you sure to delete your account? To complete your action, please enter your email.`;
    dialog.componentInstance.confirmationText = this.email;
    dialog
      .afterClosed()
      .toPromise()
      .then((data) => {
        if (data) {
          this.dataPassed = false;
          this.profileService
            .deleteAccount()
            .toPromise()
            .then(() => {
              this.toastr.success(
                "Your profile has been deleted. You will be redirected in few seconds",
                "Profile Deleted",
                {
                  positionClass: "toast-bottom-right",
                  timeOut: 1000,
                }
              );
              setTimeout(() => {
                this.authService.logout();
                this.router.navigate(["/login"]);
              }, 3000);
            });
        }
      });
  }
  checkStrength() {
    let p = this.new_password;
    // 1
    let force = 0;

    // 2
    const regex = /[$-/:-?{-~!"^_@`\[\]]/g;
    const lowerLetters = /[a-z]+/.test(p);
    const upperLetters = /[A-Z]+/.test(p);
    const numbers = /[0-9]+/.test(p);
    const symbols = regex.test(p);

    // 3
    const flags = [lowerLetters, upperLetters, numbers, symbols];

    // 4
    let passedMatches = 0;
    for (const flag of flags) {
      passedMatches += flag === true ? 1 : 0;
    }

    // 5
    force += 2 * p.length + (p.length >= 10 ? 1 : 0);
    force += passedMatches * 10;

    // 6
    force = p.length <= 6 ? Math.min(force, 10) : force;

    // 7
    force = passedMatches === 1 ? Math.min(force, 10) : force;
    force = passedMatches === 2 ? Math.min(force, 20) : force;
    force = passedMatches === 3 ? Math.min(force, 30) : force;
    force = passedMatches === 4 ? Math.min(force, 40) : force;

    this.passwordChecker.strength = force;
  }
  validateMobileNumber() {
    if (/^((\\+91-?)|0)?[0-9]{10}$/.test(this.number)) {
      return true;
    }
    return false;
  }
}
