import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { LicenceService } from 'src/app/services/page-services/license.service';

@Component({
  selector: "./create-license",
  templateUrl: "../popups/create-license.html"
})
export class CreateLicenseDialog {
  newProjectName: string = null;
  onProcessing = false;
  error: string = null;
  plan: string;
  packages = {
    starter: "Starter",
    professional: "Professional",
    business: "Business",
    enterprise: "Enterprise",
    on_premise: "On Premise"
  };
  constructor(
    public dialogRef: MatDialogRef<CreateLicenseDialog>,
    @Inject(MAT_DIALOG_DATA) public data,
    private licenceService: LicenceService
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick() {
    this.onProcessing = true;
    this.licenceService
      .createOnPremiseLicence(
        this.newProjectName
      )
      .toPromise()
      .then(() => {
          this.data = {};
          this.dialogRef.close(false);
        },
      ).catch((error) => {
        this.onProcessing = false;
        this.error = error.error.message;
      });
  }
}
