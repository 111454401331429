<div class="popup-dialog">
  <div class="title">Change Organization Name</div>
  <mat-divider></mat-divider>
  <div class="text">Change your organization name.</div>
  <div class="dialog-input-area">
    <mat-form-field appearance="outline" class="col-12">
      <mat-label>Organizations Name</mat-label>
      <input matInput [(ngModel)]="name" required/>
    </mat-form-field>
  </div>
  <div class="text-right">
    <button mat-button class="btn-choose button-positive" cdkFocusInitial (click)="onOkClick()" [disabled]="!name">
      Ok
    </button>
    <button mat-button class="btn-choose button-negative" (click)="onNoClick()">
      Cancel
    </button>
  </div>
</div>