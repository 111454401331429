import { Component, Inject, Input } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "./confirmation-popup",
  templateUrl: "../popups/confirmation-popup.component.html"
})
export class ConfirmationDialog {

  userInputText: string;
  @Input() title: string;
  @Input() text: string;
  @Input() confirmationText: string;
  @Input() okText: string = "Ok";
  @Input() noText: string = "Cancel";
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onOkClick() {
    this.dialogRef.close(true);
  }
}
