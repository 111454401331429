<app-header-background onPage="projects"></app-header-background>
<div class="content-area projects">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="page-title">Projects</div>
      </div>
    </div>
  </div>
  <div class="container">
    <loading-spinner *ngIf="!dataPassed"></loading-spinner>
    <div class="projects" *ngIf="projectObservable | async">
      <div class="row" *ngIf="dataPassed">
        <div class="col-lg-4 col-md-6 p-3">
          <mat-card
            class="new-project no-elevation transition-normal"
            (click)="openDialog()"
          >
            <div class="row">
              <div class="col-12">
                <span>Start a new project</span>
                <button
                  [disabled]="requestedProject"
                  mat-icon-button
                  mat-flat-button
                  color="primary"
                >
                  <mat-icon fontSet="material-icons-outlined">add</mat-icon>
                </button>
              </div>
            </div>
          </mat-card>
        </div>
        <ng-container *ngFor="let project of filterActiveProjects(projects)">
          <project-card
            class="col-lg-4 col-md-6 p-3"
            [installingMessage]="installingMessage()"
            [installingProgress]="installingProgress"
            [project]="project"
            [paymentMethods]="paymentMethods"
            [user]="user"
            (openRemoveDialog)="openRemoveDialog($event)"
            (openUpdateDialog)="openUpdateDialog($event)"
            (reTryProject)="reTryProject($event)"
            (goToServer)="goToServer($event)"
          ></project-card>
        </ng-container>
      </div>

      <div
        class="row organizations-projects"
        *ngIf="projectGroups.length > 0 && dataPassed"
      >
        <ng-container
          *ngTemplateOutlet="
            recursiveListTmpl;
            context: { list: projectGroups }
          "
        ></ng-container>

        <ng-template #recursiveListTmpl let-list="list">
          <div
            [class]="!item.services?.length ? 'col-lg-4 col-md-6 p-3' : ''"
            *ngFor="let item of filterActiveProjects(list)"
          >
            <div *ngIf="item.services?.length" class="w-100 row col-12 p-3">
              <div class="subtitle">{{ item.project_name }} Services</div>
              <mat-card class="light-border row no-elevation transition-normal">
                <project-card
                  class="p-3 col-lg-4 col-md-6 p-3"
                  [project]="item"
                  [paymentMethods]="paymentMethods"
                  [user]="user"
                ></project-card>
                <!-- <div
                  *ngFor="let item2 of item.services"
                  class="p-3"
                  [class]="
                    item2.services ? 'col-lg-12 col-md-12' : 'col-lg-4 col-md-6'"
                >
                  <div *ngIf="item2.services"> -->
                <ng-container
                  *ngTemplateOutlet="
                    recursiveListTmpl;
                    context: { list: item.services }
                  "
                ></ng-container>
                <!-- </div>
  
                  <project-card
                    *ngIf="!item2.services"
                    [project]="item2"
                    [paymentMethods]="paymentMethods"
                    [user]="user"
                  ></project-card>
                </div> -->
              </mat-card>
            </div>
            <project-card
              *ngIf="!item.services?.length"
              [project]="item"
              [paymentMethods]="paymentMethods"
              [user]="user"
            ></project-card>
          </div>
        </ng-template>
      </div>
      <div
        class="row organizations-projects"
        *ngIf="organizationsProjects.length > 0 && dataPassed"
      >
        <div class="col-12">
          <mat-divider> </mat-divider>
          <div class="subtitle">Projects In My Organizations</div>
        </div>
        <ng-container *ngFor="let project of filterActiveProjects(organizationsProjects)">
          <div class="col-lg-4 col-md-6 p-3">
            <project-card
              [project]="project"
              [paymentMethods]="paymentMethods"
              [user]="user"
            ></project-card>
          </div>
        </ng-container>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="row additional-info">
      <div class="col-md-12 col-lg-6">
        <mat-card class="no-elevation">
          <mat-card-title>
            <mat-icon fontSet="material-icons-outlined">link</mat-icon
            >Quicklinks
          </mat-card-title>
          <mat-card-content>
            <ul>
              <li>
                Please visit
                <a href="https://spicaengine.com/docs/guide" target="_blank"
                  >documentation</a
                >
                to see details about Spica Engine.
              </li>
              <li>
                Check out an
                <a href="https://example.spicaengine.com/spica">
                  example project.</a
                >
              </li>
              <li>
                To join our slack community, you can use this
                <a
                  href="https://spica-engine.slack.com/join/shared_invite/enQtNzYzMDE3NjQ2MTkyLTA3MTg4ZTViZGI0MThiYzdhNTYxMTQxNjcwYzRjZTJhZDE4YWFhOGU5NmUzMGZiYjlmOWY2NDg5OTUxZjM2NDM"
                  target="_blank"
                  >invitation.</a
                >
              </li>
              <li>
                We are at
                <a href="https://discordapp.com/invite/HJTrRMH" target="_blank"
                  >Discord</a
                >! See you there.
              </li>
              <li>
                Follow us on
                <a href="https://twitter.com/spicaengine">twitter</a> to keep
                yourself up-to-date.
              </li>
            </ul>
          </mat-card-content>
          <mat-card-subtitle
            >We are happy to see you in our community
            channels</mat-card-subtitle
          >
        </mat-card>
      </div>
      <div class="col-md-12 col-lg-6">
        <mat-card class="no-elevation">
          <mat-card-title>
            <img src="/assets/logo-blue.png" class="logo" />
            Managed Spica Instances
          </mat-card-title>
          <mat-card-content>
            <ul>
              <li>Don't spend time to maintain your Spica instances</li>
              <li>Boost your development speed</li>
              <li>Create a Spica instance in a minute!</li>
            </ul>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="footer-links">
      <a [routerLink]="['/privacy-policy']">Privacy Policy</a>
      <a href="https://discordapp.com/invite/HJTrRMH" target="_blank"
        >Support</a
      >
      <a href="terms">Terms And Conditions</a>
      <a href="">FAQ</a>
    </div>
  </div>
</div>
