import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "./../../../environments/environment";
import * as Bucket from "@spica-devkit/bucket";
import { map, switchMap, takeUntil, tap } from "rxjs/operators";
import { of, Subject, Subscription } from "rxjs";
import { Server } from "src/app/interfaces/server";
import { License } from "src/app/interfaces/license";
import { AuthService } from "../auth.service";

declare var Stripe: any;

@Injectable({
  providedIn: "root",
})
export class ProjectService {
  apiBucketUrl = `${environment.url}bucket/`;
  apiFunctionsUrl = `${environment.url}fn-execute/`;
  bucketLicenses: string = "5dd912440566406ec8f0d756";
  bucketStorageUsage: string = "5f327734107b9e61a17a13bf";
  bucketServer: string = "5dd913670566404965f0d757";
  bucketPlan: string = "5e8f034ad9255a607d4806ec";
  serverSubs: Subscription;
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(private http: HttpClient, private auth: AuthService) {}

  stripe = Stripe(environment.stripe_in_test ? environment.stripe_test_pk : environment.stripe_live_pk);

  getProjectsByType(type): any {
    return this.http.get(this.apiFunctionsUrl + "getMyProjects?type=" + type, {
      headers: new HttpHeaders().set(
        "Content-Type",
        `application/json, text/plain, */*`
      ),
    });
  }

  getUserProjectsWithRealtime() {
    let licenses: License[];
    let licenseArr = [];
    Bucket.initialize({
      identity: `${this.auth.getToken()}`,
      publicUrl: "https://hq.spicaengine.com/api",
    });
    return Bucket.data.realtime
      .getAll(this.bucketLicenses, {
        filter: `{"$and":[{"user":"${localStorage.getItem(
          "user_id"
        )}"},{"package_code":{"$nin":["on_premise"]}}],"status":{"$in":["active","requested","denied","delete_request"]}}`, //license
        sort: { _id: -1 },
      })
      .pipe(
        takeUntil(this.destroy$),
        tap((data: License[]) => {
          licenses = data;
        }),
        map((data) => {
          licenseArr = [];
          if (data.length > 0) {
            data.map((item) => {
              if (!licenses.some((p) => p._id === item._id))
                licenses.push(item);
            });
          }
          licenses.map((item) => {
            if (item.server) {
              licenseArr.push(item._id);
              return item;
            }
          });
        }),
        switchMap(() => {
          let filterlicense =
            '{"_id":{"$in":' +
            JSON.stringify(
              licenses
                .filter((item) => item["server"] !== undefined)
                .map((item) => item.server["_id"] || item.server)
            ) +
            "}}";
          return licenseArr.length
            ? Bucket.data.realtime
              .getAll(this.bucketServer, {
                filter: `${filterlicense}`,
              })
              .pipe(
                takeUntil(this.destroy$),
                map((servers: Server[]) => {
                  if (servers.length > 0) {
                    licenses.map((license: License) => {
                      if (license.server) {
                        if (typeof license.server === "string") {
                          let temp_server_string = servers.filter(
                            (server) => server._id == license.server
                          )[0];
                          temp_server_string
                            ? (license.server = temp_server_string)
                            : "";
                        }
                        if (typeof license.server === "object") {
                          let temp_server_obj = servers.filter(
                            (server) =>
                              server._id == (license.server as Server)._id
                          )[0];
                          temp_server_obj
                            ? (license.server = temp_server_obj)
                            : "";
                        }
                      } else {
                        license.server = servers.filter(
                          (server) => server._id == license.server
                        )[0];
                      }
                    });
                    return JSON.parse(JSON.stringify(licenses));
                  }
                  return [];
                })
              )
            : of([]);
        })
      );
  }

  getUserProjects() {
    let user = localStorage.getItem("user_id");
    return this.http.get(
      this.apiBucketUrl +
      this.bucketLicenses +
      `/data?relation=true&filter={"$and":[{"user":"${user}"},
      {"status":{"$in":["active","requested","denied","expired"]}} , {"package_code":{"$nin":["on_premise"]}} ]}`
    );
  }
  createProject(
    package_code: string,
    project_name: string,
    asset: string,
    cloud_provider: string = "google"
  ) {
    return this.http
      .post(this.apiFunctionsUrl + "create-project", {
        user: localStorage.getItem("user_id"),
        status: "requested",
        package_code: package_code,
        project_name: project_name,
        cloud_provider,
        demo: environment.stripe_in_test,
      })
      .pipe(
        map((res: any) => {
          if (res.payment_status == "requires_action") {
            this.stripe
              .confirmCardPayment(res.client_secret)
              .then(console.log)
              .catch(console.error);
          }
          return res;
        }),
        tap(({ license }) => {
          if (asset != "blank")
            localStorage.setItem(
              "set_asset",
              JSON.stringify({
                project: license._id,
                asset: asset,
              })
            );
        })
      );
  }

  removeProject(project) {
    project.status = "delete_request";
    if (project.server && project.server["_id"]) {
      project.server = project.server["_id"];
    } //for status of denied

    return this.http.put(
      this.apiBucketUrl + this.bucketLicenses + "/data/" + project._id,
      project
    );
  }

  removeProjectForDenied(project) {
    return this.http.delete(
      this.apiBucketUrl + this.bucketLicenses + "/data/" + project._id
    );
  }

  updateProject(project) {
    return this.http.put(
      this.apiBucketUrl + this.bucketLicenses + "/data/" + project._id,
      project
    );
  }
  updateProjectCors(corsConf) {
    return this.http.post(this.apiFunctionsUrl + "updateCorsConf", {
      server_id: corsConf._id,
      server_plan: corsConf.plan,
      configuration: corsConf.configuration,
    });
  }
  updateVersion(project) {
    return this.http.post(this.apiFunctionsUrl + "updateVersion", project);
  }

  assignProjectToOrganization(organization_id: string, license) {
    if (!license.organizations) {
      license.organizations = [];
    }

    license.organizations.push(organization_id);

    return this.http.put(
      this.apiBucketUrl + this.bucketLicenses + "/data/" + license._id,
      license
    );
  }

  unAssignProjectToOrganization(organization_id: string, license) {
    license.organizations = license.organizations.filter(
      (org) => org != organization_id
    );
    return this.http.put(
      this.apiBucketUrl + this.bucketLicenses + "/data/" + license._id,
      license
    );
  }
  getPlans() {
    return this.http.get(
      this.apiBucketUrl + this.bucketPlan + `/data?filter={"visible":true}`
    );
  }

  getProjectDetails(projectId) {
    return this.http.get(
      this.apiBucketUrl +
      this.bucketLicenses +
      `/data/${projectId}?relation=user&relation=server.plan&relation=organizations`
    );
  }
  getServerDetails(
    name: string,
    startDate: Date,
    endDate: Date,
    period: number
  ) {
    return this.http.post(this.apiFunctionsUrl + "getServerDetails", {
      ns: name,
      start_date: startDate.toISOString(),
      end_date: endDate.toISOString(),
      period,
    });
  }

  getStorageUsage(server: string) {
    return this.http.get(
      this.apiBucketUrl +
      this.bucketStorageUsage +
      `/data?filter={"server":"${server}"}`
    );
  }
  activateObserver(server_id) {
    return this.http.post(this.apiFunctionsUrl + "activateObserver", {
      server_id,
    });
  }

  restartProject(server_id) {
    return this.http.post(this.apiFunctionsUrl + "restartServer", {
      server_id,
    });
  }
  getVersions() {
    return this.http.get(
      "https://api.github.com/repos/spica-engine/spica/tags"
    );
  }
  getStrategies(server) {
    let header = new HttpHeaders({
      "x-authorization": `APIKEY ${server.system_api_key}`,
    });
    return this.http.get(
      `https://${server.ip_address}/api/passport/strategies`,
      {
        headers: header,
      }
    );
  }
}
