import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { switchMap, tap, take } from "rxjs/operators";
import { License } from "src/app/interfaces/license";
import { LicenceService } from "src/app/services/page-services/license.service";
import { ProjectService } from "src/app/services/page-services/project.service";
import { AssetStoreService } from "./../../services/page-services/asset-store.service";
import { environment } from "./../../../environments/environment";
import { Router } from "@angular/router";

@Component({
  selector: "./create-Project",
  templateUrl: "../popups/create-project.html",
  styleUrls: ["./create-project.scss"],
})
export class CreateProjectDialog {
  annualPrices: boolean = false;
  newProjectName: string;
  cloud_providers: {}[] =
    [{ key: 'google', title: 'Google Cloud', properties: ['Horizontal scalable unavailable!', 'CDN is used for storage'] },
    { key: 'aws', title: 'Amazon Web Services', properties: ['S3 is used for storage'] }]
  cloud_provider: string = "google"
  plan: string;
  onProcessing: boolean = false;
  plans: any;
  packagesObservable: Observable<any>;
  packages = [];
  userLicenses: License[];
  freePlan;
  selectedAsset: any = "blank";
  searchAsset: string;
  assets;
  isOpen = false;
  searcDataPassed: boolean = true;
  haveFreeLimit: boolean = false;
  active_step: string = "first";
  reg_ex = environment.project_name_regex;
  paymentMethods: any;

  versions;
  constructor(
    public dialogRef: MatDialogRef<CreateProjectDialog>,
    @Inject(MAT_DIALOG_DATA) public data,
    private licenceService: LicenceService,
    private projectService: ProjectService,
    private assetService: AssetStoreService,
    private router: Router
  ) {
    this.paymentMethods = data.payments;
    this.projectService
      .getPlans()
      .pipe(
        take(1),
        tap((data: []) => {
          data
            .sort(
              (a: { [x: string]: number }, b: { [x: string]: number }) =>
                a["order"] - b["order"]
            )
            .map(
              (plan: {
                title: any;
                storage: {
                  application: string;
                  database: string;
                  storage: string;
                };
                passport_identity_limit: any;
                bucket_data_limit: any;
                cost: any;
                slug: any;
                is_yearly: any;
              }) => {
                this.packages.push({
                  name: plan.title,
                  entry:
                    plan.bucket_data_limit >= 1000
                      ? plan.bucket_data_limit / 1000 + "K"
                      : plan.bucket_data_limit,
                  users:
                    plan.passport_identity_limit >= 1000
                      ? plan.passport_identity_limit / 1000 + "K"
                      : plan.passport_identity_limit,
                  cost: plan.cost,
                  value: plan.slug,
                  yearly: plan.is_yearly,
                  storage: plan.storage.storage,
                });
              }
            );

          //Exlude free-tier
          this.freePlan = this.packages.shift();
        }),
        switchMap(() => {
          return this.assetService.getAssets();
        }),
        tap((assets) => (this.assets = assets)),
        switchMap(() =>
          this.licenceService.getUserLicenses(localStorage.getItem("user_id"))
        ),
        tap((data: any) => {
          let freeProjectCount = 0;
          data.map((item) => {
            if (item.package_code == "free_tier") {
              freeProjectCount++;
            }
          });
          if (freeProjectCount > 1) {
            this.haveFreeLimit = true;
            this.plan = "mini";
          }
          this.plan = this.plan ? this.plan : "free_tier";
        })
      )
      .subscribe();
  }

  choosePlan(p: string) {
    this.plan = p;
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onOkClick() {
    this.onProcessing = true;
    this.projectService
      .createProject(
        this.plan,
        this.newProjectName,
        this.selectedAsset._id || "blank",
        this.cloud_provider
      )
      .toPromise()
      .then(() => {
        this.data = {};
        this.dialogRef.close(true);
      });
  }

  isAnnualViewEnabled(isYearlyPackage) {
    return (
      (isYearlyPackage && this.annualPrices) ||
      (!isYearlyPackage && !this.annualPrices)
    );
  }
  annualChange() {
    if (this.plan && this.plan != "free_tier")
      this.plan = this.annualPrices
        ? this.plan + "_yearly"
        : this.plan.split("_")[0];
  }
  chooseAsset(asset) {
    this.selectedAsset = asset;
  }
  findAssets() {
    this.searcDataPassed = false;
    this.assetService
      .getAssets(this.searchAsset)
      .toPromise()
      .then((data) => {
        this.searcDataPassed = true;
        this.assets = data;
      });
  }
  checkPattern() {
    return environment.project_name_regex.test(this.newProjectName);
  }
  openPaymentMethods() {
    this.onNoClick();
    this.router.navigate(["/payment-method"]);
  }
  selectButtonOfAssets(event) {
    switch (event) {
      case "back":
        this.active_step = "first";
        break;
      case "ok":
        this.onOkClick();
        break;
      case "no":
        this.onNoClick();
        break;
    }
  }
}
