import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { AuthService } from "./auth.service";
import { Observable } from "rxjs";
import { environment } from "./../../environments/environment";
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let x_auth=request.headers.get("x-authorization")
    let authorization;
    if (this.auth.getToken())
      if (x_auth)
        authorization = x_auth;
      else authorization = `IDENTITY ${this.auth.getToken()}`;
    else authorization = `APIKEY ${environment.api_key}`;

    request = request.clone({
      setHeaders: {
        Authorization: authorization,
      },
      body: { ...request.body },
    });
    return next.handle(request);
  }
}
