import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ProjectService } from "src/app/services/page-services/project.service";

@Component({
  selector: "./update-Project",
  templateUrl: "../popups/update-project.html",
  styleUrls: ["../popups/update-project.component.scss"],
})
export class UpdateProjectDialog {
  packages = [
    { name: "starter" },
    { name: "professional" },
    { name: "business" },
    { name: "enterprise" }
  ];
  /*
  packages = {
    starter: "Starter",
    professional: "Professional",
    business: "Business",
    enterprise: "Enterprise",
    on_premise: "On Premise",
  };*/
  plan: string;
  onProcessing: boolean = false;
  paymentMethods: any;
  annualPrices:boolean=false;
  constructor(
    public dialogRef: MatDialogRef<UpdateProjectDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private projectService: ProjectService
  ) {}

  ngOnInit() {
    this.paymentMethods = this.data.payments;
    this.plan = this.data.project.package_code;
  }
  onNoClick(): void {
    this.dialogRef.close(false);
  }

  changePlan() {
    this.onProcessing = true;
    let project = this.data.project;
    project.package_code = this.plan;
    this.projectService
      .updateProject(project)
      .toPromise()
      .then(
        () => {
          this.dialogRef.close(true);
        },
        (error) => {
          this.dialogRef.close(false);
        }
      );
  }
  changeAnnual() {

    if (this.annualPrices) {
      this.packages = this.packages.map(item => {
        item.name = item.name + "_yearly";
        return item;
      });
    } else {
      this.packages = this.packages.map(item => {
        item.name = item.name.split("_yearly")[0]
        return item;
      });
    }
    this.plan=undefined
  }
}
