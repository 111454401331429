import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'legal-titles',
  templateUrl: './legal-titles.component.html',
  styleUrls: ['./legal-titles.component.scss']
})
export class LegalTitlesComponent implements OnInit {

  @Input() subPage: null;
  @Input() page: null;
  constructor() { }

  ngOnInit(): void {
  }

}
