import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { InvoiceService } from "src/app/services/page-services/invoice.service";
import { DataService } from "../../services";
declare var Stripe: any;

@Component({
  selector: "app-invoices",
  templateUrl: "./invoices.component.html",
  styleUrls: ["./invoices.component.scss"],
})
export class InvoicesComponent implements OnInit {
  userMail: string;
  customer: string;

  //Paginator settings
  length: number = 1000;
  pageSize: number = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  paginatedInvoices: any[];
  allInvoices: any[] = [];
  @ViewChild("cardelement") cardelement: ElementRef;
  constructor(
    private dataService: DataService,
    private invoiceService: InvoiceService
  ) {}
  ngOnInit() {
    this.getInvoices();
  }
  async stripePaymentMethodHandler(result) {
    if (result.error) {
      console.log(result.error);
    } else {
      this.invoiceService
        .createCustomerAndSubs(
          result.paymentMethod.billing_details.email,
          result.paymentMethod.id
        )
        .toPromise()
        .then((response) => {
          const payment_intent = response["data"].latest_invoice.payment_intent;
          if (payment_intent) {
            if (payment_intent.status === "requires_action") {
              Stripe.confirmCardPayment(payment_intent.client_secret).then(
                function (result) {
                  if (result.error) {
                    console.log("error");
                  } else {
                    console.log("success subs");
                  }
                }
              );
            } else {
              console.log("success subs");
            }
          }
        });
    }
  }

  paginate(e) {
    this.paginatedInvoices = this.allInvoices.slice(
      e.pageSize * e.pageIndex,
      e.pageSize * e.pageIndex + e.pageSize
    );
  }

  getInvoices() {
    this.dataService
      .getUserWithIdentity()
      .toPromise()
      .then((data) => {
        this.userMail = data.email;
        this.customer = data.stripe_customer_id;
        this.invoiceService
          .getTransactions(this.customer)
          .toPromise()
          .then((invoices) => {
            this.allInvoices = invoices["data"]?.data;
            this.length = this.allInvoices.length;
            this.paginate({ pageIndex: 0, pageSize: this.pageSize });
          })
          .catch((e) => {
            console.log("error :", e);
            this.allInvoices = [];
            this.paginatedInvoices = [];
          });
      });
  }
}
