import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "./get-email",
  templateUrl: "../popups/get-email.html"
})
export class GetEmailDialog {
  email = null;

  constructor(
    public dialogRef: MatDialogRef<GetEmailDialog>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick() {
    this.dialogRef.close({result: true, email: this.email});
  }
}
