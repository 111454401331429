import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { merge, Observable, of, Subject, forkJoin, Subscription } from "rxjs";
import {
  switchMap,
  tap,
  map,
  take,
  takeUntil,
  catchError,
} from "rxjs/operators";
import { CreateProjectDialog } from "../popups/create-project.component";
import { ConfirmationDialog } from "../popups/confirmation-popup.component";
import { UpdateProjectDialog } from "../popups/update-project.component";
import { DataService } from "../../services";
import { User } from "../../interfaces/user";
import { License } from "../../interfaces/license";
import { ProjectService } from "src/app/services/page-services/project.service";
import { PaymentService } from "src/app/services/page-services/payment.service";
import { Server } from "src/app/interfaces/server";
import { AssetStoreService } from "src/app/services/page-services/asset-store.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-projects",
  templateUrl: "./projects.component.html",
  styleUrls: ["./projects.component.scss"],
})
export class ProjectsComponent implements OnInit, OnDestroy {
  serverDetailsInterval;
  user: User;
  dataPassed: boolean = false;
  organizationsProjects = [];
  paymentMethods: any = [];
  projects: License[];
  projectObservable: Observable<License[]>;
  refresh = new Subject();
  installingProgress: number = 0;
  requestedProject: Boolean = false;
  projectsOfRealtime: License[];
  destroy$: Subject<boolean> = new Subject<boolean>();
  subscribtion: Subscription;
  projectGroups = [];

  constructor(
    public dialog: MatDialog,
    private dataService: DataService,
    private projectService: ProjectService,
    private paymentService: PaymentService,
    private assetService: AssetStoreService,
    private toastr: ToastrService
  ) {}

  async ngOnInit() {
    if (!this.user)
      this.user = await this.dataService.getUserWithIdentity().toPromise();
    this.destroyAll();
    this.projectObservable = merge(of(null), this.refresh).pipe(
      switchMap(() => {
        return this.paymentService
          .getPaymentMethods(this.user["stripe_customer_id"])
          .pipe(
            catchError((err): any => {
              console.log(err);
              return of([]);
            })
          );
      }),
      takeUntil(this.destroy$),
      tap((res: any) => {
        this.paymentMethods = res.data ? res.data : [];
        this.subscribtion = this.projectService
          .getUserProjectsWithRealtime()
          .subscribe((data) => {
            this.projects = JSON.parse(JSON.stringify(data));
            this.requestedProject = false;
            this.projectGroups = [];
            this.projects.map((project) => {
              if (project.head_license) {
                let headLicense = this.projects.filter(
                  (item: any) => item._id == project.head_license
                )[0];

                if (
                  !this.projectGroups.some(
                    (item) => item._id == project.head_license
                  )
                ) {
                  headLicense["services"] = [];
                  this.projectGroups.push(headLicense);
                }

                this.projectGroups
                  .filter((item) => item._id == project.head_license)[0]
                  ["services"].push(project);
              }
              //Progress bar tricks, data is not real.
              if (
                (project.server && (project.server as Server).state == 1) ||
                (project.server && (project.server as Server).state == 0)
              ) {
                let projectCreationTime = new Date(
                  (project.server as Server).created_at
                );
                let now = new Date();
                this.installingProgress =
                  ((now.getTime() - projectCreationTime.getTime()) / 1000) *
                  (95 / 60);
                setInterval(() => {
                  this.installingProgress += 95 / 60;
                }, 1000);
                this.requestedProject = true;
              }
              if (
                project.server &&
                (project.server as Server).state == 2 &&
                this.isFiveMinuteAgo(project.server["created_at"])
              ) {
                this.dataService
                  .isServerAvailable(project.server["ip_address"])
                  .toPromise()
                  .catch((error) => {
                    if (error.status == 0 || error.status >= 500) {
                      project.server["state"] = 3;
                    }
                    // if api stand up
                    else {
                      if (localStorage.getItem("set_asset")) {
                        let willIntegrateAsset = JSON.parse(
                          localStorage.getItem("set_asset")
                        );
                        if (willIntegrateAsset.project == project._id) {
                          this.integrateAsset(
                            project.server["_id"],
                            willIntegrateAsset.asset
                          );
                          localStorage.removeItem("set_asset");
                        }
                      }
                    }
                  });
              }
            });

            this.projects = this.projects.filter(
              (item) =>
                !this.projectGroups.some((item2) => item._id == item2._id) &&
                !item.head_license
            );
            this.projectGroups = this.projectGroups.filter(
              (item) => !item.head_license
            );
            this.projectGroups.forEach((item) => {
              if (item.services) {
                this.sortProjectGroups(item.services);
              }
            });
            this.dataPassed = true;
          });
      })
    );
    this.organizationsProjects = await this.projectService
      .getProjectsByType("organization")
      .toPromise();
    this.organizationsProjects.forEach((item) => {
      item.organizations.forEach((element) => {
        if (element.users.includes(this.user._id)) {
          item["from"] = " " + element.name + " ";
        }
      });
    });
  }

  sortProjectGroups(services) {
    services.sort((a, b) => {
      a.services = a.services || [];
      b.services = b.services || [];
      return a.services.length - b.services.length;
    });
    services.forEach((element) => {
      if (element.services) {
        this.sortProjectGroups(element.services);
      }
      return;
    });
  }

  ngOnDestroy() {}
  destroyAll() {
    this.destroy$.next();
    this.projectService.destroy$.next();
  }

  filterActiveProjects(projects) {
    return projects.filter(
      (project) =>
        project.server &&
        project.status != "denied" &&
        project.server.state != 6
    );
  }

  openDialog(): void {
    if (this.requestedProject) {
      return;
    }
    this.dialog
      .open(CreateProjectDialog, {
        width: "100%",
        maxWidth: "800px",
        disableClose: true,
        data: { payments: this.paymentMethods },
      })
      .afterClosed()
      .toPromise()
      .then((isOk) => {
        if (isOk) {
          this.dataPassed = false;
        }
      });
  }

  openRemoveDialog(project) {
    let dialog = this.dialog.open(ConfirmationDialog, {
      width: "30%",
      maxWidth: "400px",
    });

    dialog.componentInstance.text =
      "Do you want to delete the project ? You CAN NOT re-open the project again. " +
      "Your remaining subscriptions time will be added to your balance in a few hours.";
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.projects.map((p, i) => {
          if (p._id == project._id) {
            if (project.server) {
              (this.projects[i].server as Server).state = 4;
            }
          }
        });

        //for status of denied
        this.projectService
          .removeProject(project)
          .toPromise()
          .then(
            () => {
              this.projects = this.projects.filter((p) => p._id != project._id);
              //  this.dataPassed = true;
              //   this.refresh.next();
            },
            (error) => {
              console.error(error);
            }
          );
      }
    });
  }
  openUpdateDialog(project) {
    this.dialog
      .open(UpdateProjectDialog, {
        width: "30%",
        maxWidth: "400px",
        data: { project, payments: this.paymentMethods },
      })
      .afterClosed()
      .toPromise()
      .then((data) => {
        if (data) {
          // this.dataPassed = true;
          // this.dataPassed = false;
          // this.refresh.next();
        }
      });
  }

  reTryProject(project) {
    if (project.server) {
      project.status = "active";
      project.server = project.server._id;
      this.projectService.updateProject(project).toPromise();
    } else {
      project.status = "requested";
      this.projectService
        .createProject(
          project.package_code,
          project.project_name,
          "blank",
          project.cloud_provider
        )
        .toPromise()
        .then(() => {
          this.projectService.removeProjectForDenied(project).toPromise();
        });
    }
  }

  installingMessage() {
    this.installingProgress = Math.min(this.installingProgress, 95);
    if (this.installingProgress < 5) return "Requested a new server";
    else if (this.installingProgress < 30) return "Installing CDN buckets";
    else if (this.installingProgress < 45) return "Creating servers";
    else if (this.installingProgress < 60) return "Preparing files";
    else if (this.installingProgress < 80) return "Last health cheks";
    else if (this.installingProgress <= 95) return "Almost ready!";
  }

  isFiveMinuteAgo(date) {
    let minuteDiff = Math.floor(
      (new Date().getTime() - new Date(date).getTime()) / (1000 * 60)
    );
    if (minuteDiff < 5) {
      return true;
    }
    return false;
  }

  integrateAsset(server, asset) {
    this.assetService.login().subscribe(() => {
      let decodedToken = this.assetService.getDecodeToken();
      this.assetService
        .integrate(server, decodedToken._id, asset)
        .toPromise()
        .then((data) => {
          this.toastr.success(undefined, data.message, {
            positionClass: "toast-bottom-center",
            timeOut: 2500,
          });
        })
        .catch((error) => {
          console.log(error.message);
        });
    });
  }
  goToServer(element) {
    this.projectService
      .getStrategies(element.server)
      .toPromise()
      .then(
        (data: any) => {
          let head_strategy = data.filter(
            (item) => item.name == "headquarters"
          )[0];
          if (head_strategy)
            window.open(
              `http://${element.server["ip_address"]}/passport/identify?strategy=${head_strategy._id}`,
              "_blank"
            );
        },
        (err) => console.log("error : ", err)
      );
  }
}
