import { Component, HostListener, OnInit } from "@angular/core";
import { Router, RoutesRecognized } from "@angular/router";
import { AuthService } from "./services/auth.service";
import { Title } from "@angular/platform-browser";
import { Subject } from "rxjs";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "hq";
  public innerWidth: number;
  activeUrl: string;
  username: string;
  routeData = new Subject();

  constructor(
    private authService: AuthService,
    public router: Router,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.username = localStorage.getItem("username");
    //this.activeUrl = this.activatedRoute.snapshot;
    this.router.events.subscribe((data) => {
      this.activeUrl = this.router.url;
      if (data instanceof RoutesRecognized) {
        this.routeData.next(data);
      }
    });
    this.routeData.subscribe((data: any) => {
      if (data["url"]) {
        if (data["url"].includes("login")) {
          this.username = undefined;
        }
        let title =
          data["url"] != "/"
            ? data["url"]
                .substring(1, data["url"].length)
                .charAt(0)
                .toUpperCase() +
              data["url"].substring(1, data["url"].length).substring(1)
            : "Spica";
        this.titleService.setTitle("Head Quarters | " + title);
      }
    });
    this.innerWidth = window.innerWidth;
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }
  logOut() {
    this.authService.logout();
    this.router.navigate(["/login"]);
  }
}
