<div class="back-splash">
  <img
    *ngIf="pageName == 'projects'"
    src="../../../assets/images/png/astro-projects.png"
    class="banner-right-image d-none d-lg-block"
  />
  <img
    *ngIf="pageName == 'organization'"
    src="../../../assets/images/png/astro-organizations.png"
    class="banner-right-image d-none d-lg-block"
  />
  <img
    *ngIf="pageName == 'invoices'"
    src="../../../assets/images/png/astro-invoices.png"
    class="banner-right-image d-none d-lg-block"
  />
  <img
    *ngIf="pageName == 'licenses'"
    src="../../../assets/images/png/astro-licenses.png"
    class="banner-right-image d-none d-lg-block"
  />
  <img
    *ngIf="pageName == 'editorganization'"
    src="../../../assets/images/png/astro-organizations.png"
    class="banner-right-image d-none d-lg-block"
  />
  <img
    *ngIf="pageName == 'details'"
    src="../../../assets/images/png/astro-projects.png"
    class="banner-right-image d-none d-lg-block"
  />
</div>
