<app-header-background onPage="editorganization"></app-header-background>

<div class="content-area pt-4 pb-4">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="page-title">
          <div class="left">
            {{ organization ? organization.name : "" }}
            <mat-icon *ngIf="organization?.owner?.identity_id==identity_id" fontSet="material-icons-outlined"
              (click)="updateOrganization()">edit</mat-icon>
          </div>
          <button mat-button color="" (click)="openDialog()">
            Invite A User
            <mat-icon fontSet="material-icons-outlined">add</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-lg-12 mt-3 text-right">
        <div class="user-list" *ngIf="data$ | async">
          <loading-spinner *ngIf="!dataPassed"></loading-spinner>

          <table *ngIf="dataPassed" mat-table class="w-100" [dataSource]="organizationUsers">
            <ng-container matColumnDef="name">
              <th class="text-left" mat-header-cell *matHeaderCellDef>Name</th>
              <td class="text-left" mat-cell *matCellDef="let orgUser">
                {{ orgUser?.user?.name }} {{ orgUser?.user?.surname }}
              </td>
            </ng-container>
            <ng-container matColumnDef="email">
              <th class="text-center" mat-header-cell *matHeaderCellDef>
                Email
              </th>
              <td class="text-center" mat-cell *matCellDef="let orgUser">
                {{ orgUser.user ? orgUser.user.email : orgUser.email }}
              </td>
            </ng-container>
            <ng-container matColumnDef="role">
              <th class="text-center" mat-header-cell *matHeaderCellDef>
                Role
              </th>
              <td class="text-center" mat-cell *matCellDef="let orgUser">

                {{ (orgUser.role) ? (orgUser.role | uppercase) : userRole | uppercase }}
              </td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th class="text-right" mat-header-cell *matHeaderCellDef>
                Action
              </th>
              <td class="text-right" mat-cell *matCellDef="let orgUser">
                <button *ngIf="orgUser.invitation_id && userRole!='member' " matTooltip="Invited user" mat-icon-button>
                  <mat-icon fontSet="material-icons-outlined">hourglass_empty </mat-icon>
                </button>

                <button *ngIf="!orgUser.invitation_id &&userRole!='member' && orgUser.role !='owner'" mat-icon-button
                  (click)="userUpdateRole(orgUser.user)">
                  <mat-icon fontSet="material-icons-outlined">edit </mat-icon>
                </button>
                <button *ngIf="!orgUser.invitation_id && userRole!='member' && orgUser.role !='owner'" mat-icon-button
                  (click)="userRemoveOrganization(orgUser.user)">
                  <mat-icon fontSet="material-icons-outlined">delete </mat-icon>
                </button>
              </td>
            </ng-container>
            <tr class="" mat-header-row *matHeaderRowDef="['name', 'email','role', 'actions']"></tr>
            <tr class="" mat-row *matRowDef="let row; columns: ['name', 'email','role', 'actions']"></tr>
          </table>        
      </div>
    </div>
  </div>
</div>