import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map, tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class GithubAuthService {

  cors_url = "https://cors-anywhere.herokuapp.com/";


  constructor(private http: HttpClient) {}

  getGithubToken(code) {
    return this.http.post(
      `${this.cors_url}https://github.com/login/oauth/access_token`,
      {
        client_id: "febaefef70feb3a97d92",
        client_secret: `477498a8a2b54a7ba9cd803c8dc271d9e17b32f6`,
        code: code,
      }
    );
  }

  getGithubUser(access_token) {
    return this.http.get(`${this.cors_url}https://api.github.com/user`, {
      headers: new HttpHeaders().set("Authorization", `token ${access_token}`),
    });
  }

}
