<div class="popup-dialog">
  <div class="title">Send invite</div>
  <mat-divider></mat-divider>
  <div class="text">Share your Spica instance with your colleagues. We will send a registration invitation if there is no registered user with the provided email address.</div>
  <div class="dialog-input-area">
    <mat-form-field appearance="outline" class="col-12">
      <mat-label>User email</mat-label>
      <input matInput [(ngModel)]="userMail" required
      pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"/>
    </mat-form-field>
    <mat-form-field appearance="outline" class="col-12">
      <mat-label>Role</mat-label>
      <mat-select matNativeControl [(ngModel)]="role" required>
        <mat-option value="admin">Admin</mat-option>
        <mat-option value="member">Member</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="text-right">
    <button mat-button class="btn-choose button-positive" cdkFocusInitial (click)="onOkClick()" [disabled]="!role || !userMail">
      Ok
    </button>
    <button mat-button class="btn-choose button-negative" (click)="onNoClick()">
      Cancel
    </button>
  </div>
</div>