<div class="text-asset col-12">
  Select to start with sample projects. The asset will be integrated after the
  project is created
</div>
<mat-form-field
  class="col-12"
  [class.search-field]="searchAsset"
  appearance="outline"
>
  <input
    placeholder="Search asset"
    autocomplete="off"
    [(ngModel)]="searchAsset"
    (ngModelChange)="findAssets()"
    matInput
  />
  <mat-icon *ngIf="!searchAsset" matSuffix>search</mat-icon>
  <button
    mat-button
    *ngIf="searchAsset && searcDataPassed"
    matSuffix
    mat-icon-button
    aria-label="Clear"
    (click)="searchAsset = ''; findAssets()"
  >
    <mat-icon>close</mat-icon>
  </button>
  <mat-spinner
    [diameter]="20"
    matSuffix
    *ngIf="searchAsset && !searcDataPassed"
  ></mat-spinner>

  <!-- <button mat-button *ngIf="searchAsset && !searcDataPassed" matSuffix mat-icon-button aria-label="Clear"
    (click)="searchAsset=''">
    <mat-icon>close</mat-icon>
  </button> -->
</mat-form-field>

<div
  class="asset-list"
  [class.full-height]="assets?.length > 3"
  [class.half-height]="assets?.length > 1 && assets?.length < 4"
>
  <div class="asset">
    <mat-card
      class="no-elevation"
      [class.choosen]="selectedAsset == 'blank'"
      (click)="selectedAsset = 'blank'"
    >
      <mat-card-content>
        <div class="row blank">
          <div class="col-12 align-self-center">Blank Project</div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="asset" *ngFor="let asset of assets">
    <mat-card class="no-elevation" [class.choosen]="selectedAsset == asset">
      <mat-card-content>
        <div class="row">
          <div class="col-11 row" (click)="selectAsset(asset)">
            <div class="col-2 align-self-center">
              <img class="content-img" [src]="asset.images[0]" alt="" />
            </div>
            <div class="col-10 align-self-center asset-desc pr-0">
              <b> {{ asset.name }}</b>
              <div class="asset-desc">
                {{ asset.description }}
              </div>
            </div>
          </div>
          <div class="col-1 align-self-center asset-link">
            <a
              [href]="
                'https://assetstore.spicaengine.com/item-detail/' +
                asset._id +
                ''
              "
              target="_blank"
              matTooltip="See Details"
            >
              <mat-icon class="cursor-pointer">link</mat-icon>
            </a>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<div class="d-flex justify-content-between align-items-center mt-3 col-12">
  <div>
    <button
      *ngIf="withBack"
      (click)="selectButton('back')"
      mat-button
      class="btn-choose button-notr"
    >
      Back
    </button>
  </div>
  <div>
    <button
      mat-button
      class="btn-choose button-positive"
      cdkFocusInitial
      (click)="selectButton('ok')"
    >
      Ok
    </button>
    <button
      mat-button
      class="btn-choose button-negative"
      (click)="selectButton('no')"
    >
      Cancel
    </button>
  </div>
</div>
