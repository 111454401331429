import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { IdentityResult, Identity_AUTH } from "../interfaces/auth";
import { User } from "../interfaces/user";
import { DataService } from "./data.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private http: HttpClient, private dataService: DataService) {}

  loginV2(email: string, password: string) {
    return this.http
      .post<IdentityResult>(
        `${environment.url}fn-execute/v2/loginV2`,
        {
          email,
          password,
        },
        {
          withCredentials: true,
        }
      )
      .pipe(
        tap((res) => {
          if (res && res.identity_auth && res.identity_auth.identity_token) {
            localStorage.setItem("user_id", res.user_id);
            localStorage.setItem("username", res.username);
            this.setSessionV2({
              identity_token: res.identity_auth.identity_token,
              expire: res.identity_auth.expire,
            });
          } else {
            return;
          }
        })
      );
  }

  private setSessionV2(authResult: Identity_AUTH) {
    let date = new Date();
    date.setHours(date.getHours() + Number(authResult.expire / 60));
    localStorage.setItem("token", authResult.identity_token);
    localStorage.setItem("expire", date.toISOString());
  }

  logout() {
    localStorage.clear();
    return this.http
      .post(
        `${environment.url}fn-execute/v2/logout`,
        {},
        { withCredentials: true }
      )
      .toPromise();
  }

  getToken() {
    return localStorage.getItem("token");
  }

  register(userInfo: User) {
    return this.http.post<any>(`${environment.url}fn-execute/register`, {
      ...userInfo,
      first_step: true,
    });
  }

  mailAuthRegister(userInfo: User, auth_verify: boolean) {
    return this.http.post<any>(`${environment.url}fn-execute/register`, {
      ...userInfo,
      auth_verify: auth_verify,
      final_step: true,
    });
  }
  createPasswordRecoveryCode(email: string) {
    return this.http.post(`${environment.url}fn-execute/forgotPassword`, {
      email,
    });
  }
  isAuthenticated(): Observable<boolean> {
    if (
      new Date() < new Date(localStorage.getItem("expire")) &&
      localStorage.getItem("token")
    ) {
      return of(true);
    }
    return of(false);
  }

  loginWithGoogle(userObj: User) {
    return this.http.post<any>(`${environment.url}fn-execute/loginWithGoogle`, {
      user: userObj,
    });
  }
}
