
<mat-card class="legal-card-titles">
    <ul>
        <li><a [routerLink]="['/privacy-policy']" [class.selected]="page == 'privacy'">Privacy Policy</a></li>
        <li><a [routerLink]="['/terms']" [class.selected]="page == 'terms'">Terms And Conditions</a></li>
        <ul>
            <li><a [routerLink]="['/terms/']" [class.selected]="subPage == ''">Spica Core</a></li>
            <li><a [routerLink]="['/terms/dashboard']" [class.selected]="subPage == 'dashboard'">Spicaengine Dashboard</a></li>
            <li><a [routerLink]="['/terms/assetstore']" [class.selected]="subPage == 'assetstore'">Spicaengine Assetstore</a></li>
            <li><a  [routerLink]="['/terms/chronos']" [class.selected]="subPage == 'chronos'">Service: Chronos</a></li>
        </ul>
        <li><a href="">FAQ</a></li>
    </ul>
</mat-card>