<div class="slider" [ngClass]="'active-' + activeSlide">
    <div
      class="slides"
      [ngStyle]="{ transform: 'translateX(' + activeSlide * -20 + '%)' }"
    >
      <div class="slide">
        <div class="slide-image-container">
          <img src="../../../assets/images/png/astro-bucket.png" />
        </div>
        <div class="slide-title">Bucket Architecture</div>
        <div class="slide-text">
          It gives full data model customization. So you can ride wherever you
          want.
        </div>
      </div>
      <div class="slide">
        <div class="slide-image-container">
          <img src="../../../assets/images/png/astro-iam.png" />
        </div>
        <div class="slide-title">Policy-Based Management</div>
        <div class="slide-text">
          Instead of using pre-built roles, you can create your roles now!
        </div>
      </div>
      <div class="slide">
        <div class="slide-image-container">
          <img src="../../../assets/images/png/astro-functions.png" />
        </div>
        <div class="slide-title">Functions</div>
        <div class="slide-text">
          For developers, for creators, for crafters, you can apply any logic
          to your projects.
        </div>
      </div>
      <div class="slide">
        <div class="slide-image-container">
          <img src="../../../assets/images/png/astro-composer.png" />
        </div>
        <div class="slide-title">Frontend Composer</div>
        <div class="slide-text">
          With component-basd interface composer, you can make UI much faster.
        </div>
      </div>
      <div class="slide">
        <div class="slide-image-container">
          <img src="../../../assets/images/png/astro-agnostic.png" />
        </div>
        <div class="slide-title">Platform Agnostic</div>
        <div class="slide-text">
          You can take mobile, PWA or web app builds with using build module.
        </div>
      </div>
    </div>
    <div class="pager">
      <div
        class="dot"
        [class.active-slide]="activeSlide == 0"
        (click)="activeSlide = 0"
      ></div>
      <div
        class="dot"
        [class.active-slide]="activeSlide == 1"
        (click)="activeSlide = 1"
      ></div>
      <div
        class="dot"
        [class.active-slide]="activeSlide == 2"
        (click)="activeSlide = 2"
      ></div>
      <div
        class="dot"
        [class.active-slide]="activeSlide == 3"
        (click)="activeSlide = 3"
      ></div>
      <div
        class="dot"
        [class.active-slide]="activeSlide == 4"
        (click)="activeSlide = 4"
      ></div>
    </div>
    
    <div class="arrows">
      <div class="back" (click)="activeSlide = (activeSlide + 4) % 5"><mat-icon>arrow_back_ios</mat-icon></div>
      <div class="forward" (click)="activeSlide = (activeSlide + 1) % 5"><mat-icon>arrow_forward_ios</mat-icon></div>
    </div>
  </div>