<div class="popup-dialog">
    <div class="title">You need to enter your e-mail address here so that we can send you an e-mail later.</div>
    <mat-divider></mat-divider>
    <div class="text"></div>
    <div class="dialog-input-area">
        <mat-form-field appearance="outline" class="col-12">
            <mat-label>Email</mat-label>
            <input matInput [(ngModel)]="email" required>
        </mat-form-field>
    </div>
    <div class="text-right">
        <button mat-button class="btn-choose button-positive" cdkFocusInitial (click)="onOkClick()" [disabled]="!email">
            Ok
        </button>
        <button mat-button class="btn-choose button-negative" (click)="onNoClick()">
            Cancel
        </button>
    </div>
</div>