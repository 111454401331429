import { switchMap, tap } from "rxjs/operators";
import { DataService } from "./../../services/data.service";
import { Observable, merge, of, Subject } from "rxjs";
import { Component, OnInit } from "@angular/core";
import { ConfirmationDialog } from "../popups/confirmation-popup.component";
import { MatDialog } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { ServiceService } from "src/app/services/page-services/service.service";

@Component({
  selector: "app-service",
  templateUrl: "./service.component.html",
  styleUrls: ["./service.component.scss"],
})
export class ServiceComponent implements OnInit {
  servicesObservable: Observable<any>;
  dataPassed: boolean = false;
  allServices = [];
  refresh = new Subject();
  serviceInterval;
  requestedService: boolean = false;
  user_id;

  constructor(
    public dialog: MatDialog,
    private dataService: DataService,
    private serviceService: ServiceService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.user_id = localStorage.getItem("user_id");
    this.servicesObservable = merge(of(null), this.refresh).pipe(
      switchMap(() => {
        return this.serviceService.getUserServices(
          localStorage.getItem("user_id")
        );
      }),
      tap(async (res) => {
        let data: any = await this.serviceService.getAllServices().toPromise();
        this.allServices = data;
        data.map((service, index) => {
          service.value = JSON.parse(service.value);
          if (res.length > 0) {
            res.map((existEntry) => {
              this.allServices[index]["isActivated"] =
                this.allServices[index]["isActivated"] ||
                (service.key == existEntry.service.key &&
                  existEntry.status == "activated")
                  ? true
                  : false;
              this.requestedService = this.requestedService
                ? this.requestedService
                : existEntry.status == "requested";
              if (service.key == existEntry.service.key) {
                this.allServices[index]["status"] = existEntry.status;
                this.allServices[index]["service_id"] = existEntry._id;
                this.allServices[index]["user"] = existEntry.user;
                this.allServices[index]["service_id"] = existEntry._id;
                this.allServices[index]["stripe_subs_id"] =
                  existEntry.stripe_subs_id;
                this.allServices[index]["stripe_subs_id"] =
                  existEntry.stripe_subs_id;
              }
            });
          }
        });

        [
          "../../../assets/images/png/service-solution.png",
          "../../../assets/images/png/service-integrated.png",
          "../../../assets/images/png/service-training.png",
        ].map((image, index) => {
          this.allServices[index]["image"] = image;
        });
        this.dataPassed = true;
      })
    );

    this.serviceInterval = setInterval(() => {
      if (this.requestedService) {
        this.requestedService = false;
        this.refresh.next();
      }
    }, 20000);
  }

  openRemoveDialog(service) {
    let dialog = this.dialog.open(ConfirmationDialog, {
      width: "30%",
      maxWidth: "400px",
    });

    dialog.componentInstance.text = "Do you want to cancel the service ?";
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.dataPassed = false;
        this.serviceService
          .softRemoveServices(service)
          .toPromise()
          .then((_) => this.refresh.next()),
          (error) => {
            console.error(error);
            this.refresh.next();
          };
      }
    });
  }
  openCreateDialog(service) {
    let dialog = this.dialog.open(ConfirmationDialog, {
      width: "30%",
      maxWidth: "400px",
    });

    dialog.componentInstance.text = "Do you want to enable the services ?";
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.serviceService
          .getUserServicesByService(
            localStorage.getItem("user_id"),
            service._id
          )
          .subscribe((data) => {
            if (data["length"] != 0) {
              this.serviceService
                .realRemoveService(service.service_id)
                .subscribe((_) => this.addServiceOperation(service._id));
            } else {
              this.addServiceOperation(service._id);
            }
          });
        // this.dataPassed = false;
      }
    });
  }
  retryService(service) {
    this.serviceService
      .addServices(service._id)
      .toPromise()
      .then((_) => this.refresh.next());
    this.serviceService.realRemoveService(service.service_id).toPromise();
  }

  addServiceOperation(service_id) {
    this.serviceService
      .addServices(service_id)
      .toPromise()
      .then((_) => this.refresh.next())
      .catch((error) => {
        if (error.error.message)
          this.toastr.error(undefined, error.error.message.message, {
            positionClass: "toast-bottom-center",
          });
        this.dataPassed = true;
      });
  }
}
