import { Component, OnInit } from "@angular/core";
import { Observable, merge, of, Subject } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { DataService } from "../../services";
import { MatDialog } from "@angular/material/dialog";
import { CreateLicenseDialog } from "../popups/create-license.component";
import { ConfirmationDialog } from '../popups/confirmation-popup.component';
import { LicenceService } from 'src/app/services/page-services/license.service';

@Component({
  selector: "app-licenses",
  templateUrl: "./licenses.component.html",
  styleUrls: ["./licenses.component.scss"]
})
export class LicensesComponent implements OnInit {
  packages = {
    starter: "Starter",
    professional: "Professional",
    business: "Business",
    enterprise: "Enterprise",
    on_premise: "On Premise"
  };
  dataPassed: boolean = false;
  licenses: Observable<any>;
  refresh = new Subject();
  constructor(private dataService: DataService,private licenceService:LicenceService, public dialog: MatDialog) {}

  ngOnInit() {
    this.licenses = merge(of(null), this.refresh).pipe(
      switchMap(() => {
        return this.licenceService.getUserLicenses(
          localStorage.getItem('user_id')
        );
      }),
      tap(() => {
        this.dataPassed = true;
      })
    );
  }

  openDialog(): void {
    this.dialog
      .open(CreateLicenseDialog, {
        width: "30%",
        maxWidth: "400px"
      })
      .afterClosed()
      .toPromise()
      .then(cancelled => {
        if (cancelled == false) {
          this.dataPassed = false;
          this.refresh.next();
        }
      });
  }

  removeLicense(license_id: string) {
    let dialog = this.dialog.open(ConfirmationDialog, {
      width: "30%",
      maxWidth: "400px"
    });

    dialog.componentInstance.text = "Are you sure to delete license? Once you delete the license, you can NOT revert it.";
    dialog.afterClosed()
    .toPromise()
    .then(data => {
      if(data){
        this.dataPassed = false;
        this.licenceService
          .removeLicense(license_id)
          .toPromise()
          .then(() => {
            this.refresh.next();
          });
      }
    })
  }
}
