<div class="bg">
  <div class="left">
    <img src="../../../assets/logo.png" class="logo" />
    <slider></slider>
  </div>

  <div class="right">
    <div class="content-wrapper">
      <div class="content">
        <div class="p-5" *ngIf="!forgotPanel">
          <h2>Launch It</h2>
          <p class="mt-1">
            Hello there! <br />
            It's nice to see you.
          </p>
          <form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)" class="form">
            <mat-form-field color="light" appearance="outline" floatLabel="never" class="form-element d-block">
              <mat-label>Email</mat-label>
              <input type="text" matInput formControlName="email" />
              <mat-icon matSuffix fontSet="material-icons-outlined">mail_outline</mat-icon>
              <mat-error *ngIf="formGroup.controls['email'].invalid">
                {{ getError("email") }}
              </mat-error>
            </mat-form-field>

            <mat-form-field floatLabel="never" appearance="outline" class="form-element d-block">
              <mat-label>Password</mat-label>
              <input type="password" matInput formControlName="password" />
              <mat-icon matSuffix fontSet="material-icons-outlined">vpn_key</mat-icon>
              <mat-error *ngIf="!formGroup.controls['password'].valid">
                {{ getError("pass") }}
              </mat-error>
            </mat-form-field>
            <span *ngIf="!loginProgress">
              <div class="password-recovery" (click)="forgotPanel = !forgotPanel">
                Forgot your password ?
              </div>
              <div class="form-element text-center">
                <button  mat-raised-button type="submit" class="button" color="primary"
                  [disabled]="!formGroup.valid">
                  Launch
                </button>

                <button  mat-flat-button [routerLink]="['/register']"
                  routerLinkActive="router-link-active" class="button ml-2">
                  Register
                </button>
              </div>
              <div class="social">
                <div class="__item" (click)="loginWithGoogle()">
                  <span class="login-social">Or sign in with</span>
                  <img  #loginRef src="../../../assets/images/svg/social/gooogle-icon.svg"
                    alt="google">
                </div>
                <!-- <div class="__item">
                  <a href="https://github.com/login/oauth/authorize?client_id=febaefef70feb3a97d92&scope=user">
                    <img src="../../../assets/images/svg/social/github-icon.svg" alt="github">
                  </a>
                </div> -->
              </div>
            </span>
          
           <div class="login-spinner"  *ngIf="loginProgress"> <loading-spinner mode="query"></loading-spinner></div>
          </form>

        </div>

        <div *ngIf="forgotPanel">
          <h2 class="pb1">Password Recovery</h2>
          <form [formGroup]="formGroup2" (ngSubmit)="recoverPassword(formGroup2.value)" class="form">
            <mat-form-field color="light" appearance="outline" floatLabel="never" class="form-element d-block">
              <mat-label>Email</mat-label>
              <input type="text" matInput formControlName="email" />
              <mat-error *ngIf="formGroup2.controls['email'].invalid">
                {{ getError("email") }}
              </mat-error>
            </mat-form-field>

            <div class="form-element text-center">
              <div class="password-recovery">
                <div class="form-element text-center">
                  <button mat-raised-button type="submit" class="button" [disabled]="!formGroup2.valid">
                    <mat-icon *ngIf="forgotProgress"><mat-spinner diameter="20">
                    </mat-spinner></mat-icon>  Recover Password
                  </button>
                </div>
                <div (click)="forgotPanel=!forgotPanel">
                  Go back
                </div>
              </div>

            </div>
            <!-- <loading-spinner *ngIf="loginProgress" mode="query"></loading-spinner> -->

          </form>
        </div>
      </div>
    </div>
  </div>
</div>