import { Component, OnInit } from "@angular/core";
import { DataService } from "../../services";
import { of, Subject } from "rxjs";
import { catchError } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { AddPaymentMethodDialog } from "../popups/add-payment-method.component";
import { ConfirmationDialog } from "../popups/confirmation-popup.component";
import { PaymentService } from "src/app/services/page-services/payment.service";

@Component({
  selector: "app-payment-method",
  templateUrl: "./payment-method.component.html",
  styleUrls: ["./payment-method.component.scss"],
})
export class PaymentMethodComponent implements OnInit {
  customer: string;
  dataPassed: boolean = false;
  paymentMethods;
  refresh = new Subject();
  me;
  constructor(
    private dataService: DataService,
    private dialog: MatDialog,
    private paymentService: PaymentService
  ) {}

  async ngOnInit() {
    this.me = await this.dataService.getUserWithIdentity().toPromise();
    this.customer = this.me.stripe_customer_id;
    await this.getPaymentMethods();
  }

  async getPaymentMethods() {
    this.paymentMethods = await this.paymentService
      .getPaymentMethods(this.customer)
      .pipe(
        catchError((err): any => {
          console.log(err);
          return of([]);
        })
      )
      .toPromise();
    this.dataPassed = true;
  }
  openDialog() {
    if (this.paymentMethods.length == 1) return;

    this.dialog
      .open(AddPaymentMethodDialog, {
        width: "30%",
        minWidth: "400px",
      })
      .afterClosed()
      .toPromise()
      .then((data) => {
        if (data) {
          this.dataPassed = false;
          //check denied project with new payment method
          return this.getPaymentMethods();
        }
      });
  }

  removePaymentMethod(methodId) {
    let dialog = this.dialog.open(ConfirmationDialog, {
      maxWidth: "400px",
    });

    dialog.componentInstance.text = "Are you sure to delete payment method?";
    dialog
      .afterClosed()
      .toPromise()
      .then((data) => {
        if (data) {
          this.dataPassed = false;
          this.paymentService
            .removePaymentMethod(this.customer, methodId)
            .toPromise()
            .then(() => this.getPaymentMethods());
        }
      });
  }
  setAsDefault(method) {
    this.dataPassed = false;
    this.paymentService
      .setDefaultPaymentMethod(this.customer, method)
      .toPromise()
      .then(() => this.getPaymentMethods());
  }
}
