import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from './../../../environments/environment';
@Injectable({
    providedIn: "root",
})
export class InvoiceService {
    apiBucketUrl = `${environment.url}bucket/`;
    apiFunctionsUrl = `${environment.url}fn-execute/`;

    constructor(private http: HttpClient) { }

    getTransactions(customer: string) {
        return this.http.post(this.apiFunctionsUrl + "getTransactions", {
            customer,
        });
    }
    createCustomerAndSubs(email: string, payment_method: string) {
        return this.http.post(this.apiFunctionsUrl + "createCustomerAndSubs", {
            email,
            payment_method,
        });
    }

}