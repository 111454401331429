<app-header-background onPage="privacy-policy"></app-header-background>

<div class="content-area">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="page-title">
            Terms & Conditions
        </div>
      </div>
    </div>
  </div>

  <div class="container mt-5">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <legal-titles page="terms" [subPage]="subPage"></legal-titles>
            </div>
            <div class="col-lg-8 col-md-12">
                <mat-card class="legal-card" *ngIf="subPage == ''">
                    <p>
                        CAREFULLY READ THE TERMS AND CONDITIONS OF THIS AGREEMENT BEFORE YOU CHECK THE BOX INDICATING THAT YOU HAVE READ AND AGREE TO OUR TERMS AND CONDITIONS. BY CHECKING THE BOX OR USING THE WEBSITE IN ANY MANNER, YOU ARE AGREEING TO BE BOUND BY AND ARE BECOMING A PARTY TO THIS AGREEMENT. WE RESERVE THE RIGHT TO CHANGE THE TERMS OF THIS AGREEMENT IN THE FUTURE AND ANY CHANGES WILL APPLY TO YOUR USE OF THE WEBSITE AFTER THE DATE OF SUCH CHANGE.
                    </p>
                    <p>
                        <b>1. ACCEPTANCE OF TERMS; LEGAL COMPLIANCE</b><br>
                        Teknodev Ltd, a limited liability company (hereinafter referred to as “Spica”, “we”, “our” or “us”), provides this website (the “Site”), including all information, documents, communications, files, images, text, graphics, software, software applications, and products available through the Site (collectively, the “Materials”) and all services offered or operated by Spica and third parties through the Site, including all content management services offered by Spica, (collectively, the “Services”), available for your use subject to the terms and conditions set forth in this document and any changes to this document that we may publish from time to time (collectively, the “Terms of Use” or “Agreement”).
                    </p>
                    <p>
                        <b>2. OPEN SOURCE ELEMENTS SUBJECT TO GNU (VERSION 3) LICENSE</b><br>
                        Except for Materials and Services requiring registration of an Account on the Site, software and services on the Site which do not require registration or an Account are provided for free as open source software, which shall be governed by GNU Affero General Public License (Version 3) to the extent that those terms conflict with the terms of this Agreement. The GNU License is accessible at: <a href="https://www.gnu.org/licenses/agpl-3.0.html" target="_blank">https://www.gnu.org/licenses/agpl-3.0.html</a>.
                        <br />
                        All open source materials are accessible at: <a href="https://github.com/spica-engine" target="_blank">https://github.com/spica-engine</a> 
                    </p>
                </mat-card>
                <mat-card class="legal-card" *ngIf="subPage == 'dashboard'">
                    <p>
                        CAREFULLY READ THE TERMS AND CONDITIONS OF THIS AGREEMENT BEFORE YOU CHECK THE BOX INDICATING THAT YOU HAVE READ AND AGREE TO OUR TERMS AND CONDITIONS. BY CHECKING THE BOX OR USING THE WEBSITE IN ANY MANNER, YOU ARE AGREEING TO BE BOUND BY AND ARE BECOMING A PARTY TO THIS AGREEMENT. WE RESERVE THE RIGHT TO CHANGE THE TERMS OF THIS AGREEMENT IN THE FUTURE AND ANY CHANGES WILL APPLY TO YOUR USE OF THE WEBSITE AFTER THE DATE OF SUCH CHANGE.
                    </p>
                    <p>
                        <b>1. ACCEPTANCE OF TERMS; LEGAL COMPLIANCE</b><br>
                        Teknodev Ltd, a limited liability company (hereinafter referred to as “Spica”, “we”, “our” or “us”), provides this website (the “Site”), including all information, documents, communications, files, images, text, graphics, software, software applications, and products available through the Site (collectively, the “Materials”) and all services offered or operated by Spica and third parties through the Site, including all content management services offered by Spica, (collectively, the “Services”), available for your use subject to the terms and conditions set forth in this document and any changes to this document that we may publish from time to time (collectively, the “Terms of Use” or “Agreement”).
                    </p>
                    <p>
                        <b>2. OPEN SOURCE ELEMENTS SUBJECT TO GNU (VERSION 3) LICENSE</b><br>
                        Except for Materials and Services requiring registration of an Account on the Site, software and services on the Site which do not require registration or an Account are provided for free as open source software, which shall be governed by GNU Affero General Public License (Version 3) to the extent that those terms conflict with the terms of this Agreement. The GNU License is accessible at: <a href="https://www.gnu.org/licenses/agpl-3.0.html" target="_blank">https://www.gnu.org/licenses/agpl-3.0.html</a>.
                    </p>
                    <p>
                        <b>3. MODIFICATION; AMENDMENTS</b><br>
                        We reserve the right to change the Terms of Use and other guidelines, fees or rules posted on the Site from time to time at our sole discretion. Your continued use of the Site, or any Materials or Services accessible through it, after such notice has been posted constitutes your acceptance of the changes. Your use of the Site will be subject to the most current version of the Terms of Use, rules and guidelines posted on the Site at the time of such use. You should periodically check these Terms of Use to view the then current terms. If you breach any of the Terms of Use, your authorization to use this Site automatically terminates.
                    </p>
                    <p>
                        <b>4. AGREEMENT TO COMPLY WITH APPLICABLE LAW</b><br>
                        In using the Spica Materials or Services, you agree to comply with all applicable domestic and international laws, statutes, ordinances and regulations regarding your use of the Site, the Materials or Services.
                    </p>
                    <p>
                        <b>5. ACCOUNT REGISTRATION</b><br>
                        As a condition to your use of the Materials and Services provided by Spica you will be required to register for a user account (an “Account”) on the Site, following the account registration directions provided on the Site. There is no fee for merely maintaining a Spica Account.
                    </p>
                    <p>
                        By using the Site and/or setting up an Account you represent that you are of legal age to form a binding contract and are not a person barred from receiving our Materials and Services under applicable laws. You also agree:
                    </p>
                    <p></p>
                    <ol type="i">
                        <li>that you will supply only complete and accurate information in registering for an Account;</li>
                        <li>that you are solely responsible for the activity that occurs on your Account, that you will keep your Account password secure at all times, and that we will not be liable for your losses caused by any unauthorized use of your Account; and</li>
                        <li>that if your Account has been compromised for any reason, you will immediately notify us of the security breach or unauthorized use of your Account.</li>
                    </ol>
                    <p></p>
                    <p>
                        We reserve the right to (a) take appropriate legal action against anyone who, in our sole discretion, violates these Terms of Use; (b) in our sole discretion without notice and without limitation or liability, refuse, restrict access to or availability of, or terminate any user’s Account for any violation of this Agreement or your violation of any of our rules or policies. A termination of your Account will also terminate all of your database Instances, as defined below.
                    </p>
                    <p>
                        <b>6. DATABASE INSTANCES; SERVICE PLANS</b><br>
                        After you have registered for a user Account, you will be able to purchase service plans for a single or multiple database instances (each database instance, an “Instance” and collectively, “Instances”). You may subscribe for a single Instance or for multiple Instances, and each Instance is treated separately for subscription and termination purposes. For each Instance you will be required to select your service plan and make applicable payments, as directed on the Site. Spica offers several service plans for each Instance, with varying monthly subscription fees, as described in more detail on the Site.
                    </p>
                    <p>
                        When you subscribe for an Instance or Instances, you will be required to provide us with billing and account information for credit card, debit or other payment systems (each a “Payment Source”) for which you are authorized to approve charges. You authorize us to automatically and immediately bill the Payment Source when payments for subscription plans are due. The billing of all subscription service plans is made on a pre-paid basis. Each Instance subscribed for will have its own billing cycle and will be invoiced separately. Unless expressly provided otherwise on the Site, all payments and amounts are in United States Dollars.
                    </p>
                    <p>
                        We may amend and make changes to our service plans and to corresponding subscription fees at any time upon notice to you, and such changes shall take effects at the commencement of the next billing cycle for the subscription of each Instance unless you such Instance(s) prior to the next corresponding billing cycle. If applicable, we will charge you sales tax in addition to our subscription fee for each subscribed Instance, as required by law.
                    </p>
                    <p>
                        By selecting a particular plan, you represent to Spica that your proposed use for such Instance falls within the parameters described under your selected service plan. For example, if a selected plan is limited to 3 users, you agree that only 3 users will make use of the subscribed Instance, and you agree to upgrade your plan if additional users are added.
                    </p>
                    <p>
                        <b>7. FREE TRIALS</b><br>
                        For some of our service plans we may offer users a free trial period. Such free trials are provided at our sole discretion and we may discontinue such offering at any time without notice to you. To utilize a free trial, you will be required to register for an Account, in the same manner as is required for all other subscriptions. You will also be required to provide payment information. Unless provided otherwise on the Site, the trial period is 14 days. If you fail to terminate the subscription for the particular Instance prior to the expiration of the 14-day period we will, and you authorize us to, automatically charge your Payment Source the monthly subscription fee for the selected service plan/Instance(s). Unless you terminate the subscription, the subscription for the Instance(s) will automatically renew on a monthly basis (pursuant to Section 6 below) and you will be charged automatically upon each renewal.
                    </p>
                    <p>
                        <b>8. AUTOMATIC RENEWALS</b><br>
                        Spica offers monthly subscriptions and may also offer annual subscriptions. Unless terminated pursuant to this Agreement, the subscription term for each Instance will renew automatically at the end of the paid-for term for the same period as the original subscription term for the particular Instance. For example, if you subscribe for one Instance on a monthly subscription, then, unless terminated, the subscription for that Instance will renew automatically for additional month-long periods at the end of each successive month.
                    </p>
                    <p>
                        <b>9. CANCELLATION OF INSTANCES; TERMINATION OF ACCOUNT</b><br>
                        You may, at any time, terminate any of Instances for which you subscribed by going to the “Projects” and selecting "Delete project”, thereby discontinuing any future automatic renewal for that particular Instance. Because each Instance is a stand-alone subscription, you may terminate some or all of your Instance subscriptions in this manner. Refunds will be made for unused portions of subscription periods.
                    </p>
                    <p>
                        <b>10. INTELLECTUAL PROPERTY; LIMITED LICENSE</b><br>
                        The Materials and Services on this Site, as well as their selection and arrangement, are protected by copyright, trademark, patent, and/or other intellectual property laws, and any unauthorized use of the Materials or Services may violate such laws and these Terms of Use. Except as expressly provided herein, we do not grant any express or implied rights to use the Materials and Services. You agree not to copy, republish, frame, download, transmit, modify, assign, share, distribute, or create derivative works based on the Site, its Materials, or its Services or their selection and arrangement, except as expressly authorized herein. In addition, you agree not to use any data mining, robots, or similar data gathering and extraction methods in connection with the Site.
                    </p>
                    <p>
                        In addition to the Materials and Services offered by us, this Site, or our sponsors/affiliates, may make available materials, links, information, products and/or services provided by third parties (collectively, the “Third Party Products and Services”). The Third Party Products and Services are governed by separate agreements that accompany such products or services. We offer no guarantees and assume no responsibility or liability of any type with respect to the Third Party Products and Services. You agree that you will not hold us responsible or liable with respect to the Third Party Products and Services or seek to do so, even if we promote and/or recommend particular goods or services.
                    </p>
                    <p>
                        We grant you a limited non-exclusive, non-transferable license to access and make personal use of the Site, Materials and Services and not to download or modify it, as part or as a whole. This license does not grant you the right to resell or otherwise commercialize the Site, Materials or Services. We may terminate your license at any time and for any reason upon notice to you.
                    </p>
                    <p>
                        <b>11. TRADEMARK INFORMATION</b><br>
                        The trademarks, logos, and service marks, including the “Spica” trademark (the “Marks”) displayed on this Site are the property of Spica or other third parties. You are not permitted to use the Marks without the prior written consent of Spica or such third party that may own the Mark.
                    </p>
                    <p>
                        <b>12. PROHIBITED USE OF THE WEBSITE</b><br>
                        You may not access or use the Site, Materials or Services for any other purpose other than that for which we make it available. In using the Site, including all Services and Materials available through it, you agree to not engage in any prohibited activities. A prohibited activity includes, but is not limited to:<br>
                    </p>
                    <p>
                        </p><ol type="a">
                            <li>criminal or tortious activity, including fraud, harassment, stalking, spamming, or copyright or trademark infringement; and</li>
                            <li>using the Site, Materials or Services in a manner inconsistent with any and all applicable laws and regulations.</li>
                        </ol>
                    <p></p>
                    <p>
                        <b>13. PROHIBITED CONTENT</b><br>
                        You understand that you are entirely responsible for your data and content (collectively “content”) and any harm resulting from your content. When you upload any content you thereby represent and warrant that:
                    </p>
                    <p>
                        </p><ol type="a">
                            <li>the creation, distribution, transmission, public display and performance, accessing, downloading and copying of your content does not and will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark or trade secret rights of any third party;</li>
                            <li>you have fully complied with any third-party licenses relating to your content, and have done all things necessary to successfully pass through to viewers any required terms;</li>
                            <li>your content does not contain any viruses, malicious code or other harmful or destructive content;</li>
                            <li>your content is not obscene, lewd, lascivious, filthy, excessively violent, harassing or otherwise objectionable, libelous or slanderous, does not incite, encourage or threaten immediate physical harm against another, does not violate any applicable law, regulation, or rule, and does not violate the privacy or publicity rights of any third party;</li>
                            <li>your content does not contain material that solicits personal information from anyone under 18 or exploits people under the age of 18 in a sexual or violent manner, and does not violate any applicable child pornography law or otherwise intended to protect the health or wellbeing of minors;</li>
                            <li>your content does not violate any applicable law designed to regulate electronic advertising;</li>
                            <li>your content does not constitute, contain, install or attempt to install or promote spyware, malware or other computer code designed to enable you or others to gather information about or monitor the online or other activities of another party on or through the Site;</li>
                            <li>your content does not contain any defamatory material; and</li>
                            <li>your content does not otherwise violate, or link to material that violates, any provision of these Terms of Use or any applicable law or regulation.</li>
                        </ol>
                    <p></p>
                    <p>
                        <b>14. PERSONAL INFORMATION AND PRIVACY</b><br>
                        You understand and agree that we may disclose information about you if we have a good faith belief that we are required to do so by law or legal process, to respond to claims, or to protect the rights, property, or safety of Spica or others. Please refer to our Privacy Policy for more information about the manner in which we protect and use your information.
                    </p>
                    <p>
                        <b>15. WARRANTIES AND DISCLAIMERS</b><br>
                        EXCEPT AS EXPRESSLY PROVIDED OTHERWISE IN A WRITTEN AGREEMENT BETWEEN YOU AND US, THIS SITE, AND ALL MATERIALS AND SERVICES ACCESSIBLE THROUGH THIS SITE, ARE PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, OR THE WARRANTY OF NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, WE MAKE NO WARRANTY THAT (I) THE SERVICES OR MATERIALS WILL MEET YOUR REQUIREMENTS; (II) THE SERVICES AND MATERIALS WILL BE UNINTERRUPTED, TIMELY, OR ERROR-FREE; (III) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICES OR MATERIALS WILL BE EFFECTIVE, ACCURATE, OR RELIABLE; (IV) THE QUALITY OF ANY SERVICES OR MATERIALS OBTAINED OR ACCESSIBLE BY YOU THROUGH THE SITE WILL MEET YOUR EXPECTATIONS; AND (V) ANY ERRORS IN THE SERVICES OR MATERIALS OBTAINED THROUGH THE SITE, OR ANY DEFECTS IN THE SITE, ITS SERVICES OR MATERIALS, WILL BE CORRECTED.
                    </p>
                    <p>
                        The use of the Services or the downloading or other use of any Materials through the Site is done at your own discretion and risk and with your agreement that you will be solely responsible for any damage to your computer system, loss of data, or other harm that results from such activities. We assume no liability for any computer virus or other similar software code that is downloaded to your computer from the Site or in connection with any Services or Materials offered through the Site.
                    </p>
                    <p>
                        <b>16. LIMITATION OF LIABILITY</b><br>
                        IN NO EVENT SHALL Spica OR ITS OFFICERS, OWNERS, EMPLOYEES, SUPPLIERS OR LICENSORS BE LIABLE TO YOU OR ANY THIRD PARTY UNDER ANY CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE OR UNDER LEGAL OR EQUITABLE THEORY FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT DAMAGES ARISING FROM YOUR USE OF THE SITE AND ANY OF THE MATERIALS OR SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. <b>NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR TOTAL LIABILITY FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT YOU ACTUALLY PAID TO US IN SUBSCRIPTION FEES DURING THE 12 MONTHS PRIOR TO THE DATE ON WHICH THE CAUSE OF ACTION AROSE.</b>
                    </p>
                    <p>
                        <b>17. INDEMNIFICATION</b><br>
                        You agree to indemnify and hold Spica, and its subsidiaries, affiliates, officers, directors, agents, owners, partners and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third party due to or arising out of your content, your use of the Site (or the Materials or Services), or your violation of these Terms of Use, your violation of any rights of another person or entity, or your violation of any law.
                    </p>
                    <p>
                        <b>18. GOVERNING LAW, JURISDICTION AND ARBITRATION</b><br>
                        This Agreement is governed by and shall be construed in accordance with the laws of Turkey. You accept and consent to the exclusive personal jurisdiction and venue of the courts in Antalya, Turkey. <b>You and Spica agree that any cause of action arising out of or related to the Site and any of our Services or Materials or related to this Agreement must commence within one (1) year after the cause of action arose; otherwise, such cause of action is permanently barred. Any controversy or claim shall be litigated on an individual basis, and shall not be consolidated in any arbitration with any claim or controversy of any other party.</b> Notwithstanding anything else to the contrary in this Agreement, you shall only be allowed to raise an objection to a bill/charge within ninety days starting from the day of the charge. If the objection is not raised within that time, the charge is deemed accepted by you and you are thereafter permanently barred from initiating a cause of action based on such charge(s).
                    </p>
                    <p>
                        <b>19. GENERAL</b><br>
                        The Terms of Use and the other rules, guidelines, licenses and disclaimers posted on the Site constitute the entire agreement between Spica and you with respect to your use of the Site. If for any reason a court of competent jurisdiction finds any provision of these Terms of Use, or portion thereof, to be unenforceable, that provision shall be enforced to the maximum extent permissible so as to effect the intent of the parties as reflected by that provision, and the remainder of the Terms of Use shall continue in full force and effect. Any failure by us to enforce or exercise any provision of these Terms of Use or related right shall not constitute a waiver of that right or provision.
                    </p>
                </mat-card>
                <mat-card class="legal-card" *ngIf="subPage == 'assetstore'">
                    <p>
                        CAREFULLY READ THE TERMS AND CONDITIONS OF THIS AGREEMENT BEFORE YOU CHECK THE BOX INDICATING THAT YOU HAVE READ AND AGREE TO OUR TERMS AND CONDITIONS. BY CHECKING THE BOX OR USING THE WEBSITE IN ANY MANNER, YOU ARE AGREEING TO BE BOUND BY AND ARE BECOMING A PARTY TO THIS AGREEMENT. WE RESERVE THE RIGHT TO CHANGE THE TERMS OF THIS AGREEMENT IN THE FUTURE AND ANY CHANGES WILL APPLY TO YOUR USE OF THE WEBSITE AFTER THE DATE OF SUCH CHANGE.
                    </p>
                    <p>
                        <b>1. ACCEPTANCE OF TERMS; LEGAL COMPLIANCE</b><br>
                        Teknodev Ltd, a limited liability company (hereinafter referred to as “Spica”, “we”, “our” or “us”), provides this website (the “Site”), including all information, documents, communications, files, images, text, graphics, software, software applications, and products available through the Site (collectively, the “Materials”) and all services offered or operated by Spica and third parties through the Site, including all content management services offered by Spica, (collectively, the “Services”), available for your use subject to the terms and conditions set forth in this document and any changes to this document that we may publish from time to time (collectively, the “Terms of Use” or “Agreement”).
                    </p>
                    <p>
                        <b>2. OPEN SOURCE ELEMENTS SUBJECT TO GNU (VERSION 3) LICENSE</b><br>
                        Except for Materials and Services requiring registration of an Account on the Site, software and services on the Site which do not require registration or an Account are provided for free as open source software, which shall be governed by GNU Affero General Public License (Version 3) to the extent that those terms conflict with the terms of this Agreement. The GNU License is accessible at: <a href="https://www.gnu.org/licenses/agpl-3.0.html" target="_blank">https://www.gnu.org/licenses/agpl-3.0.html</a>.
                    </p>
                    <p>
                        <b>3. MODIFICATION; AMENDMENTS</b><br>
                        We reserve the right to change the Terms of Use and other guidelines, fees or rules posted on the Site from time to time at our sole discretion. Your continued use of the Site, or any Materials or Services accessible through it, after such notice has been posted constitutes your acceptance of the changes. Your use of the Site will be subject to the most current version of the Terms of Use, rules and guidelines posted on the Site at the time of such use. You should periodically check these Terms of Use to view the then current terms. If you breach any of the Terms of Use, your authorization to use this Site automatically terminates.
                    </p>
                    <p>
                        <b>4. AGREEMENT TO COMPLY WITH APPLICABLE LAW</b><br>
                        In using the Spica Materials or Services, you agree to comply with all applicable domestic and international laws, statutes, ordinances and regulations regarding your use of the Site, the Materials or Services.
                    </p>
                    <p>
                        <b>5. ACCOUNT REGISTRATION</b><br>
                        As a condition to your use of the Materials and Services provided by Spica you will be required to register for a user account (an “Account”) on the Site, following the account registration directions provided on the Site. There is no fee for merely maintaining a Spica Account.
                    </p>
                    <p>
                        By using the Site and/or setting up an Account you represent that you are of legal age to form a binding contract and are not a person barred from receiving our Materials and Services under applicable laws. You also agree:
                    </p>
                    <p></p>
                    <ol type="i">
                        <li>that you will supply only complete and accurate information in registering for an Account;</li>
                        <li>that you are solely responsible for the activity that occurs on your Account, that you will keep your Account password secure at all times, and that we will not be liable for your losses caused by any unauthorized use of your Account; and</li>
                        <li>that if your Account has been compromised for any reason, you will immediately notify us of the security breach or unauthorized use of your Account.</li>
                    </ol>
                    <p></p>
                    <p>
                        We reserve the right to (a) take appropriate legal action against anyone who, in our sole discretion, violates these Terms of Use; (b) in our sole discretion without notice and without limitation or liability, refuse, restrict access to or availability of, or terminate any user’s Account for any violation of this Agreement or your violation of any of our rules or policies. A termination of your Account will also terminate all of your database Instances, as defined below.
                    </p>
                    <p>
                        <b>6. DATABASE INSTANCES; SERVICE PLANS</b><br>
                        After you have registered for a user Account, you will be able to purchase service plans for a single or multiple database instances (each database instance, an “Instance” and collectively, “Instances”). You may subscribe for a single Instance or for multiple Instances, and each Instance is treated separately for subscription and termination purposes. For each Instance you will be required to select your service plan and make applicable payments, as directed on the Site. Spica offers several service plans for each Instance, with varying monthly subscription fees, as described in more detail on the Site.
                    </p>
                    <p>
                        When you subscribe for an Instance or Instances, you will be required to provide us with billing and account information for credit card, debit or other payment systems (each a “Payment Source”) for which you are authorized to approve charges. You authorize us to automatically and immediately bill the Payment Source when payments for subscription plans are due. The billing of all subscription service plans is made on a pre-paid basis. Each Instance subscribed for will have its own billing cycle and will be invoiced separately. Unless expressly provided otherwise on the Site, all payments and amounts are in United States Dollars.
                    </p>
                    <p>
                        We may amend and make changes to our service plans and to corresponding subscription fees at any time upon notice to you, and such changes shall take effects at the commencement of the next billing cycle for the subscription of each Instance unless you such Instance(s) prior to the next corresponding billing cycle. If applicable, we will charge you sales tax in addition to our subscription fee for each subscribed Instance, as required by law.
                    </p>
                    <p>
                        By selecting a particular plan, you represent to Spica that your proposed use for such Instance falls within the parameters described under your selected service plan. For example, if a selected plan is limited to 3 users, you agree that only 3 users will make use of the subscribed Instance, and you agree to upgrade your plan if additional users are added.
                    </p>
                    <p>
                        <b>7. FREE TRIALS</b><br>
                        For some of our service plans we may offer users a free trial period. Such free trials are provided at our sole discretion and we may discontinue such offering at any time without notice to you. To utilize a free trial, you will be required to register for an Account, in the same manner as is required for all other subscriptions. You will also be required to provide payment information. Unless provided otherwise on the Site, the trial period is 14 days. If you fail to terminate the subscription for the particular Instance prior to the expiration of the 14-day period we will, and you authorize us to, automatically charge your Payment Source the monthly subscription fee for the selected service plan/Instance(s). Unless you terminate the subscription, the subscription for the Instance(s) will automatically renew on a monthly basis (pursuant to Section 6 below) and you will be charged automatically upon each renewal.
                    </p>
                    <p>
                        <b>8. AUTOMATIC RENEWALS</b><br>
                        Spica offers monthly subscriptions and may also offer annual subscriptions. Unless terminated pursuant to this Agreement, the subscription term for each Instance will renew automatically at the end of the paid-for term for the same period as the original subscription term for the particular Instance. For example, if you subscribe for one Instance on a monthly subscription, then, unless terminated, the subscription for that Instance will renew automatically for additional month-long periods at the end of each successive month.
                    </p>
                    <p>
                        <b>9. CANCELLATION OF INSTANCES; TERMINATION OF ACCOUNT</b><br>
                        You may, at any time, terminate any of Instances for which you subscribed by going to the “Projects” and selecting "Delete project”, thereby discontinuing any future automatic renewal for that particular Instance. Because each Instance is a stand-alone subscription, you may terminate some or all of your Instance subscriptions in this manner. Refunds will be made for unused portions of subscription periods.
                    </p>
                    <p>
                        <b>10. INTELLECTUAL PROPERTY; LIMITED LICENSE</b><br>
                        The Materials and Services on this Site, as well as their selection and arrangement, are protected by copyright, trademark, patent, and/or other intellectual property laws, and any unauthorized use of the Materials or Services may violate such laws and these Terms of Use. Except as expressly provided herein, we do not grant any express or implied rights to use the Materials and Services. You agree not to copy, republish, frame, download, transmit, modify, assign, share, distribute, or create derivative works based on the Site, its Materials, or its Services or their selection and arrangement, except as expressly authorized herein. In addition, you agree not to use any data mining, robots, or similar data gathering and extraction methods in connection with the Site.
                    </p>
                    <p>
                        In addition to the Materials and Services offered by us, this Site, or our sponsors/affiliates, may make available materials, links, information, products and/or services provided by third parties (collectively, the “Third Party Products and Services”). The Third Party Products and Services are governed by separate agreements that accompany such products or services. We offer no guarantees and assume no responsibility or liability of any type with respect to the Third Party Products and Services. You agree that you will not hold us responsible or liable with respect to the Third Party Products and Services or seek to do so, even if we promote and/or recommend particular goods or services.
                    </p>
                    <p>
                        We grant you a limited non-exclusive, non-transferable license to access and make personal use of the Site, Materials and Services and not to download or modify it, as part or as a whole. This license does not grant you the right to resell or otherwise commercialize the Site, Materials or Services. We may terminate your license at any time and for any reason upon notice to you.
                    </p>
                    <p>
                        <b>11. TRADEMARK INFORMATION</b><br>
                        The trademarks, logos, and service marks, including the “Spica” trademark (the “Marks”) displayed on this Site are the property of Spica or other third parties. You are not permitted to use the Marks without the prior written consent of Spica or such third party that may own the Mark.
                    </p>
                    <p>
                        <b>12. PROHIBITED USE OF THE WEBSITE</b><br>
                        You may not access or use the Site, Materials or Services for any other purpose other than that for which we make it available. In using the Site, including all Services and Materials available through it, you agree to not engage in any prohibited activities. A prohibited activity includes, but is not limited to:<br>
                    </p>
                    <p>
                        </p><ol type="a">
                            <li>criminal or tortious activity, including fraud, harassment, stalking, spamming, or copyright or trademark infringement; and</li>
                            <li>using the Site, Materials or Services in a manner inconsistent with any and all applicable laws and regulations.</li>
                        </ol>
                    <p></p>
                    <p>
                        <b>13. PROHIBITED CONTENT</b><br>
                        You understand that you are entirely responsible for your data and content (collectively “content”) and any harm resulting from your content. When you upload any content you thereby represent and warrant that:
                    </p>
                    <p>
                        </p><ol type="a">
                            <li>the creation, distribution, transmission, public display and performance, accessing, downloading and copying of your content does not and will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark or trade secret rights of any third party;</li>
                            <li>you have fully complied with any third-party licenses relating to your content, and have done all things necessary to successfully pass through to viewers any required terms;</li>
                            <li>your content does not contain any viruses, malicious code or other harmful or destructive content;</li>
                            <li>your content is not obscene, lewd, lascivious, filthy, excessively violent, harassing or otherwise objectionable, libelous or slanderous, does not incite, encourage or threaten immediate physical harm against another, does not violate any applicable law, regulation, or rule, and does not violate the privacy or publicity rights of any third party;</li>
                            <li>your content does not contain material that solicits personal information from anyone under 18 or exploits people under the age of 18 in a sexual or violent manner, and does not violate any applicable child pornography law or otherwise intended to protect the health or wellbeing of minors;</li>
                            <li>your content does not violate any applicable law designed to regulate electronic advertising;</li>
                            <li>your content does not constitute, contain, install or attempt to install or promote spyware, malware or other computer code designed to enable you or others to gather information about or monitor the online or other activities of another party on or through the Site;</li>
                            <li>your content does not contain any defamatory material; and</li>
                            <li>your content does not otherwise violate, or link to material that violates, any provision of these Terms of Use or any applicable law or regulation.</li>
                        </ol>
                    <p></p>
                    <p>
                        <b>14. PERSONAL INFORMATION AND PRIVACY</b><br>
                        You understand and agree that we may disclose information about you if we have a good faith belief that we are required to do so by law or legal process, to respond to claims, or to protect the rights, property, or safety of Spica or others. Please refer to our Privacy Policy for more information about the manner in which we protect and use your information.
                    </p>
                    <p>
                        <b>15. WARRANTIES AND DISCLAIMERS</b><br>
                        EXCEPT AS EXPRESSLY PROVIDED OTHERWISE IN A WRITTEN AGREEMENT BETWEEN YOU AND US, THIS SITE, AND ALL MATERIALS AND SERVICES ACCESSIBLE THROUGH THIS SITE, ARE PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, OR THE WARRANTY OF NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, WE MAKE NO WARRANTY THAT (I) THE SERVICES OR MATERIALS WILL MEET YOUR REQUIREMENTS; (II) THE SERVICES AND MATERIALS WILL BE UNINTERRUPTED, TIMELY, OR ERROR-FREE; (III) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICES OR MATERIALS WILL BE EFFECTIVE, ACCURATE, OR RELIABLE; (IV) THE QUALITY OF ANY SERVICES OR MATERIALS OBTAINED OR ACCESSIBLE BY YOU THROUGH THE SITE WILL MEET YOUR EXPECTATIONS; AND (V) ANY ERRORS IN THE SERVICES OR MATERIALS OBTAINED THROUGH THE SITE, OR ANY DEFECTS IN THE SITE, ITS SERVICES OR MATERIALS, WILL BE CORRECTED.
                    </p>
                    <p>
                        The use of the Services or the downloading or other use of any Materials through the Site is done at your own discretion and risk and with your agreement that you will be solely responsible for any damage to your computer system, loss of data, or other harm that results from such activities. We assume no liability for any computer virus or other similar software code that is downloaded to your computer from the Site or in connection with any Services or Materials offered through the Site.
                    </p>
                    <p>
                        <b>16. LIMITATION OF LIABILITY</b><br>
                        IN NO EVENT SHALL Spica OR ITS OFFICERS, OWNERS, EMPLOYEES, SUPPLIERS OR LICENSORS BE LIABLE TO YOU OR ANY THIRD PARTY UNDER ANY CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE OR UNDER LEGAL OR EQUITABLE THEORY FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT DAMAGES ARISING FROM YOUR USE OF THE SITE AND ANY OF THE MATERIALS OR SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. <b>NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR TOTAL LIABILITY FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT YOU ACTUALLY PAID TO US IN SUBSCRIPTION FEES DURING THE 12 MONTHS PRIOR TO THE DATE ON WHICH THE CAUSE OF ACTION AROSE.</b>
                    </p>
                    <p>
                        <b>17. INDEMNIFICATION</b><br>
                        You agree to indemnify and hold Spica, and its subsidiaries, affiliates, officers, directors, agents, owners, partners and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third party due to or arising out of your content, your use of the Site (or the Materials or Services), or your violation of these Terms of Use, your violation of any rights of another person or entity, or your violation of any law.
                    </p>
                    <p>
                        <b>18. GOVERNING LAW, JURISDICTION AND ARBITRATION</b><br>
                        This Agreement is governed by and shall be construed in accordance with the laws of Turkey. You accept and consent to the exclusive personal jurisdiction and venue of the courts in Antalya, Turkey. <b>You and Spica agree that any cause of action arising out of or related to the Site and any of our Services or Materials or related to this Agreement must commence within one (1) year after the cause of action arose; otherwise, such cause of action is permanently barred. Any controversy or claim shall be litigated on an individual basis, and shall not be consolidated in any arbitration with any claim or controversy of any other party.</b> Notwithstanding anything else to the contrary in this Agreement, you shall only be allowed to raise an objection to a bill/charge within ninety days starting from the day of the charge. If the objection is not raised within that time, the charge is deemed accepted by you and you are thereafter permanently barred from initiating a cause of action based on such charge(s).
                    </p>
                    <p>
                        <b>19. GENERAL</b><br>
                        The Terms of Use and the other rules, guidelines, licenses and disclaimers posted on the Site constitute the entire agreement between Spica and you with respect to your use of the Site. If for any reason a court of competent jurisdiction finds any provision of these Terms of Use, or portion thereof, to be unenforceable, that provision shall be enforced to the maximum extent permissible so as to effect the intent of the parties as reflected by that provision, and the remainder of the Terms of Use shall continue in full force and effect. Any failure by us to enforce or exercise any provision of these Terms of Use or related right shall not constitute a waiver of that right or provision.
                    </p>
                </mat-card>
                <mat-card class="legal-card" *ngIf="subPage == 'chronos'">
                    <p>
                        CAREFULLY READ THE TERMS AND CONDITIONS OF THIS AGREEMENT BEFORE YOU CHECK THE BOX INDICATING THAT YOU HAVE READ AND AGREE TO OUR TERMS AND CONDITIONS. BY CHECKING THE BOX OR USING THE WEBSITE IN ANY MANNER, YOU ARE AGREEING TO BE BOUND BY AND ARE BECOMING A PARTY TO THIS AGREEMENT. WE RESERVE THE RIGHT TO CHANGE THE TERMS OF THIS AGREEMENT IN THE FUTURE AND ANY CHANGES WILL APPLY TO YOUR USE OF THE WEBSITE AFTER THE DATE OF SUCH CHANGE.
                    </p>
                    <p>
                        <b>1. ACCEPTANCE OF TERMS; LEGAL COMPLIANCE</b><br>
                        Teknodev Ltd, a limited liability company (hereinafter referred to as “Spica”, “we”, “our” or “us”), provides this website (the “Site”), including all information, documents, communications, files, images, text, graphics, software, software applications, and products available through the Site (collectively, the “Materials”) and all services offered or operated by Spica and third parties through the Site, including all content management services offered by Spica, (collectively, the “Services”), available for your use subject to the terms and conditions set forth in this document and any changes to this document that we may publish from time to time (collectively, the “Terms of Use” or “Agreement”).
                    </p>
                    <p>
                        <b>2. OPEN SOURCE ELEMENTS SUBJECT TO GNU (VERSION 3) LICENSE</b><br>
                        Except for Materials and Services requiring registration of an Account on the Site, software and services on the Site which do not require registration or an Account are provided for free as open source software, which shall be governed by GNU Affero General Public License (Version 3) to the extent that those terms conflict with the terms of this Agreement. The GNU License is accessible at: <a href="https://www.gnu.org/licenses/agpl-3.0.html" target="_blank">https://www.gnu.org/licenses/agpl-3.0.html</a>.
                    </p>
                    <p>
                        <b>3. MODIFICATION; AMENDMENTS</b><br>
                        We reserve the right to change the Terms of Use and other guidelines, fees or rules posted on the Site from time to time at our sole discretion. Your continued use of the Site, or any Materials or Services accessible through it, after such notice has been posted constitutes your acceptance of the changes. Your use of the Site will be subject to the most current version of the Terms of Use, rules and guidelines posted on the Site at the time of such use. You should periodically check these Terms of Use to view the then current terms. If you breach any of the Terms of Use, your authorization to use this Site automatically terminates.
                    </p>
                    <p>
                        <b>4. AGREEMENT TO COMPLY WITH APPLICABLE LAW</b><br>
                        In using the Spica Materials or Services, you agree to comply with all applicable domestic and international laws, statutes, ordinances and regulations regarding your use of the Site, the Materials or Services.
                    </p>
                    <p>
                        <b>5. ACCOUNT REGISTRATION</b><br>
                        As a condition to your use of the Materials and Services provided by Spica you will be required to register for a user account (an “Account”) on the Site, following the account registration directions provided on the Site. There is no fee for merely maintaining a Spica Account.
                    </p>
                    <p>
                        By using the Site and/or setting up an Account you represent that you are of legal age to form a binding contract and are not a person barred from receiving our Materials and Services under applicable laws. You also agree:
                    </p>
                    <p></p>
                    <ol type="i">
                        <li>that you will supply only complete and accurate information in registering for an Account;</li>
                        <li>that you are solely responsible for the activity that occurs on your Account, that you will keep your Account password secure at all times, and that we will not be liable for your losses caused by any unauthorized use of your Account; and</li>
                        <li>that if your Account has been compromised for any reason, you will immediately notify us of the security breach or unauthorized use of your Account.</li>
                    </ol>
                    <p></p>
                    <p>
                        We reserve the right to (a) take appropriate legal action against anyone who, in our sole discretion, violates these Terms of Use; (b) in our sole discretion without notice and without limitation or liability, refuse, restrict access to or availability of, or terminate any user’s Account for any violation of this Agreement or your violation of any of our rules or policies. A termination of your Account will also terminate all of your database Instances, as defined below.
                    </p>
                    <p>
                        <b>6. DATABASE INSTANCES; SERVICE PLANS</b><br>
                        After you have registered for a user Account, you will be able to purchase service plans for a single or multiple database instances (each database instance, an “Instance” and collectively, “Instances”). You may subscribe for a single Instance or for multiple Instances, and each Instance is treated separately for subscription and termination purposes. For each Instance you will be required to select your service plan and make applicable payments, as directed on the Site. Spica offers several service plans for each Instance, with varying monthly subscription fees, as described in more detail on the Site.
                    </p>
                    <p>
                        When you subscribe for an Instance or Instances, you will be required to provide us with billing and account information for credit card, debit or other payment systems (each a “Payment Source”) for which you are authorized to approve charges. You authorize us to automatically and immediately bill the Payment Source when payments for subscription plans are due. The billing of all subscription service plans is made on a pre-paid basis. Each Instance subscribed for will have its own billing cycle and will be invoiced separately. Unless expressly provided otherwise on the Site, all payments and amounts are in United States Dollars.
                    </p>
                    <p>
                        We may amend and make changes to our service plans and to corresponding subscription fees at any time upon notice to you, and such changes shall take effects at the commencement of the next billing cycle for the subscription of each Instance unless you such Instance(s) prior to the next corresponding billing cycle. If applicable, we will charge you sales tax in addition to our subscription fee for each subscribed Instance, as required by law.
                    </p>
                    <p>
                        By selecting a particular plan, you represent to Spica that your proposed use for such Instance falls within the parameters described under your selected service plan. For example, if a selected plan is limited to 3 users, you agree that only 3 users will make use of the subscribed Instance, and you agree to upgrade your plan if additional users are added.
                    </p>
                    <p>
                        <b>7. FREE TRIALS</b><br>
                        For some of our service plans we may offer users a free trial period. Such free trials are provided at our sole discretion and we may discontinue such offering at any time without notice to you. To utilize a free trial, you will be required to register for an Account, in the same manner as is required for all other subscriptions. You will also be required to provide payment information. Unless provided otherwise on the Site, the trial period is 14 days. If you fail to terminate the subscription for the particular Instance prior to the expiration of the 14-day period we will, and you authorize us to, automatically charge your Payment Source the monthly subscription fee for the selected service plan/Instance(s). Unless you terminate the subscription, the subscription for the Instance(s) will automatically renew on a monthly basis (pursuant to Section 6 below) and you will be charged automatically upon each renewal.
                    </p>
                    <p>
                        <b>8. AUTOMATIC RENEWALS</b><br>
                        Spica offers monthly subscriptions and may also offer annual subscriptions. Unless terminated pursuant to this Agreement, the subscription term for each Instance will renew automatically at the end of the paid-for term for the same period as the original subscription term for the particular Instance. For example, if you subscribe for one Instance on a monthly subscription, then, unless terminated, the subscription for that Instance will renew automatically for additional month-long periods at the end of each successive month.
                    </p>
                    <p>
                        <b>9. CANCELLATION OF INSTANCES; TERMINATION OF ACCOUNT</b><br>
                        You may, at any time, terminate any of Instances for which you subscribed by going to the “Projects” and selecting "Delete project”, thereby discontinuing any future automatic renewal for that particular Instance. Because each Instance is a stand-alone subscription, you may terminate some or all of your Instance subscriptions in this manner. Refunds will be made for unused portions of subscription periods.
                    </p>
                    <p>
                        <b>10. INTELLECTUAL PROPERTY; LIMITED LICENSE</b><br>
                        The Materials and Services on this Site, as well as their selection and arrangement, are protected by copyright, trademark, patent, and/or other intellectual property laws, and any unauthorized use of the Materials or Services may violate such laws and these Terms of Use. Except as expressly provided herein, we do not grant any express or implied rights to use the Materials and Services. You agree not to copy, republish, frame, download, transmit, modify, assign, share, distribute, or create derivative works based on the Site, its Materials, or its Services or their selection and arrangement, except as expressly authorized herein. In addition, you agree not to use any data mining, robots, or similar data gathering and extraction methods in connection with the Site.
                    </p>
                    <p>
                        In addition to the Materials and Services offered by us, this Site, or our sponsors/affiliates, may make available materials, links, information, products and/or services provided by third parties (collectively, the “Third Party Products and Services”). The Third Party Products and Services are governed by separate agreements that accompany such products or services. We offer no guarantees and assume no responsibility or liability of any type with respect to the Third Party Products and Services. You agree that you will not hold us responsible or liable with respect to the Third Party Products and Services or seek to do so, even if we promote and/or recommend particular goods or services.
                    </p>
                    <p>
                        We grant you a limited non-exclusive, non-transferable license to access and make personal use of the Site, Materials and Services and not to download or modify it, as part or as a whole. This license does not grant you the right to resell or otherwise commercialize the Site, Materials or Services. We may terminate your license at any time and for any reason upon notice to you.
                    </p>
                    <p>
                        <b>11. TRADEMARK INFORMATION</b><br>
                        The trademarks, logos, and service marks, including the “Spica” trademark (the “Marks”) displayed on this Site are the property of Spica or other third parties. You are not permitted to use the Marks without the prior written consent of Spica or such third party that may own the Mark.
                    </p>
                    <p>
                        <b>12. PROHIBITED USE OF THE WEBSITE</b><br>
                        You may not access or use the Site, Materials or Services for any other purpose other than that for which we make it available. In using the Site, including all Services and Materials available through it, you agree to not engage in any prohibited activities. A prohibited activity includes, but is not limited to:<br>
                    </p>
                    <p>
                        </p><ol type="a">
                            <li>criminal or tortious activity, including fraud, harassment, stalking, spamming, or copyright or trademark infringement; and</li>
                            <li>using the Site, Materials or Services in a manner inconsistent with any and all applicable laws and regulations.</li>
                        </ol>
                    <p></p>
                    <p>
                        <b>13. PROHIBITED CONTENT</b><br>
                        You understand that you are entirely responsible for your data and content (collectively “content”) and any harm resulting from your content. When you upload any content you thereby represent and warrant that:
                    </p>
                    <p>
                        </p><ol type="a">
                            <li>the creation, distribution, transmission, public display and performance, accessing, downloading and copying of your content does not and will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark or trade secret rights of any third party;</li>
                            <li>you have fully complied with any third-party licenses relating to your content, and have done all things necessary to successfully pass through to viewers any required terms;</li>
                            <li>your content does not contain any viruses, malicious code or other harmful or destructive content;</li>
                            <li>your content is not obscene, lewd, lascivious, filthy, excessively violent, harassing or otherwise objectionable, libelous or slanderous, does not incite, encourage or threaten immediate physical harm against another, does not violate any applicable law, regulation, or rule, and does not violate the privacy or publicity rights of any third party;</li>
                            <li>your content does not contain material that solicits personal information from anyone under 18 or exploits people under the age of 18 in a sexual or violent manner, and does not violate any applicable child pornography law or otherwise intended to protect the health or wellbeing of minors;</li>
                            <li>your content does not violate any applicable law designed to regulate electronic advertising;</li>
                            <li>your content does not constitute, contain, install or attempt to install or promote spyware, malware or other computer code designed to enable you or others to gather information about or monitor the online or other activities of another party on or through the Site;</li>
                            <li>your content does not contain any defamatory material; and</li>
                            <li>your content does not otherwise violate, or link to material that violates, any provision of these Terms of Use or any applicable law or regulation.</li>
                        </ol>
                    <p></p>
                    <p>
                        <b>14. PERSONAL INFORMATION AND PRIVACY</b><br>
                        You understand and agree that we may disclose information about you if we have a good faith belief that we are required to do so by law or legal process, to respond to claims, or to protect the rights, property, or safety of Spica or others. Please refer to our Privacy Policy for more information about the manner in which we protect and use your information.
                    </p>
                    <p>
                        <b>15. WARRANTIES AND DISCLAIMERS</b><br>
                        EXCEPT AS EXPRESSLY PROVIDED OTHERWISE IN A WRITTEN AGREEMENT BETWEEN YOU AND US, THIS SITE, AND ALL MATERIALS AND SERVICES ACCESSIBLE THROUGH THIS SITE, ARE PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, OR THE WARRANTY OF NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, WE MAKE NO WARRANTY THAT (I) THE SERVICES OR MATERIALS WILL MEET YOUR REQUIREMENTS; (II) THE SERVICES AND MATERIALS WILL BE UNINTERRUPTED, TIMELY, OR ERROR-FREE; (III) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICES OR MATERIALS WILL BE EFFECTIVE, ACCURATE, OR RELIABLE; (IV) THE QUALITY OF ANY SERVICES OR MATERIALS OBTAINED OR ACCESSIBLE BY YOU THROUGH THE SITE WILL MEET YOUR EXPECTATIONS; AND (V) ANY ERRORS IN THE SERVICES OR MATERIALS OBTAINED THROUGH THE SITE, OR ANY DEFECTS IN THE SITE, ITS SERVICES OR MATERIALS, WILL BE CORRECTED.
                    </p>
                    <p>
                        The use of the Services or the downloading or other use of any Materials through the Site is done at your own discretion and risk and with your agreement that you will be solely responsible for any damage to your computer system, loss of data, or other harm that results from such activities. We assume no liability for any computer virus or other similar software code that is downloaded to your computer from the Site or in connection with any Services or Materials offered through the Site.
                    </p>
                    <p>
                        <b>16. LIMITATION OF LIABILITY</b><br>
                        IN NO EVENT SHALL Spica OR ITS OFFICERS, OWNERS, EMPLOYEES, SUPPLIERS OR LICENSORS BE LIABLE TO YOU OR ANY THIRD PARTY UNDER ANY CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE OR UNDER LEGAL OR EQUITABLE THEORY FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT DAMAGES ARISING FROM YOUR USE OF THE SITE AND ANY OF THE MATERIALS OR SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. <b>NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR TOTAL LIABILITY FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT YOU ACTUALLY PAID TO US IN SUBSCRIPTION FEES DURING THE 12 MONTHS PRIOR TO THE DATE ON WHICH THE CAUSE OF ACTION AROSE.</b>
                    </p>
                    <p>
                        <b>17. INDEMNIFICATION</b><br>
                        You agree to indemnify and hold Spica, and its subsidiaries, affiliates, officers, directors, agents, owners, partners and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third party due to or arising out of your content, your use of the Site (or the Materials or Services), or your violation of these Terms of Use, your violation of any rights of another person or entity, or your violation of any law.
                    </p>
                    <p>
                        <b>18. GOVERNING LAW, JURISDICTION AND ARBITRATION</b><br>
                        This Agreement is governed by and shall be construed in accordance with the laws of Turkey. You accept and consent to the exclusive personal jurisdiction and venue of the courts in Antalya, Turkey. <b>You and Spica agree that any cause of action arising out of or related to the Site and any of our Services or Materials or related to this Agreement must commence within one (1) year after the cause of action arose; otherwise, such cause of action is permanently barred. Any controversy or claim shall be litigated on an individual basis, and shall not be consolidated in any arbitration with any claim or controversy of any other party.</b> Notwithstanding anything else to the contrary in this Agreement, you shall only be allowed to raise an objection to a bill/charge within ninety days starting from the day of the charge. If the objection is not raised within that time, the charge is deemed accepted by you and you are thereafter permanently barred from initiating a cause of action based on such charge(s).
                    </p>
                    <p>
                        <b>19. GENERAL</b><br>
                        The Terms of Use and the other rules, guidelines, licenses and disclaimers posted on the Site constitute the entire agreement between Spica and you with respect to your use of the Site. If for any reason a court of competent jurisdiction finds any provision of these Terms of Use, or portion thereof, to be unenforceable, that provision shall be enforced to the maximum extent permissible so as to effect the intent of the parties as reflected by that provision, and the remainder of the Terms of Use shall continue in full force and effect. Any failure by us to enforce or exercise any provision of these Terms of Use or related right shall not constitute a waiver of that right or provision.
                    </p>
                </mat-card>
            </div>
        </div>
    </div>
</div>