<app-header-background onPage="invoices"></app-header-background>

<div class="content-area">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="page-title">
          Invoices
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <!-- <div class="row">
      <div class="col-lg-12">
        <mat-card>
          <div class="row">
            <div class="col-lg-8 card-title">
              <h5>Mevcut Bakiyeniz</h5>
            </div>
            <div class="col text-right"><h3>₺26,64</h3></div>
          </div>

          <div class="row">
            <div class="col-lg-8 mt-auto mb-auto">
              <mat-progress-bar
                mode="determinate"Mevcut Bakiyeniz
                style="height:12px;"
                value="40"
              ></mat-progress-bar>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
-->

    <div class="row">
      <div class="col-12 mt-2">
        <loading-spinner *ngIf="!paginatedInvoices"></loading-spinner>
        <div
          *ngIf="paginatedInvoices && paginatedInvoices.length == 0"
          class="text-center col-12 pt-5"
        >
          You have no invoice yet!
        </div>
        <div *ngIf="paginatedInvoices && paginatedInvoices.length > 0">
          <table mat-table class="w-100" [dataSource]="paginatedInvoices">
            <ng-container matColumnDef="period">
              <th class="text-left" mat-header-cell *matHeaderCellDef>
                Period
              </th>
              <td class="text-left" mat-cell *matCellDef="let invoice">
                {{ invoice.period_start * 1000 | date }} -
                {{ invoice.period_end * 1000 | date }}
              </td>
            </ng-container>
            <ng-container matColumnDef="amount">
              <th class="text-center" mat-header-cell *matHeaderCellDef>
                Amount
              </th>
              <td class="text-center" mat-cell *matCellDef="let invoice">
                {{
                  invoice.total / 100
                    | currency: invoice.currency.toUpperCase():"symbol-narrow"
                }}
                <mat-icon
                  *ngIf="invoice.total < 0"
                  matTooltip="Your remaining subscription period will be added to your balance. You won't be charged until your balance is zero."
                  >info</mat-icon
                >
              </td>
            </ng-container>
            <ng-container matColumnDef="paid_at">
              <th class="text-center" mat-header-cell *matHeaderCellDef>
                Transaction Date
              </th>
              <td class="text-center" mat-cell *matCellDef="let invoice">
                {{
                  !invoice.status_transitions.paid_at
                    ? "Not Paid Yet"
                    : (invoice.status_transitions.paid_at * 1000
                      | date: "medium")
                }}
              </td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th class="text-right" mat-header-cell *matHeaderCellDef>
                Action
              </th>
              <td class="text-right" mat-cell *matCellDef="let invoice">
                <a href="{{ invoice.hosted_invoice_url }}" target="_blank">
                  <button mat-flat-button mat-icon-button>
                    <mat-icon fontSet="material-icons-outlined">link</mat-icon>
                  </button>
                </a>
                <a href="{{ invoice.invoice_pdf }}" target="_blank">
                  <button mat-flat-button mat-icon-button>
                    <mat-icon fontSet="material-icons-outlined"
                      >cloud_download</mat-icon
                    >
                  </button>
                </a>
              </td>
            </ng-container>
            <tr
              class=""
              mat-header-row
              *matHeaderRowDef="['period', 'amount', 'paid_at', 'actions']"
            ></tr>
            <tr
              class=""
              mat-row
              *matRowDef="
                let row;
                columns: ['period', 'amount', 'paid_at', 'actions']
              "
            ></tr>
          </table>
          <mat-paginator
            [length]="length"
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions"
            (page)="paginate($event)"
          >
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
