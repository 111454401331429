<app-header-background onPage="home"></app-header-background>
<div class="content-area">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="page-title">
          Payment Methods
          <button mat-button color="" (click)="openDialog()">
            Add a new one
            <mat-icon fontSet="material-icons-outlined">add</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <loading-spinner *ngIf="!dataPassed"></loading-spinner>
    <div class="row" *ngIf="paymentMethods?.data?.length == 0">
      <div class="text-center col-12 pt-5">You have no payment method yet!</div>
    </div>
    <div *ngIf="paymentMethods?.data?.length > 0">
      <table
        *ngIf="dataPassed"
        mat-table
        class="w-100"
        [dataSource]="paymentMethods?.data"
      >
        <ng-container matColumnDef="id">
          <th class="text-center" mat-header-cell *matHeaderCellDef>Id</th>
          <td class="text-center" mat-cell *matCellDef="let method">
            {{ method.id }}
          </td>
        </ng-container>
        <ng-container matColumnDef="brand">
          <th class="text-center" mat-header-cell *matHeaderCellDef>
            Card Type
          </th>
          <td class="text-center" mat-cell *matCellDef="let method">
            {{ method.card.brand.toUpperCase() }}
          </td>
        </ng-container>
        <ng-container matColumnDef="last4">
          <th class="text-center" mat-header-cell *matHeaderCellDef>
            Card Number
          </th>
          <td class="text-center" mat-cell *matCellDef="let method">
            **** **** **** {{ method.card.last4 }}
          </td>
        </ng-container>
        <ng-container matColumnDef="expire">
          <th class="text-center" mat-header-cell *matHeaderCellDef>
            Expires At
          </th>
          <td class="text-center" mat-cell *matCellDef="let method">
            {{ method.card.exp_month }} / {{ method.card.exp_year }}
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th class="text-center" mat-header-cell *matHeaderCellDef>Action</th>
          <td class="text-center" mat-cell *matCellDef="let method">
            <button
              matTooltip="Delete Payment Method"
              mat-icon-button
              (click)="removePaymentMethod(method.id)"
            >
              <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
            </button>
            <button
              [matTooltip]="
                method.id != paymentMethods.defaultPayment
                  ? 'Set As Default'
                  : 'Default Method'
              "
              mat-icon-button
              (click)="
                method.id == paymentMethods.defaultPayment
                  ? ''
                  : setAsDefault(method.id)
              "
            >
              <mat-icon fontSet="material-icons-outlined"
                >settings_applications</mat-icon
              >
            </button>
          </td>
        </ng-container>
        <tr
          class="text-center"
          mat-header-row
          *matHeaderRowDef="['brand', 'last4', 'expire', 'actions']"
        ></tr>
        <tr
          class="text-center"
          mat-row
          *matRowDef="let row; columns: ['brand', 'last4', 'expire', 'actions']"
          [class.default]="row.id == paymentMethods.defaultPayment"
        ></tr>
      </table>
    </div>
  </div>
</div>
