<mat-sidenav-container fullscreen>
  <div class="header-navigator">
    <a class="logo-container" [routerLink]="['/']">
      <img src="../assets/logo.png" class="logo" />
    </a>

    <div class="container" *ngIf="innerWidth > 1300">
      <a [routerLink]="['/projects']" [class.spica-text-primary]="activeUrl == '/projects'">
        <mat-icon fontSet="material-icons-outlined" class="mr-2">work_outline</mat-icon>Projects
      </a>
      <a [routerLink]="['/organizations']" [class.spica-text-primary]="activeUrl == '/organizations'">
        <mat-icon fontSet="material-icons-outlined" class="mr-2">people_outline</mat-icon>Organizations
      </a>
      <a  [routerLink]="['/invoices']" [class.spica-text-primary]="activeUrl == '/invoices'">
        <mat-icon fontSet="material-icons-outlined" class="mr-2">event_note</mat-icon>Invoices
      </a>
      <a  [routerLink]="['/licenses']" [class.spica-text-primary]="activeUrl == '/licenses'">
        <mat-icon fontSet="material-icons-outlined" class="mr-2">featured_play_list</mat-icon>Licenses
      </a>
    </div>

    <a class="profile link" [matMenuTriggerFor]="appMenu" *ngIf="username">
      {{ username ? 'Hi, ' + username : 'Not Loggined' }}
      <mat-icon fontSet="material-icons-outlined">account_circle</mat-icon>
    </a>

  </div>


  <mat-menu #appMenu="matMenu">
    <div *ngIf="innerWidth <= 1300">
      <button mat-menu-item [routerLink]="['/organizations']">
        <mat-icon fontSet="material-icons-outlined" class="mr-2">people_outline</mat-icon> Organizations
      </button>
      <button mat-menu-item [routerLink]="['/projects']">
        <mat-icon fontSet="material-icons-outlined" class="mr-2">work</mat-icon> Projects
      </button>
      <button mat-menu-item [routerLink]="['/invoices']">
        <mat-icon fontSet="material-icons-outlined" class="mr-2">event_note</mat-icon> Invoices
      </button>
    </div>

    <button [routerLink]="['/profile']" mat-menu-item>
      <mat-icon fontSet="material-icons-outlined">person</mat-icon> Profile
    </button>

    <button [routerLink]="['/payment-method']" mat-menu-item>
      <mat-icon fontSet="material-icons-outlined">payment</mat-icon> Payment Methods
    </button>
    
    <button [routerLink]="['/service']" mat-menu-item>
      <mat-icon fontSet="material-icons-outlined">extension</mat-icon> Services
    </button>

    <button mat-menu-item [routerLink]="['help']">
      <mat-icon fontSet="material-icons-outlined">help_outline</mat-icon> Help
    </button>
    <button  (click)="logOut()" mat-menu-item>
      <mat-icon fontSet="material-icons-outlined">exit_to_app</mat-icon> Logout
    </button>
  </mat-menu>
  <router-outlet #outlet="outlet"></router-outlet>
</mat-sidenav-container>