<div class="popup-dialog">
  <div class="title d-flex justify-content-between"><span>
      Change Plan
    </span>
    <mat-slide-toggle *ngIf="paymentMethods.length>0" color="primary" class="font-medium" (change)="changeAnnual()"
      [(ngModel)]="annualPrices"> Annual Prices
    </mat-slide-toggle>
  </div>
  <mat-divider></mat-divider>
  <div class="text">Upgrade/downgrade your plan. {{paymentMethods.length>0 ? ' The changes you make will cause your'
    +' server to be disabled for a few minutes' : ''}}</div>
  <div class="dialog-input-area">
    <div class="row">
      <div class="col">
        <mat-form-field *ngIf="paymentMethods.length>0" appearance="outline" class="w-100">
          <mat-label>Select your plan</mat-label>
          <mat-select [(ngModel)]="plan" placeholder="Select your plan">
            <mat-option [value]="p.name" *ngFor="let p of packages">{{(p.name).replace("_"," ")|titlecase}}</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="payment-none" *ngIf="paymentMethods.length==0">
          You do not have a valid payment method.
          <a class="link" [routerLink]="['/payment-method']">Check your payment method.</a>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="!onProcessing">
      <div class="col">
        <div class="mt-3 justify-content-between d-flex">
          <button mat-button class="btn-choose button-negative" (click)="onNoClick()">
            Cancel
          </button>
          <button [disabled]="paymentMethods.length==0 || !plan" mat-button class="btn-choose button-positive"
            cdkFocusInitial (click)="changePlan()">
            Change Plan
          </button>
        </div>
      </div>
    </div>
    <loading-spinner *ngIf="onProcessing"></loading-spinner>
  </div>
</div>