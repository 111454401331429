<app-header-background onPage="home"></app-header-background>

<div class="content-area">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="page-title text-center">Welcome to Spica Engine</div>
      </div>
    </div>
    <loading-spinner *ngIf="!dataProccessed"></loading-spinner>
    <div
      *ngIf="dataProccessed && projects.length"
      class="row additional-info mt-5"
    >
      <div class="col-12">
        <mat-card class="col-12">
          <mat-card-title>
            <img src="/assets/logo-blue.png" class="logo" />Would you like to
            create a modern stack project?
          </mat-card-title>
          <mat-card-content>
            Save your time and use the latest technology for your projects. We
            power mobile applications, web sites, games, startups and many more
            with our profound engine. With our industry-defining solutions
            tailored for your project needs, we are here to start a successful
            journey with you!
          </mat-card-content>
          <div mat-button class="col-12 button-container">
            <button mat-button [routerLink]="['/projects']">
              Create a project
            </button>
          </div>
        </mat-card>
      </div>
    </div>
    <div
      *ngIf="dataProccessed && !projects.length"
      class="row first-project mt-5"
    >
      <div class="col-12">
        <mat-card class="col-12">
          <div class="row">
            <img class="col-6" src="/assets/logo-blue.png" />
            <div class="col-6 pt-5 creation-panel">
              <mat-card-title>
                Let's create your first project!
              </mat-card-title>
              <mat-card-content class="pt-3">
                Your free-trial instance is available for a month. To create
                your first Spica instance, you just need to give a name to your
                project. We don't require any payment card information. Creation
                process may take few minutes.
                <mat-form-field class="col-12 mt-5" appearance="outline">
                  <mat-label>Enter you project name</mat-label>
                  <input
                    [(ngModel)]="newProjectName"
                    maxlength="20"
                    matInput
                    [pattern]="reg_ex"
                  />
                </mat-form-field>
              </mat-card-content>
              <div mat-button class="button-container">
                <button
                  mat-button
                  mat-flat-button
                  color="primary"
                  (click)="createProject(chooseAsset)"
                  [disabled]="!newProjectName || !checkPattern()"
                >
                  Create a project
                </button>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
    <div class="row two-column additional-info mt-4">
      <div class="col-lg-6 col-md-12">
        <mat-card class="col-12" [class.expanded]="expanded.assetstore">
          <mat-card-title class="ready">
            <a href="https://assetstore.spicaengine.com/" target="_blank"
              >Assetstore</a
            >
          </mat-card-title>
          <mat-card-content>
            Download community driven pre-built data models, functions, policy
            rules and import them to your instance with a single click.
            Experience of masses in your finger tips.
            <ul class="mt-1">
              <li class="addition">Data model assets</li>
              <li class="addition">Community driven functions</li>
              <li class="addition">
                Ready-to-use policy rules and identity templates
              </li>
              <li class="addition">Usable audio-visual assets</li>
              <li class="addition">Re-usable frontend components</li>
            </ul>
          </mat-card-content>
          <div
            mat-button
            class="col-12 button-container text-center"
            *ngIf="!expanded.assetstore"
          >
            <button mat-button (click)="expanded.assetstore = true">
              See All Features
            </button>
          </div>
        </mat-card>
        <mat-card
          class="col-12 additional-info"
          [class.expanded]="expanded.chronos"
        >
          <mat-card-title> Upcoming: Chronos </mat-card-title>
          <mat-card-content>
            Chronos is a backup service with features such as scheduled backups,
            snapshots to make your server time travel. All backups and snapshots
            are stored in the cloud and you can download them anytime anywhere.
            <ul class="mt-1">
              <li class="addition">Scheduled backups</li>
              <li class="addition">Conditional backups</li>
              <li class="addition">Snapshot of your project</li>
              <li class="addition">Re-usable server configurations</li>
              <li class="addition">Publishable time machine images</li>
            </ul>
          </mat-card-content>
          <div
            mat-button
            class="col-12 button-container text-center"
            *ngIf="!expanded.chronos"
          >
            <button mat-button (click)="expanded.chronos = true">
              See All Features
            </button>
          </div>
        </mat-card>
      </div>
      <div class="col-lg-6 col-md-12">
        <mat-card
          class="col-12 additional-info"
          [class.expanded]="expanded.composer"
        >
          <mat-card-title class="ready">
            <a href="https://observer.spicaengine.com/" target="_blank"
              >Observer</a
            >
          </mat-card-title>
          <mat-card-content>
            Documentation of API & function calls for your projects. Resource
            usage graphs and real-time function and worker monitoring. Follow
            your project down to the finest detail.
            <ul class="mt-1">
              <li class="addition">
                Monitoring functions & resource usage graphics
              </li>
              <li class="addition">
                Documentation of bucket api and function calls and also
                downloadable pdf format
              </li>
              <li class="addition">Api and database resource graphics</li>
              <li class="addition">Extract interfaces of your buckets</li>
            </ul>
          </mat-card-content>
          <div
            mat-button
            class="col-12 button-container text-center"
            *ngIf="!expanded.composer"
          >
            <button mat-button (click)="expanded.composer = true">
              See All Features
            </button>
          </div>
        </mat-card>

        <mat-card class="col-12" [class.expanded]="expanded.auditing">
          <mat-card-title> Upcoming: Auditing Tool </mat-card-title>
          <mat-card-content>
            Auditing Tool offers real-time data & critical information
            monitoring of your web-site, keeps you on your project at all times.
            <ul class="mt-1">
              <li class="addition">Real-time web site monitoring</li>
              <li class="addition">
                Informs you what happened when you were away
              </li>
              <li class="addition">
                Monitoring tool for mission critical projects
              </li>
              <li class="addition">Informs you if any incident happens</li>
            </ul>
          </mat-card-content>
          <div
            mat-button
            class="col-12 button-container"
            *ngIf="!expanded.auditing"
          >
            <button mat-button (click)="expanded.auditing = true">
              See All Features
            </button>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</div>
<ng-template #chooseAsset>
  <app-choose-asset
    (selectedButton)="selectButtonOfAssets($event)"
    (changeSelected)="selectedAsset = $event"
  ></app-choose-asset>
</ng-template>
