<div class="popup-dialog">
  <div class="title">On-Premise License</div>
  <mat-divider></mat-divider>
  <div class="text">On-premise licenses are designed to run a Spica instance on your own servers. 
    Once you create an on-premise license you can use extra services such as Assetstore or Integrated Success Services.</div>
  <div class="dialog-input-area">
      <mat-form-field class="col-12" appearance="outline">
        <mat-label>Enter you project name</mat-label>
        <input [(ngModel)]="newProjectName" matInput (keyup.enter)="onOkClick()" />
      </mat-form-field>
      <mat-hint class="inline-error" *ngIf="(error && !onProcessing)">
        {{error}}
        <a class="link" [routerLink]="['/payment-method']" (click)="onNoClick()">Click here to check your payment method.</a>
      </mat-hint>
  </div>

  <div class="text-right" *ngIf="!onProcessing">
    <button mat-button class="btn-choose button-positive" (click)="onOkClick()" [disabled]="!newProjectName">
      Ok
    </button>
    <button mat-button class="btn-choose button-negative" (click)="onNoClick()">
      Cancel
    </button>
  </div>
  <loading-spinner *ngIf="onProcessing"></loading-spinner>
</div>
