import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../../services/auth.service";
import { GoogleAuthService } from "./../../services/google-auth.service";
import { GithubAuthService } from "./../../services/github-auth.service";
import { MatDialog } from "@angular/material/dialog";
import { GetEmailDialog } from "../popups/get-email.component";

@Component({
  templateUrl: "login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  formGroup: FormGroup;
  formGroup2: FormGroup;
  loginProgress: boolean = false;
  forgotProgress: boolean = false;
  forgotPanel: boolean = false;
  githubAuthCode: string;
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private googleAuth: GoogleAuthService,
    private githubAuth: GithubAuthService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.authService.isAuthenticated().subscribe(
      (response) => {
        if (response) {
          if (
            this.activatedRoute.snapshot.queryParamMap.has("operation") &&
            this.activatedRoute.snapshot.queryParamMap.get("operation") ==
            "logOut"
          ) {
            this.authService.logout().then(() => {
              setTimeout(() => {
                window.location.href =
                  this.activatedRoute.snapshot.queryParamMap.get("callback");
              }, 600);
            });
          } else if (
            this.activatedRoute.snapshot.queryParamMap.has("callback")
          ) {
            window.location.href =
              this.activatedRoute.snapshot.queryParamMap.get("callback");
          } else {
            this.router.navigate(["/"]);
          }
        }
        return false;
      },
      (error) => {
        return false;
      }
    );
    this.createForm();
    this.createRecoveryForm();
    /*
    if (this.activatedRoute.snapshot.queryParamMap.has("code")) {
      this.githubAuthCode = this.activatedRoute.snapshot.queryParamMap.get(
        "code"
      );
      this.loginWithGithub();
    }
    */
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      email: [
        "",
        [
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ],
      ],
      password: ["", Validators.required],
    });
  }

  createRecoveryForm() {
    this.formGroup2 = this.formBuilder.group({
      email: [
        "",
        [
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ],
      ],
    });
  }

  getError(el) {
    switch (el) {
      case "email":
        if (this.formGroup.get("email").hasError("required")) {
          return "Enter your email please!";
        }
        if (this.formGroup.get("email").hasError("pattern")) {
          return "Please provide a valid email address!";
        }
        break;
      case "pass":
        if (this.formGroup.get("password").hasError("required")) {
          return "Enter your password please!";
        }
        break;
      default:
        return "";
    }
  }

  onSubmit(post) {
    this.loginProgress = true;

    this.authService
      .loginV2(post.email, post.password)
      .toPromise()
      .then((data) => {
        this.loginProgress = false;
        this.toastr.success(
          undefined,
          "Welcome " + data.message + ". It is nice to see you!",
          {
            positionClass: "toast-bottom-center",
            timeOut: 2500,
          }
        );
        setTimeout(() => {
          if (this.activatedRoute.snapshot.queryParamMap.has("callback")) {
            window.location.href =
              this.activatedRoute.snapshot.queryParamMap.get("callback");
          } else {
            this.router.navigate(["/"]);
          }
        }, 600);
      })
      .catch((err) => {
        this.loginProgress = false;
        this.toastr.error(undefined, err.error.message, {
          positionClass: "toast-bottom-center",
          timeOut: 2500,
        });
      });
  }

  recoverPassword(post) {

    this.forgotProgress = true;
    this.authService
      .createPasswordRecoveryCode(post.email)
      .toPromise()
      .then((data) => {
        this.forgotProgress = false;
        this.toastr.success(undefined, data["message"], {
          positionClass: "toast-bottom-center",
          timeOut: 2500,
        });
        setTimeout(() => {
          this.router.navigate(["/"]);
        }, 1000);
      })
      .catch((data) => {

        this.toastr.error(undefined, data.error.message, {
          positionClass: "toast-bottom-center",
          timeOut: 2500,
        });
      }).finally(() => this.forgotProgress = false)
  }

  loginWithGoogle() {
    this.loginProgress = true;
    this.googleAuth
      .loginWithGoogle()
      .then((user) => {
        let userObj = {
          email: user.email,
          name: user.firstName,
          surname: user.lastName,
          password: user.id,
        };
        this.authService.loginWithGoogle(userObj).subscribe(
          () => {
            this.loginProgress = false;
            this.onSubmit({ email: userObj.email, password: userObj.password });
          },
          () => { }
        );
        console.log("user : ", userObj);
      })
      .catch((error) => {
        console.log("ERROR WHEN LOGİN GOOGLE", error);
      }).finally(() => this.loginProgress = false)
  }

  /*
  loginWithGithub() {
    this.loginProgress = true;
    this.githubAuth
      .getGithubToken(this.githubAuthCode)
      .toPromise()
      .then()
      .catch((error) => {
        let tokenData = error.error.text.split("&")[0].split("=")[1];
        this.githubAuth
          .getGithubUser(tokenData)
          .toPromise()
          .then((user) => {
            if (!user["email"]) {
              this.openGetEmailDialog();
            }
            
          })
          .catch((er) => console.log(er));

        console.log("ERROR LOGİN Github", error.error.text);
      });
  }
  openGetEmailDialog() {
    this.dialog
      .open(GetEmailDialog, {
        width: "30%",
        maxWidth: "400px",
      })
      .afterClosed()
      .toPromise()
      .then((data) => {
        console.log(data);
      });
  }
  */
}
