import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ProjectsComponent } from "./pages/projects/projects.component";

import { OrganizationsComponent } from "./pages/organizations/organizations.component";
import { RegisterComponent } from "./pages/register/register.component";
import { LoginComponent } from "./pages/login/login.component";
import { AuthGuard } from "./guards/auth.guard";

//import { NavbarComponent } from "./navbar/navbar.component";
import { EditorganizationComponent } from "./pages/editorganization/editorganization.component";
import { ProfileComponent } from "./pages/profile/profile.component";
import { ServiceComponent } from "./pages/service/service.component";
import { InvoicesComponent } from "./pages/invoices/invoices.component";
import { DetailsComponent } from "./pages/project-details/details.component";
import { HomeComponent } from "./pages/home/home.component";
import { LicensesComponent } from "./pages/licenses/licenses.component";
import { PaymentMethodComponent } from "./pages/payment-method/payment-method.component";
import { PrivacyPolicyComponent } from "./pages/legal/privacy-policy/privacy-policy.component";
import { TermsConditionsComponent } from "./pages/legal/terms-conditions/terms-conditions.component";
import { PasswordRecoveryComponent } from "./pages/password-recovery/password-recovery.component";
import { HelpComponent } from "./pages/help/help.component";
import { AppComponent } from "./app.component";

export const loginState = true;

const routes: Routes = [
  {
    path: "privacy-policy",
    component: PrivacyPolicyComponent,
    data: { needSession: false },
  },
  {
    path: "terms",
    component: TermsConditionsComponent,
    data: { needSession: false },
  },
  {
    path: "terms/:subPage",
    component: TermsConditionsComponent,
    data: { needSession: false },
  },
  {
    path: "password-recovery",
    component: PasswordRecoveryComponent,
    data: { needSession: false },
  },
  { path: "login", component: LoginComponent, data: { needSession: false } },
  {
    path: "register",
    component: RegisterComponent,
    data: { needSession: false },
  },
  {
    path: "",
    component: AppComponent,
    canActivate: [AuthGuard],
    data: { needSession: true },
    children: [
      { path: "", component: HomeComponent, data: { needSession: true } },
      {
        path: "projects",
        component: ProjectsComponent,
        data: { needSession: true },
      },
      {
        path: "details/:projectId",
        component: DetailsComponent,
        data: { needSession: true },
      },
      {
        path: "organizations",
        component: OrganizationsComponent,
        data: { needSession: true },
      },
      {
        path: "profile",
        component: ProfileComponent,
        data: { needSession: true },
      },
      {
        path: "service",
        component: ServiceComponent,
        data: { needSession: true },
      },
      {
        path: "editorganization/:organizationId",
        component: EditorganizationComponent,
        data: { needSession: true },
      },
      // { path: "navbar", component: NavbarComponent, canActivate: [loginState] },
      {
        path: "payment-method",
        component: PaymentMethodComponent,
        data: { needSession: true },
      },
      {
        path: "invoices",
        component: InvoicesComponent,
        data: { needSession: true },
      },
      {
        path: "licenses",
        component: LicensesComponent,
        data: { needSession: true },
      },
      { path: "help", component: HelpComponent, data: { needSession: true } },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],

  exports: [RouterModule],
})
export class AppRoutingModule {}
