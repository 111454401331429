// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  url: "https://hq.spicaengine.com/api/",
  ws_url: "wss://hq.spicaengine.com/api",
  assetstore_url: "https://assetstore-882ef.hq.spicaengine.com/api/",
  production: false,
  api_key: "fqr1k8bsg6e4",
  assetstore_api_key: "eqoa1kcujy27g",
  assetstore_domain: "assetstore-882ef.hq.spicaengine.com",
  project_name_regex: /^[^_]*$/,
  stripe_in_test: false,
  stripe_live_pk: "pk_live_cOscDw6uqRvR4MUvNQaI3cT8",
  stripe_test_pk: "pk_test_2OX3ckgUgbG7IlIln1c2lfRw"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
