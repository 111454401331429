<div class="popup-dialog">
  <div class="title">{{ 'Update Version' }}</div>
  <mat-divider></mat-divider>
  <div class="text" *ngIf="versions && versions.length>1">
    Update the Spica version of this project. The changes you make will cause
    your server to be disabled for a few minutes.
  </div>
  <div class="dialog-input-area">
    <mat-form-field
      appearance="outline"
      class="w-100"
      *ngIf="versions && versions.length>1"
    >
      <mat-select [(ngModel)]="version">
        <mat-option *ngFor="let item of versions" [value]="item.name"
          >{{item.name}}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <div class="mt-3" *ngIf="versions && versions.length==1">
      You are already using the latest version of Spica
    </div>

    <div class="row" *ngIf="!onProcessing">
      <div class="col">
        <div class="mt-3 justify-content-between d-flex">
          <button
            mat-button
            class="btn-choose button-negative"
            (click)="onNoClick()"
          >
            Cancel
          </button>
          <button
            *ngIf="data.project.status != 'expired' && versions && versions.length>1"
            mat-button
            class="btn-choose button-positive"
            cdkFocusInitial
            (click)="updateVersion()"
          >
            Update Version
          </button>
        </div>
      </div>
    </div>
    <loading-spinner *ngIf="onProcessing"></loading-spinner>
  </div>
</div>
