<app-header-background onPage="details"></app-header-background>
<div class="content-area">
  <div class="container">
    <div class="page-title">
      Project Details
      <div>
        <!-- <button [disabled]="!paymentMethods" mat-button (click)="openUpdateDialog()">Update/Upgrade
          <mat-icon class="update" fontSet="material-icons-outlined">arrow_upward</mat-icon>
        </button> -->
        <button
          matButton
          mat-raised-button
          class="restart"
          (click)="openRestartDialog()"
        >
          Restart
        </button>
        <button
          matButton
          mat-raised-button
          class="button-negative important"
          (click)="openRemoveDialog()"
        >
          Remove
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="projectDetailsObervable | async" class="container">
    <div class="row mt-4" *ngIf="!isProcessing.metaInfo">
      <div class="col">
        <mat-form-field class="w-100">
          <mat-label>Project Name</mat-label>
          <input
            [(ngModel)]="unrelatedProject.project_name"
            matInput
            type="text"
          />
          <button
            mat-icon-button
            matSuffix
            [disabled]="!unrelatedProject.project_name"
            (click)="updateProject(unrelatedProject)"
          >
            <mat-icon fontSet="material-icons-outlined">save</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>

    <div class="col-12" *ngIf="isProcessing.metaInfo">
      <loading-spinner diameter="50"></loading-spinner>
    </div>

    <div class="info-table">
      <div class="row">
        <div class="col">
          Project Package
          <div class="dot">:</div>
        </div>
        <div class="col">
          <div class="with-action">
            <span>
              {{ relatedProject.package_code.replace("_", " ") | titlecase }}
            </span>
           <div class="action">
            <button
            [disabled]="!paymentMethods"
            mat-button
            (click)="openUpdatePackageDialog()"
          >
           Upgrade
          </button>
          <mat-icon fontSet="material-icons-outlined"
          >arrow_upward</mat-icon
        >
           </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          Project Host
          <div class="dot">:</div>
        </div>
        <div class="col">
          <a (click)="goToServer(relatedProject)" target="_blank">{{
            relatedProject.server.ip_address
          }}</a>
        </div>
      </div>
      <div class="row">
        <div class="col">
          Date Created
          <div class="dot">:</div>
        </div>
        <div class="col">
          {{ relatedProject.server.created_at | date: "mediumDate" }}
        </div>
      </div>
      <div class="row">
        <div class="col">
          Version
          <div class="dot">:</div>
        </div>
        <div class="col">
          <div class="with-action">
            <span>
              {{ relatedProject.server.version }}
            </span>
            <div class="action">
              <button mat-button (click)="openUpdateVersionDialog()">
                Upgrade
              </button>
              <mat-icon fontSet="material-icons-outlined"
              >arrow_upward</mat-icon
            >
            </div>
           
          </div>
        </div>
      </div>
      <div class="row row-end">
        <div class="col">
          Expiry Date
          <div class="dot">:</div>
        </div>
        <div class="col">
          {{ relatedProject.expire_at | date: "mediumDate" }}
        </div>
      </div>
    </div>

    <div
      *ngIf="attachableServices && userID == relatedProject.user._id"
      class="row mt-5"
    >
      <div class="col">
        <b>Attach As Service</b>
        <hr />
        <mat-chip-list *ngIf="!isProcessing.sub_services">
          <div *ngFor="let license of attachableServices">
            <mat-chip
              (click)="attachToService(license)"
              [class.assigned]="license.assigned"
            >
              {{ license.project_name }}
              <mat-icon *ngIf="!license.assigned">add_circle</mat-icon>
              <mat-icon *ngIf="license.assigned">cancel</mat-icon>
            </mat-chip>
          </div>
        </mat-chip-list>
        <div
          *ngIf="
            !isProcessing.sub_services &&
            attachableServices &&
            attachableServices.length == 0
          "
          class="no-organization"
        >
          <mat-icon fontSet="material-icons-outlined">error</mat-icon>
          You do not have any license to use as a service. 
          <!-- <span *ngIf="unrelatedProject.head_license"> Because you are already marked as a service </span> -->
        </div>
        <loading-spinner *ngIf="isProcessing.sub_services"></loading-spinner>
      </div>
    </div>
    <!-- <mat-divider class="mt-3"></mat-divider> -->
    <div
      *ngIf="assignedOrganizations && userID == relatedProject.user._id"
      class="row mt-5"
    >
      <div class="col">
        <b>Assign To Organizations</b>
        <hr />
        <mat-chip-list *ngIf="!isProcessing.organization">
          <div *ngFor="let organization of userOrganizations">
            <mat-chip
              (click)="toggleAssign(organization)"
              [class.assigned]="organization.assigned"
            >
              {{ organization.name }}
              <mat-icon *ngIf="!organization.assigned">add_circle</mat-icon>
              <mat-icon *ngIf="organization.assigned">cancel</mat-icon>
            </mat-chip>
          </div>
        </mat-chip-list>
        <div
          *ngIf="
            !isProcessing.organization &&
            userOrganizations &&
            userOrganizations.length == 0
          "
          class="no-organization"
        >
          <mat-icon fontSet="material-icons-outlined">error</mat-icon>
          You are not a part of any organization. Please click
          <a [routerLink]="['/organizations']">here</a> to create an
          organization.
        </div>
        <loading-spinner *ngIf="isProcessing.organization"></loading-spinner>
      </div>
    </div>
    <mat-divider class="mt-3"></mat-divider>
    <div class="row mt-3">
      <div class="col-6">
        <mat-card>
          <div *ngIf="!isProcessing.observer">
            <h4>Observer</h4>
            {{
              relatedProject.server?.observer_activated_date
                ? "Monitoring activation date : " +
                  (relatedProject.server?.observer_activated_date
                    | date: "mediumDate")
                : "Monitoring is available. Activate it now!"
            }}
            <div class="observer">
              <button
                (click)="activateObserver()"
                [class.passive]="
                  !relatedProject.server?.observer_activated_date
                "
                color="primary"
                matButton
                mat-raised-button
              >
                {{
                  relatedProject.server?.observer_activated_date
                    ? "Go to Observer"
                    : "Activate"
                }}
              </button>
            </div>
          </div>
          <loading-spinner *ngIf="isProcessing.observer"></loading-spinner>
        </mat-card>
        <!-- <mat-card>
          <h4>Auditing</h4>
          Auditing service is under development now!
        </mat-card> -->
      </div>
      <div class="col-6">
        <mat-card>
          <h4>Chronos</h4>
          Our backup service Chronos is coming soon!
        </mat-card>
      </div>
    </div>
    <mat-divider class="mt-5"></mat-divider>
    <div class="row">
      <div class="col-12 mt-5">
        <h4>Advance Settings</h4>
        <p class="mt-3">
          You can change advance server settings here. We believe default
          settings cover most of generic projects but in case of developing an
          extra-ordinary project you can change server settings here.
        </p>
        <loading-spinner *ngIf="isProcessing.metaInfo"></loading-spinner>
        <div
          class="mt-5"
          *ngIf="!isProcessing.metaInfo && project.enableAdvanceSettings"
        >
          <mat-slide-toggle
            class="mb-4"
            [labelPosition]="'before'"
            [(ngModel)]="corsConfig['cors_allow_credentials']"
          >
            Cors Allow Credentials
          </mat-slide-toggle>
          <mat-form-field
            *ngIf="corsConfig['cors_allowed_headers']"
            class="w-100"
          >
            <mat-chip-list #chipList>
              <mat-chip
                *ngFor="let corsHeader of corsConfig['cors_allowed_headers']"
                selectable
                removable
                (removed)="removeChipItem(corsHeader, 'headers')"
              >
                {{ corsHeader }}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
              <input
                placeholder="Cors Allowed Headers"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                matChipInputAddOnBlur
                (matChipInputTokenEnd)="addChipItem($event, 'headers')"
              />
            </mat-chip-list>
          </mat-form-field>

          <mat-form-field
            *ngIf="corsConfig['cors_allowed_methods']"
            class="w-100"
          >
            <mat-chip-list #chipList>
              <mat-chip
                *ngFor="let corsMethod of corsConfig['cors_allowed_methods']"
                selectable
                removable
                (removed)="removeChipItem(corsMethod, 'methods')"
              >
                {{ corsMethod }}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
              <input
                placeholder="Cors Allowed Methods"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                matChipInputAddOnBlur
                (matChipInputTokenEnd)="addChipItem($event, 'methods')"
              />
            </mat-chip-list>
          </mat-form-field>
          <mat-form-field
            *ngIf="corsConfig['cors_allowed_origins']"
            class="w-100"
          >
            <mat-chip-list #chipList>
              <mat-chip
                *ngFor="let corsMethod of corsConfig['cors_allowed_origins']"
                selectable
                removable
                (removed)="removeChipItem(corsMethod, 'origins')"
              >
                {{ corsMethod }}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
              <input
                placeholder="Cors Allowed Origins"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                matChipInputAddOnBlur
                (matChipInputTokenEnd)="addChipItem($event, 'origins')"
              />
            </mat-chip-list>
          </mat-form-field>

          <!-- <div class="row">
            <div class="col-6">
              <label>Function pool size</label> <br>
              <div class="slider-slide">
                <mat-slider thumbLabel min="1" [max]="poolLimit.function_pool_max_size" step="1"
                  [(ngModel)]="corsConfig['function_pool_size']"></mat-slider>
                <label class="slider-range">{{poolLimit.function_pool_size }} /
                  {{poolLimit.function_pool_max_size}}</label>
              </div>
            </div>
          </div> -->
          <div class="row">
            <div class="col-6">
              <label>Payload size limit </label> <br />
              <div class="slider-slide">
                <mat-slider
                  thumbLabel
                  min="5"
                  max="100"
                  step="1"
                  [(ngModel)]="corsConfig['payload_size_limit']"
                >
                </mat-slider>
                <label class="slider-range"
                  >{{ corsConfig["payload_size_limit"] }} / 100</label
                >
              </div>
            </div>
            <div class="col-6">
              <label>Storage object size limit</label> <br />
              <div class="slider-slide">
                <mat-slider
                  thumbLabel
                  min="5"
                  max="1024"
                  step="1"
                  [(ngModel)]="corsConfig['storage_object_size_limit']"
                >
                </mat-slider>
                <label class="slider-range"
                  >{{ corsConfig["storage_object_size_limit"] }} / 1024</label
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6 align-self-center">
              <mat-slide-toggle
                class="mb-4"
                [labelPosition]="'before'"
                [(ngModel)]="corsConfig['bucket_cache']"
              >
                Bucket cache
              </mat-slide-toggle>
            </div>
            <div class="col-6">
              <mat-form-field class="w-100">
                <mat-label
                  >Lifespan of the bucket-data response
                  caches(second)</mat-label
                >
                <input
                  [min]="1"
                  [disabled]="!corsConfig['bucket_cache']"
                  [(ngModel)]="corsConfig['bucket_cache_ttl']"
                  matInput
                  type="number"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <mat-form-field class="w-100">
                <mat-label
                  >Passport identity token expiration seconds limit</mat-label
                >
                <input
                  [(ngModel)]="
                    corsConfig[
                      'passport_identity_token_expiration_seconds_limit'
                    ]
                  "
                  matInput
                  type="number"
                />
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field class="w-100">
                <mat-label
                  >Passport identity token default expires in</mat-label
                >
                <input
                  [(ngModel)]="corsConfig['passport_identity_token_expires_in']"
                  matInput
                  type="number"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <mat-form-field class="w-100">
                <mat-label>Function maximum timeout (s)</mat-label>
                <input
                  [(ngModel)]="corsConfig['function_timeout']"
                  matInput
                  type="number"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="text-right">
            <button
              matButton
              mat-raised-button
              color="primary"
              (click)="updateProjectCors(relatedProject.server)"
            >
              Save
            </button>
          </div>
        </div>
        <div *ngIf="!project.enableAdvanceSettings" class="text-right mt-3">
          <button
            matButton
            mat-raised-button
            class="button-negative important"
            (click)="project.enableAdvanceSettings = true"
          >
            Show Advance Settings
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12" *ngIf="isProcessing.main">
    <loading-spinner diameter="50"></loading-spinner>
  </div>

</div>
