<app-header-background onPage="projects"></app-header-background>
<div class="content-area projects">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="page-title">
                    Services
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <loading-spinner *ngIf="!dataPassed"></loading-spinner>
        <div *ngIf="servicesObservable | async">
            <div class="row" *ngIf="dataPassed">
                <div class="col-lg-12 col-md-12 services" *ngFor="let service of allServices">
                    <mat-card [class.success-card]="service.isActivated" [class.passive-card]="!service.isActivated">
                        <div class="meta">
                            <img class="service-image" [src]="service.image">
                            <div class="overlay-info">
                                <h4 class="service-name">{{ service.key.split('_').join('\n') | titlecase }}
                                </h4>
                                <div class="service-price">{{service.value.price | currency}}</div>
                            </div>
                        </div>
                        <div class="info">
                            <p *ngIf="!service.isActivated">{{ service.value.description  | titlecase }}</p>
                            <p *ngIf="service.isActivated">To use this service, you can contact us via Slack, Discord or
                                email with the following ID: {{user_id}}</p>
                        </div>
                        <div class="action-buttons">
                            <div>{{(service.isActivated) ? "Enabled" : "Disabled"}}</div>
                            <div *ngIf="service.status=='requested'" >
                               <mat-spinner [diameter]="30"></mat-spinner>
                            </div>
                            <div *ngIf="service.status=='denied'" class="denied">Your service is denied ! Please check your payment method</div>
                            <button class="done-button" *ngIf="!service.isActivated && service.status!='denied'"
                                matTooltip="Enable {{service.key.split('_').join('\n') | titlecase}}" mat-icon-button
                                (click)="openCreateDialog(service) ">
                                <mat-icon fontSet="material-icons-outlined">done</mat-icon>
                            </button>
                            <button class="done-button" *ngIf="!service.isActivated && service.status=='denied'"
                                matTooltip="Enable {{service.key.split('_').join('\n') | titlecase}}" mat-icon-button
                                (click)="retryService(service) ">
                                <mat-icon fontSet="material-icons-outlined">refresh</mat-icon>
                            </button>
                            <button class="button-negative" *ngIf="service.isActivated && service.user._id == user_id"
                                matTooltip="Disable {{service.key.split('_').join('\n') | titlecase}}" mat-icon-button
                                (click)="openRemoveDialog(service) ">
                                <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
                            </button>
                        </div>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</div>