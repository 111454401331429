<div class="popup-dialog">
  <div class="title">
    {{active_step =='first' ? 'Create New Project' : onProcessing ? 'Processing Payment' : 'Select Template'}}
  </div>
  <mat-divider></mat-divider>
  <mat-tab-group [selectedIndex]="active_step =='first' ? 0 :1">
    <mat-tab label="First">
      <div class="text">
        Please enter your project name to the field and select one of the below
        package options to start benefiting from advanced services of Spica! For
        detailed pricing please visit<a
          href="https://spicaengine.com/pricing"
          target="_blank"
        >
          pricing page.</a
        >
      </div>
      <div class="dialog-input-area row">
        <mat-form-field class="col-12" appearance="outline">
          <mat-label>Enter you project name</mat-label>
          <input
            autocomplete="off"
            [(ngModel)]="newProjectName"
            [pattern]="reg_ex"
            matInput
            maxlength="20"
          />
        </mat-form-field>
        <div class="package col-12">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Select a package</mat-label>
            <mat-select [(ngModel)]="plan">
              <!-- <mat-option (click)="haveFreeLimit ? '' :choosePlan(freePlan?.value)" [value]="freePlan?.value"
              [matTooltipDisabled]="!haveFreeLimit" [disabled]="haveFreeLimit"
              matTooltip="You can have only two free instance at the same time"> -->
              <mat-option
                (click)="haveFreeLimit ? '' :choosePlan(freePlan?.value)"
                [value]="freePlan?.value"
                [disabled]="haveFreeLimit"
                [matTooltip]="haveFreeLimit ? 'You can have only two free instance at the same time' : 'You can use it as long as you are active here'"
              >
                <div class="package__select">
                  <div class="package__select__option">{{freePlan?.name}}</div>
                  <div class="package__select__option">
                    {{freePlan?.entry}} Entries
                  </div>
                  <div class="package__select__option">
                    {{freePlan?.users}} Users
                  </div>
                  <div class="package__select__option">
                    {{freePlan?.storage}} Storage
                  </div>
                  <div class="package__select__option">
                    <span class="spica-text-primary"><b>$0</b></span>
                  </div>
                </div>
              </mat-option>
              <mat-option
                class="d-none"
                (click)="choosePlan(package.value)"
                [class.d-block]="isAnnualViewEnabled(package.yearly)"
                *ngFor="let package of packages"
                [value]="package.value"
              >
                <div class="package__select">
                  <div class="package__select__option">{{package.name}}</div>
                  <div class="package__select__option">
                    {{package.entry}} Entries
                  </div>
                  <div class="package__select__option">
                    {{package.users}} Users
                  </div>
                  <div class="package__select__option">
                    {{package.storage}} Storage
                  </div>
                  <div class="package__select__option">
                    <span class="spica-text-primary"
                      ><b>${{package.cost}}</b></span
                    >
                    per {{package.yearly ? 'year' : 'month'}}
                  </div>
                </div>
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-slide-toggle
            color="primary"
            [(ngModel)]="annualPrices"
            (ngModelChange)="annualChange()"
            matTooltip="Annual Prices"
          >
          </mat-slide-toggle>
        </div>
        <div class="provider col-12">
          <div>
            <div>Select a cloud provider</div>
            <mat-radio-group
              [(ngModel)]="cloud_provider"
              aria-label="Select an option"
            >
              <mat-accordion>
                <mat-expansion-panel *ngFor="let provider of cloud_providers">
                  <mat-expansion-panel-header>
                    <mat-panel-title
                      ><mat-radio-button
                        (click)="$event.stopPropagation();"
                        [value]="provider.key"
                      >
                        {{provider.title}}</mat-radio-button
                      >
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ul>
                    <li *ngFor="let item of provider.properties">{{item}}</li>
                  </ul>
                </mat-expansion-panel>
              </mat-accordion>
            </mat-radio-group>
          </div>
        </div>

        <mat-hint
          *ngIf="plan && paymentMethods.length==0 && plan != 'free_tier'"
          (click)="openPaymentMethods()"
        >
          Please enter your payment method to create a paid instance.
        </mat-hint>
      </div>
      <div class="d-flex justify-content-between align-items-center mt-3">
        <div></div>
        <div>
          <button
            mat-button
            class="btn-choose button-positive"
            cdkFocusInitial
            (click)="active_step='second'"
            *ngIf="!(!plan || !newProjectName || !checkPattern() || (paymentMethods.length==0 && plan != 'free_tier'))"
          >
            Ok
          </button>
          <button
            mat-button
            class="btn-choose button-negative"
            (click)="onNoClick()"
          >
            Cancel
          </button>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Second">
      <div class="dialog-input-area">
        <app-choose-asset
          *ngIf="!onProcessing"
          (selectedButton)="selectButtonOfAssets($event)"
          [withBack]="true"
          (changeSelected)="selectedAsset=$event"
        ></app-choose-asset>
        <loading-spinner *ngIf="onProcessing"></loading-spinner>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
