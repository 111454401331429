<app-header-background onPage="organization"></app-header-background>

<div class="content-area">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="page-title">
          Organizations
          <button mat-button (click)="openDialog()">
            Create a new one
            <mat-icon fontSet="material-icons-outlined">add</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <loading-spinner *ngIf="!dataPassed"></loading-spinner>
        <div *ngIf="getOrganizationsData | async; let organizationsSource">
          <div *ngIf="organizations.length == 0 && dataPassed" class="text-center col-12 pt-5">
            You have no organization yet!
          </div>
          <table *ngIf="organizations.length > 0 && dataPassed" mat-table class="w-100" [dataSource]="organizations">
            <ng-container matColumnDef="name">
              <th class="text-left" mat-header-cell *matHeaderCellDef>
                Organization Name
              </th>
              <td class="text-left" mat-cell *matCellDef="let element">
                {{ element.name }}
              </td>
            </ng-container>

            <ng-container matColumnDef="count">
              <th class="text-center" mat-header-cell *matHeaderCellDef>
                Organization Members
              </th>
              <td class="text-center" mat-cell *matCellDef="let element">
                {{ element.users.length }}
              </td>
            </ng-container>

            <ng-container matColumnDef="_id">
              <th class="text-right" mat-header-cell *matHeaderCellDef>
                Action
              </th>
              <td class="text-right" mat-cell *matCellDef="let element">
                <button mat-icon-button [routerLink]="['/editorganization', element._id]">
                  <mat-icon fontSet="material-icons-outlined">build_outline</mat-icon>
                </button>
                <button mat-icon-button (click)="removeOrganization(element._id)" *ngIf="element.owner._id == user._id">
                  <mat-icon fontSet="material-icons-outlined">delete_outline</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr class="" mat-header-row *matHeaderRowDef="['name', 'count', '_id']"></tr>
            <tr class="" mat-row *matRowDef="let row; columns: ['name', 'count', '_id']"></tr>
          </table>
        </div>
        <div *ngIf="dataPassed && invitations && invitations.length > 0" class="pt-5">
          <mat-divider></mat-divider>
          <h3 class="mt-5">You got invitations!</h3>
          <table mat-table class="w-100" [dataSource]="invitations">
            <ng-container matColumnDef="name">
              <th class="text-left" mat-header-cell *matHeaderCellDef>
                Organization Name
              </th>
              <td class="text-left" mat-cell *matCellDef="let element">
                {{ element.organization.name }}
              </td>
            </ng-container>

            <ng-container matColumnDef="_id">
              <th class="text-right" mat-header-cell *matHeaderCellDef>
                Action
              </th>
              <td class="text-right" mat-cell *matCellDef="let element">
                <button mat-icon-button (click)="answerInvite(element, true)">
                  <mat-icon fontSet="material-icons-outlined">done</mat-icon>
                </button>
                <button mat-icon-button (click)="answerInvite(element, false)">
                  <mat-icon fontSet="material-icons-outlined">close</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr class="" mat-header-row *matHeaderRowDef="['name', '_id']"></tr>
            <tr class="" mat-row *matRowDef="let row; columns: ['name', '_id']"></tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>