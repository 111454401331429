<div class="popup-dialog">
    <div class="title">Change User Role</div>
    <mat-divider></mat-divider>
    <div class="text"></div>
    <div class="dialog-input-area">
        <mat-form-field appearance="outline" class="col-12">
            <mat-label>Role</mat-label>
            <mat-select matNativeControl [(ngModel)]="role" required>
                <mat-option value="admin">Admin</mat-option>
                <mat-option value="member">Member</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="text-right">
        <button mat-button class="btn-choose button-positive" cdkFocusInitial (click)="onOkClick()"
            [disabled]="!role">
            Ok
        </button>
        <button mat-button class="btn-choose button-negative" (click)="onNoClick()">
            Cancel
        </button>
    </div>
</div>