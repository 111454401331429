import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { merge, Observable, of, Subject } from "rxjs";
import { map, switchMap, withLatestFrom, tap } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { DataService } from "../../services";
import { UpdateOrganizationDialog } from "../popups/update-organization.component";
import { ConfirmationDialog } from "../popups/confirmation-popup.component";
import { ToastrService } from "ngx-toastr";
import { UpdateOrganizationMetaDialog } from "../popups/update-organization-meta.component";
import { Organization } from "src/app/interfaces/organization";
import { UpdateUserRoleDialog } from "../popups/update-user-role.component";
import { OrganizationService } from "src/app/services/page-services/organization.service";

export interface IEditOrganization {
  org_id: string;
  org_ownerId: string;
}

@Component({
  selector: "app-editorganization",
  templateUrl: "./editorganization.component.html",
  styleUrls: ["./editorganization.component.scss"],
})
export class EditorganizationComponent implements OnInit {
  userID;
  userRole: string;
  organizationId: string;
  organization: any;
  data$: Observable<any>;
  organizationMembers = new Array();
  dataPassed: boolean = false;
  organizationUsers: any;
  identity_id;
  invitations;

  refresh = new Subject();
  constructor(
    private dataService: DataService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private organizationService: OrganizationService
  ) {}

  ngOnInit() {
    this.userID = localStorage.getItem("user_id");
    this.identity_id = this.dataService.getUserId();
    this.data$ = merge(this.route.params, this.refresh).pipe(
      withLatestFrom(this.route.params),
      switchMap(([_, params]) => {
        this.organizationId = params.organizationId;
        return this.organizationService.getOrganization(this.organizationId);
      }),
      tap((res) => {
        this.organization = res[0];
      }),
      switchMap((_) => {
        return this.organizationService.getOrganizationInvitations(
          this.organizationId
        );
      }),
      tap((res) => {
        this.invitations = res['data'];
      }),
      switchMap((_) => {
        return this.organizationService.getOrganizationUsers(
          this.organizationId,
          true
        );
      }),
      switchMap((response) => {
        this.organizationUsers = response["data"];
        this.invitations.map((item) => {
          this.organizationUsers.push(item);
        });

        this.organizationMembers = response["data"].map((userOrganization) => {
          if (userOrganization.organization._id == this.organizationId) {
            if (userOrganization.user._id == this.userID) {
              this.userRole = userOrganization.role;
            }
          }
          return userOrganization.user;
        });
        return of([]);
      }),
      tap(() => {
        this.dataPassed = true;
      })
    );
    //this.organizationUsers.subscribe(org=>org.forEach(element => {
    // console.log(org)
    //}));
  }

  userRemoveOrganization(user) {
    let dialog = this.dialog.open(ConfirmationDialog, {
      width: "30%",
      maxWidth: "400px",
    });
    dialog.componentInstance.text = "Do you want to delete the user ?";
    dialog
      .afterClosed()
      .toPromise()
      .then((data) => {
        if (data) {
          this.dataPassed = false;
          this.organizationService
            .userRemoveOrganization(this.organizationId, user)
            .toPromise()
            .then(() => {
              this.refresh.next();
            })
            .catch((error) => {
              this.toastr.error(undefined, error.error.message, {
                positionClass: "toast-bottom-center",
                timeOut: 2500,
              });
              this.refresh.next();
            });
        }
      });
  }

  openDialog(): void {
    this.dialog
      .open(UpdateOrganizationDialog, {
        width: "30%",
        maxWidth: "400px",
      })
      .afterClosed()
      .toPromise()
      .then((data) => {
        if (data && data.result) {
          this.dataPassed = false;
          this.organizationService
            .inviteUserToOrganization(
              this.organizationId,
              data.userMail,
              data.role
            )
            .toPromise()
            .then(() => {
              this.refresh.next();
              this.toastr.success(
                undefined,
                "Invitation successfully sent to: " + data.userMail,
                {
                  positionClass: "toast-bottom-center",
                  timeOut: 2500,
                }
              );
            })
            .catch((error) => {
              this.toastr.error(undefined, error.error.message, {
                positionClass: "toast-bottom-center",
                timeOut: 2500,
              });
              this.refresh.next();
            });
        }
      });
  }

  updateOrganization(): void {
    this.dialog
      .open(UpdateOrganizationMetaDialog, {
        width: "30%",
        maxWidth: "400px",
      })
      .afterClosed()
      .toPromise()
      .then((data) => {
        if (data && data.result) {
          this.dataPassed = false;
          this.organization.name = data.organizationName;
          this.organization.owner = this.organization.owner._id;
          this.organizationService
            .changeOrganizationName(this.organizationId, this.organization)
            .toPromise()
            .then(() => {
              this.refresh.next();
            })
            .catch(() => {
              this.refresh.next();
            });
        }
      });
  }

  userUpdateRole(user) {
    this.dialog
      .open(UpdateUserRoleDialog, {
        width: "30%",
        maxWidth: "400px",
      })
      .afterClosed()
      .toPromise()
      .then((data) => {
        if (data) {
          this.dataPassed = false;
          this.organizationService
            .userUpdateRole(this.organizationId, user, data.role)
            .toPromise()
            .then(() => {
              this.refresh.next();
            })
            .catch((error) => {
              console.log(error.message);
              this.refresh.next();
            });
        }
      });
  }
}
