<div class="popup-dialog">
  <div class="title">Add Payment Method</div>
  <mat-divider></mat-divider>
  <div class="body">
    The provided payment method will be used as default method.
  </div>
  <div class="sub-title">Add a Card Number</div>
  <form id="subscription-form">
    <h5 class="my-3"></h5>
    <div #cardelement class="MyCardElement"></div>
    <div id="card-errors" role="alert"></div>
  </form>

  <div class="sub-title">Add a Billing Address</div>
  <form [formGroup]="formGroup">
    <mat-form-field
      color="light"
      appearance="outline"
      floatLabel="never"
      class="form-element d-block"
    >
      <mat-label>Email</mat-label>
      <input type="text" matInput formControlName="email" />
      <mat-icon matSuffix fontSet="material-icons-outlined"
        >mail_outline</mat-icon
      >
      <mat-error *ngIf="formGroup.controls['email'].invalid">
        {{ getError("email") }}
      </mat-error>
    </mat-form-field>
    <mat-form-field
      color="light"
      appearance="outline"
      floatLabel="never"
      class="form-element d-block"
    >
      <mat-label>Full Name</mat-label>
      <input type="text" matInput formControlName="name" />
      <mat-icon matSuffix fontSet="material-icons-outlined">badge</mat-icon>
      <mat-error *ngIf="formGroup.controls['name'].invalid">
        {{ getError("name") }}
      </mat-error>
    </mat-form-field>
    <div class="item-row">
      <mat-form-field
        color="light"
        appearance="outline"
        floatLabel="never"
        class="form-element d-block w-50"
      >
        <mat-label>Country</mat-label>
        <mat-select
          (selectionChange)="countryChange()"
          formControlName="country"
        >
          <mat-option *ngFor="let country of countries" [value]="country.code">
            {{ country.name }}
          </mat-option>
        </mat-select>
        <mat-icon matSuffix fontSet="material-icons-outlined">place</mat-icon>
        <mat-error *ngIf="formGroup.controls['country'].invalid">
          {{ getError("country") }}
        </mat-error>
      </mat-form-field>
      <mat-form-field
        color="light"
        appearance="outline"
        floatLabel="never"
        class="form-element d-block w-50"
      >
        <mat-label>City</mat-label>
        <mat-select
          (selectionChange)="cityChange()"
          formControlName="city"
          [disabled]="!formGroup.controls['country'].value"
        >
          <mat-option *ngFor="let city of cities" [value]="city.state_code">
            {{ city.name }}
          </mat-option>
        </mat-select>
        <mat-icon matSuffix fontSet="material-icons-outlined">place</mat-icon>
        <mat-error *ngIf="formGroup.controls['city'].invalid">
          {{ getError("city") }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="item-row">
      <mat-form-field
        color="light"
        appearance="outline"
        floatLabel="never"
        class="form-element d-block w-50"
      >
        <mat-label>State</mat-label>
        <mat-select
          formControlName="state"
          [disabled]="!formGroup.controls['city'].value"
        >
          <mat-option *ngFor="let state of states" [value]="state">
            {{ state }}
          </mat-option>
        </mat-select>
        <mat-icon matSuffix fontSet="material-icons-outlined">place</mat-icon>
        <mat-error *ngIf="formGroup.controls['state'].invalid">
          {{ getError("state") }}
        </mat-error>
      </mat-form-field>
      <mat-form-field
        color="light"
        appearance="outline"
        floatLabel="never"
        class="form-element d-block w-50"
      >
        <mat-label>Postal Code</mat-label>
        <input type="text" matInput formControlName="postal_code" />
        <mat-icon matSuffix fontSet="material-icons-outlined"
          >local_post_office</mat-icon
        >
        <mat-error *ngIf="formGroup.controls['postal_code'].invalid">
          {{ getError("postal_code") }}
        </mat-error>
      </mat-form-field>
    </div>
    <mat-form-field
      color="light"
      appearance="outline"
      floatLabel="never"
      class="form-element d-block"
    >
      <!-- <mat-label>Phone Number</mat-label> -->
      <input
        type="text"
        placeholder="Phone Number"
        matInput
        formControlName="phone"
      />
      <span *ngIf="dial_code" matPrefix>{{ dial_code }}</span>
      <mat-icon matSuffix fontSet="material-icons-outlined">phone</mat-icon>
      <mat-error *ngIf="formGroup.controls['phone'].invalid">
        {{ getError("phone") }}
      </mat-error>
    </mat-form-field>
    <mat-form-field
      color="light"
      appearance="outline"
      floatLabel="never"
      class="form-element d-block"
    >
      <mat-label>Address Line 1</mat-label>
      <input rows="1" type="text" matInput formControlName="address_line1" />
      <mat-icon matSuffix fontSet="material-icons-outlined">place</mat-icon>
      <mat-error *ngIf="formGroup.controls['address_line1'].invalid">
        {{ getError("address_line1") }}
      </mat-error>
    </mat-form-field>
    <mat-form-field
      color="light"
      appearance="outline"
      floatLabel="never"
      class="form-element d-block"
    >
      <mat-label>Address Line 2</mat-label>
      <textarea rows="1" type="text" matInput formControlName="address_line2">
      </textarea>
      <mat-icon matSuffix fontSet="material-icons-outlined">place</mat-icon>
      <mat-error *ngIf="formGroup.controls['address_line2'].invalid">
        {{ getError("address_line2") }}
      </mat-error>
    </mat-form-field>
    <!-- <mat-form-field
      color="light"
      appearance="outline"
      floatLabel="never"
      class="form-element d-block"
    >
      <mat-label>Address</mat-label>
      <textarea rows="1" type="text" matInput formControlName="address">
      </textarea>
      <mat-icon matSuffix fontSet="material-icons-outlined">place</mat-icon>
      <mat-error *ngIf="formGroup.controls['address'].invalid">
        {{ getError("address") }}
      </mat-error>
    </mat-form-field> -->
  </form>
  <div class="text-right" *ngIf="!onProcessing">
    <button
      mat-button
      class="btn-choose button-positive"
      cdkFocusInitial
      (click)="onOkClick()"
      [disabled]="formGroup.invalid"
    >
      Ok
    </button>
    <button mat-button class="btn-choose button-negative" (click)="onNoClick()">
      Cancel
    </button>
  </div>

  <loading-spinner *ngIf="onProcessing"></loading-spinner>
</div>
