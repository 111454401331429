import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DataService } from "../../services";
import { Observable, Subject, merge, of, forkJoin } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { CreateOrganizationDialog } from "../popups/create-organization.component";
import { ConfirmationDialog } from "../popups/confirmation-popup.component";
import { User } from "../../interfaces/user";
import { Organization } from "src/app/interfaces/organization";
import { OrganizationService } from "./../../services/page-services/organization.service";
import { ToastrService } from "ngx-toastr";

export interface organizationDataConfig {
  orgId: string;
  orgName: string;
  orgUserCount: number;
}

/**
 * @title Dialog Overview
 */
@Component({
  selector: "./organizations.component",
  templateUrl: "./organizations.component.html",
  styleUrls: ["./organizations.component.scss"],
})
export class OrganizationsComponent implements OnInit {
  organizations: any;
  getOrganizationsData: Observable<any>;
  invitations;
  user: User;
  refresh = new Subject();
  dataPassed: boolean = false;
  constructor(
    public dialog: MatDialog,
    private dataService: DataService,
    private organizationService: OrganizationService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.getOrganizationsData = merge(of(null), this.refresh).pipe(
      switchMap(() =>
        this.dataService
          .getUserWithIdentity()
          .toPromise()
          .then((data: User) => {
            this.user = data;
          })
      ),
      switchMap(() =>
        this.organizationService
          .getUserInvitations(this.user.email)
          .toPromise()
          .then((data) => (this.invitations = data))
      ),
      switchMap(() => {
        if (this.user["organizations"]) {
          return this.organizationService.getOrganizationsV3(this.user["_id"]);
        }
        return of([]);
      }),
      tap((result) => {
        this.organizations = result;
        this.dataPassed = true;
      })
    );
  }
  removeOrganization(organizationId: string) {
    let dialog = this.dialog.open(ConfirmationDialog, {
      width: "30%",
      maxWidth: "400px",
    });
    dialog.componentInstance.title = "Delete Organization";
    dialog.componentInstance.text = "Are sure to delete organization ?";
    dialog
      .afterClosed()
      .toPromise()
      .then((data) => {
        if (data) {
          this.dataPassed = false;
          this.organizationService
            .removeOrganization(organizationId)
            .toPromise()
            .then((data) => {
              this.refresh.next();
            })
            .catch((e) => {
              this.dataPassed = true;
              this.toastr.error(undefined, e.error.message, {
                positionClass: "toast-bottom-center",
                timeOut: 2500,
              });
            });
        }
      });
  }

  openDialog(): void {
    this.dialog
      .open(CreateOrganizationDialog, {
        width: "30%",
        maxWidth: "400px",
      })
      .afterClosed()
      .toPromise()
      .then((data) => {
        //   console.log(data);
        if (data.result) {
          this.dataPassed = false;
          this.organizationService
            .createOrganization(data.organizationName)
            .toPromise()
            .then((data: Organization) => {
              this.organizationService
                .userAddOrganization(data._id, this.user, "owner")
                .toPromise()
                .then((_) => this.refresh.next());
            });
        }
      });
  }

  answerInvite(invitation, answer: boolean) {
    this.dataPassed = false;
    if (answer) {
      this.organizationService
        .userAddOrganization(
          invitation.organization["_id"],
          this.user,
          invitation.role
        )
        .toPromise()
        .then((_) => {
          this.organizationService
            .removeInvitation(invitation)
            .toPromise()
            .then(() => {
              this.refresh.next();
            });
        });
    } else {
      this.organizationService
        .removeInvitation(invitation)
        .toPromise()
        .then((_) => {
          this.refresh.next();
        });
    }
  }
}
